import * as actionTypes from '../constant';

const initialState = {
    data: {
        id: ""
    }
};

export const serviceIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SERVICE_ID:
            return { ...state, data: {...state, id: action.value} };
        default:
            return state;
    }
}