import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { appConstants } from "../../_Constants/app.constants"
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from 'jquery';
import { updateProfileUser, resetStatus } from '../../redux/actions/AuthActions'
import { getServiceScheduleAction,deleteScheduleAction } from '../../redux/actions/ServiceActions'
import { Container, Row, Col, Card, Modal, FormLabel, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import Confirm from '../../components/ConfirmWithDescription';
// import { confirmAlert } from 'react-confirm-alert';


class MyScheduleList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      success: '',
      loading: false,
      scheduleList: [],
      deleteDescription: ""
    }
  }

  componentDidMount() {
    document.title = "Kunneckt | My Schedule"
    if (localStorage.getItem('token') === null) {
      this.props.history.push('/login')
    } else if (this.props && this.props.location && this.props.location.state === undefined) {
      this.props.history.push('/service');
    } else {
      this.props.getServiceScheduleAction(`?service_id=${this.props.location.state.id}`)
    }
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.serviceData  && this.state.deleteStatus){
      this.setState({
        deleteStatus:false
      })
      NotificationManager.success(nextProps.serviceData.success);
      this.componentDidMount();
      // this.props.history.push({
      //   pathname: "/my-schedule",
      //   state: {}
      // });
    }
    if (nextProps.serviceData && nextProps.serviceData.scheduleRes && nextProps.serviceData.type === "response") {
      // let listData =[];
      // listData.push(nextProps.serviceData.scheduleRes.data)
      this.setState({ scheduleList: nextProps.serviceData.scheduleRes.data })
    }
  }
  IsTime = (cell, row) => {
    var startTime = row.recurrence_list.start_time ? row.recurrence_list.start_time : '';
    var endTime = row.recurrence_list.end_time ? row.recurrence_list.end_time : '';
    return (startTime + ' - ' + endTime)
  }
  IsStartTime = (cell, row) => {
    return (row.start_date_time ? moment(row.start_date_time).format('MMM, DD YYYY') : '')
  }
  IsEndTime = (cell, row) => {
    if (row.recurrence_rule === 'None') {
      return '-';
    } else {
      return (row.end_date_time ? moment(row.end_date_time).format('MMM, DD YYYY') : '')
    }
  }
  IsAction = (cell, row) => {
    return (<div>
      <a href="javascript:void(0)" onClick={e=>{Confirm(this.deleteService,row.id, this.deleteDescriptionEdit)}}><i className="fa fa-trash-o" aria-hidden="true"></i></a> &nbsp;&nbsp;
      <Link to={{pathname: '/schedule', schedule:row, state:this.props.location.state }} > <i className="fa fa-edit" aria-hidden="true"></i> </Link>
    </div>)
  }

  deleteDescriptionEdit = (e) => {
    this.setState({"deleteDescription": e.target.value});
  }


  deleteService=(serviceId)=>{
    this.setState({
        deleteStatus:true
    })
    this.props.deleteScheduleAction(serviceId, this.state.deleteDescription);
  }


  render() {
    const { scheduleList } = this.state;

    return (
      <div className="container-fluid main-contain">
        <div className="row">
          <div className="col-md-12">
            <h3>Service:- {this.props.location.state.title ? this.props.location.state.title : '-'}</h3>
            {/* <h3>Service:- {scheduleList.recurrence_rule?scheduleList.recurrence_rule:'-'}</h3> */}
            {/* <h3>Service:- {this.props.location.state.title?this.props.location.state.title:'-'}</h3>
            <h3>Service:- {this.props.location.state.title?this.props.location.state.title:'-'}</h3> */}
            <BootstrapTable data={this.state.scheduleList} pagination search={false}>
              <TableHeaderColumn tdAttr={{ 'data-attr': 'ID' }} dataField='id' isKey hidden={true}>Id</TableHeaderColumn>
              {/* <TableHeaderColumn width="200" tdAttr={{ 'data-attr':'Service Name'}} dataField='title' >Service</TableHeaderColumn>*/}
              <TableHeaderColumn width="200" tdAttr={{ 'data-attr': 'recurrence_rule' }} dataField='recurrence_rule' >Recurrence Rule</TableHeaderColumn>
              <TableHeaderColumn width="200" dataField='start_date_time' dataFormat={this.IsStartTime.bind(this)}>Start Date</TableHeaderColumn>
              <TableHeaderColumn width="200" dataField='end_date_time' dataFormat={this.IsEndTime.bind(this)}>End Date</TableHeaderColumn>
              <TableHeaderColumn width="200" dataField='start_time' dataFormat={this.IsTime.bind(this)}>Schedule Time</TableHeaderColumn>
              <TableHeaderColumn width='100' dataFormat={this.IsAction.bind(this)} dataSort={true} searchable={false}>Action</TableHeaderColumn>
            </BootstrapTable><br />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
  }
}

const mapDispatchToProps = {
  getServiceScheduleAction: getServiceScheduleAction,
  deleteScheduleAction: deleteScheduleAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(MyScheduleList);
