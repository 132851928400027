import React from 'react';
import './App.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Routes from './routes'
import { Provider } from 'react-redux';
import { persistor,store } from './redux/store'
import { PersistGate } from 'redux-persist/lib/integration/react';
import IdleTimer from 'react-idle-timer';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {LoaderContainer} from './components/Loader';
import CommonContainer from './components/Common/index.js';
// @import '../../node_modules/@syncfusion/ej2/material.css';

class App extends React.Component{
	constructor(props) {
    super(props)
    this.idleTimer = null     
    this.handleOnIdle = this.handleOnIdle.bind(this)
	}
	 
  handleOnIdle (event) {
  	if(localStorage.getItem('token')){
			localStorage.clear();
			window.location.href = '/'
  	}
  }

	render(){
	  return(
			<Provider store={store}>
				<IdleTimer ref={ref => { this.idleTimer = ref }}
		          timeout={1000 * 60 * 10} 
		          onIdle={this.handleOnIdle} debounce={250} />
      	
      	<CommonContainer />
      	<NotificationContainer/>
      	<LoaderContainer/>
			
				<PersistGate loading={<LoadingView />} persistor={persistor}>
					<Routes/>
				</PersistGate>
			</Provider>
	  )
	}
}
const LoadingView=()=>{
	return(
		<h2 style={{textAlign:"center"}}>Loading</h2>	
	)
}
export default App;
