import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {appConstants} from "../../_Constants/app.constants"
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from 'jquery';

class Notification extends React.Component {
  constructor(props){
    super(props)
    this.state={
      
    }
  }
  
  componentDidMount() {
    // document.title = "Kunneckt | Notification"
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('key');
    if(!token){
      this.props.history.push('/login')
    }
    if (localStorage.getItem('token') === null) {
      this.props.history.push('/login')
    }else{
        this.props.history.push('/invite-notification')
      }
    }

    render() {
      return (
        <div className="container-fluid">

        </div>
      )
    }
}


export default (Notification);
