import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {appConstants} from "../../_Constants/app.constants"
import {updateProfileUser,resetStatus} from '../../redux/actions/AuthActions'
import {getUserListingAction} from '../../redux/actions/UserActions'
import {updateServiceAction, deleteServiceAction, EnrollNotificationGetAction, serviceListAction} from '../../redux/actions/ServiceActions'
import { Link } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Confirm from '../../components/ConfirmWithDescription';
import SimpleReactValidator from 'simple-react-validator';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import {Modal, FormLabel, Form, FormControl, Button} from 'react-bootstrap';
import defaultImg from '../../assets/images/default.png';
import {addressValidate} from '../../services/authServices'

class EditService extends React.Component {
  constructor(props){
    super(props)
    this.state={
      file:'',
      error:'',
      success:'',
      imagePreviewUrl:'',
      imagePosterUrl:'',
      imageLogoUrl:'',
      loading:false,
      updateStatus:false,
      formData:{},
      updateFormData:{},
      userListing:[],
      enrollNotificationList:[],
      modal:false,
      modalData:{},
      deleteDescription: ""
    }
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    document.title = "Kunneckt | Edit Service"

    const { location } = this.props;
    if(location && location.state && location.state.data){
      let propsData = this.props.location.state.data;
      let dataObj = {
        id : propsData.id,
        title : propsData.title?propsData.title:'',
        email : propsData.email?propsData.email:'',
        phone : propsData.phone?propsData.phone:'',
        streetAddress : propsData.streetAddress?propsData.streetAddress:'',
        city : propsData.city?propsData.city:'',
        state : propsData.state?propsData.state:'',
        zip : propsData.zip?propsData.zip:'',
        description : propsData.description?propsData.description:'',
       // atLocation : propsData.atLocation,
       // isMultiItemService : propsData.isMultiItemService,
        price : propsData.price?propsData.price:'',
        duration : propsData.duration?propsData.duration:'',
        poster : propsData.poster?propsData.poster:'',
        //imagePosterUrl : propsData.poster,
        logo : propsData.logo?propsData.logo:'',
        //imageLogoUrl : propsData.logo,
        descriptionModal: false,
        updateDescription: ""
      }
      this.setState({
        formData: dataObj,
        imagePosterUrl : propsData.poster?propsData.poster:'',
        imageLogoUrl : propsData.logo?propsData.logo:'',
      })
    }

    this.props.getUserListingAction()
    this.props.EnrollNotificationGetAction()
    this.props.resetStatus({signupError:'',success:''});
    if (localStorage.getItem('token') === null) {
        this.props.history.push('/login')
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.serviceData && nextProps.serviceData.services && this.state.deleteStatus){
      this.setState({
        deleteStatus:false
      })
      NotificationManager.success(nextProps.serviceData.success);
      this.props.history.push({
        pathname: "/service",
        state: {}
      });
    }
    if(nextProps.serviceData && nextProps.serviceData && this.state.updateStatus && nextProps.serviceData.type === "serviceUpdate"){
      this.setState({
        loading:false,
        updateStatus:false
      })
      NotificationManager.success(nextProps.serviceData.success);
      this.props.history.push({
        pathname: "/service",
        state: {}
      });
    }
    if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "notification"){
      const { location } = this.props;
      let notificationData = nextProps.serviceData.services?nextProps.serviceData.services:[];
      notificationData = notificationData.sort(function(a,b){
        return new Date(b.requested_at) - new Date(a.requested_at)
      })
      if(notificationData && location && location.state && location.state.data && location.state.data.id){
        let myServiceID = location.state.data.id;
        notificationData = notificationData.filter(function(row){
          return row.serviceId === myServiceID && row.is_approve===true && row.consumer_status===true
        })
      }
      this.setState({
        enrollNotificationList:notificationData,
      })
    }
    if(nextProps.userDetail && nextProps.userDetail.users && nextProps.userDetail.type === "userList"){
      this.setState({
        userListing : nextProps.userDetail.users,
      })
    }
  }

  handleImageChange=(e)=> {
    e.preventDefault();
    let nameOption = e.target.name;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FILE_SIZE = 1498400;
    let SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
    ];
    if(file===undefined){
        return false
    }
    if(!SUPPORTED_FORMATS.includes(file.type)){
        alert("File not supported.")
        return false
    }
    if(file.size > FILE_SIZE){
        alert("Image size has been exceeded.")
        return false
    }
    if (file) {
      reader.onloadend = () => {
        if(nameOption == "poster"){
          this.setState({
            posterFile: file,
            imagePosterUrl: reader.result
          });
        }
        if(nameOption == "logo"){
          this.setState({
            logoFile: file,
            imageLogoUrl: reader.result
          });
        }
      };
      reader.readAsDataURL(file);
      //this.props.setFieldValue(this.props.field.name, file);
    }
  }

  deleteService=(serviceId)=>{
    this.setState({
        deleteStatus:true
    })
    let deleteData = {
      "description": this.state.deleteDescription
    };
    this.props.deleteServiceAction(serviceId, deleteData)
  }

  deleteDescriptionEdit = (e) => {
    this.setState({"deleteDescription": e.target.value});
  }

  handleChange = (e)=>{
    let formData = this.state.formData;
    if(e.target && e.target.name == 'duration' && e.target.value < 1){
      formData[e.target.name] = '';
      var input = document.getElementById('durationBox');
      input.value = "Empty";
    }
    if(e.target.name == "atLocation"){
      formData[e.target.name] = e.target.checked?"1":"0"; 
    }else{
      formData[e.target.name] =e.target.value;
    }
    this.setState({
      formData:formData
    })
  }

  handleSubmit= (e)=>{
    e.preventDefault();
    this.setState({descriptionModal: true})
  }

  handleSubmitNext= ()=>{
    
    var dt = new Date();
    let form_data = new FormData();
    let formData = this.state.formData;
    for ( var key in formData ) {
      if(key!=='id'){
          form_data.append(key, formData[key]);
      }
    }
    if(this.state.file!==''){
      form_data.append('profile',this.state.file,dt.getTime()+this.state.file.name)           
    }
    if(this.state.posterFile && (this.state.posterFile!=='' || this.state.posterFile!==undefined)){
        form_data.append('poster',this.state.posterFile,dt.getTime()+this.state.posterFile.name)
    }
    if(this.state.logoFile && (this.state.logoFile!=='' || this.state.logoFile!==undefined)){
        form_data.append('logo',this.state.logoFile,dt.getTime()+this.state.logoFile.name)
    }
    form_data.append("updateDescription2", this.state.updateDescription);

    if (this.validator.allValid()) {
      this.props.resetStatus({signupError:'', success:''})
      this.setState({
        loading:true,
        updateStatus:true,
        success:''
      },()=>{

        let addressUrl = "?address="+formData.streetAddress+"&city="+formData.city+"&state="+formData.state
        addressValidate(addressUrl).then(res=>{
            let address = res.data.ZipCodeLookupResponse.Address;
            if(!formData.street_address && !formData.city && !formData.state && !formData.zip){
                this.props.updateServiceAction(formData.id,form_data)
            }else if(address.Zip4 == formData.zip || address.Zip5 == formData.zip){
                this.props.updateServiceAction(formData.id,form_data)
            }else{
                this.setState({
                    loading:false,
                    error:'Invalid Address.'
                })
            }
        }).catch(err=>{
            this.setState({
                loading:false,
                error:'Invalid Address.'
            })
        })

      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  imageUpload=(id)=>{
    document.getElementById(id).click();
  }

  IsAccepted = (cell,row)=>{
    if(row.is_approve===false || row.is_approve==="False"){
      return (<span>Unapproved</span>)
    }else if(row.is_approve===true || row.is_approve==="True"){
      return (<span>Approved</span>)
    }else{
      return (<span>Pending</span>)
    }
  }      

  IsAction = (cell,row)=>{
    return (<button type='link' className='btnlink' onClick={(e)=>this.hideModals(row)}> View </button>)
  }      

  IsDate = (cell,row)=>{
    var dateTimeValue = moment(row.requested_at).format('MMM, DD YYYY');
    return (<span>{dateTimeValue}</span>)
  } 

  hideModals=(data)=> {
    this.setState({
      modalData: data?data:{},
      modal: !this.state.modal
    })
  }

  render() {
    const {modalData, formData, updateFormData, enrollNotificationList, userListing} = this.state ;
    const phoneRegExp =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    // const passwordRegex = /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/
    const passwordRegex = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;

    //   const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    var dataTest = [];
    var notifyArr = enrollNotificationList?enrollNotificationList:[];
    if(notifyArr && userListing){
      for (var i = 0; i < notifyArr.length; i++) {
        for (var j = 0; j < userListing.length; j++) {
          if(userListing[j].id===notifyArr[i].userId){
            let firstName = userListing[j].firstName?userListing[j].firstName:'';
            let lastName = userListing[j].lastName?userListing[j].lastName:'';
            notifyArr[i]['fullName'] = firstName+' '+lastName;
            var a = Object.assign(notifyArr[i], userListing[j]);
            dataTest.push(a);
          }
        }
      }
    }

    return (
      <React.Fragment>
        <div className="profile-form pad-top0">
          <div id="service-details-box" className="form form-vertical bgwhite">
            <div className="row">
              <div className="col-md-8">
                <div className="service-detail-text">View &amp; Edit Service Details</div>
              </div>
              <div className="col-md-2">
                <Link to={{pathname: '/schedule', state: formData }} className="btn btn-info" > Service Schedule </Link>
              </div>              
              <div className="col-md-2">
                <button className="btn btn-info" onClick={e=>{Confirm(this.deleteService,formData.id, this.deleteDescriptionEdit)}}> Delete</button><br/>
              </div>
            </div>
          </div><br></br>
          <form className="form form-vertical" onSubmit={this.handleSubmit} name="serviceForm">
            <div className="banner-addservice">       
              <div>
                <div className="kv-avatar">
                  <div className="file-loading">
                    <input id="poster" name="poster" type="file" style={{display: "none"}}  onChange={this.handleImageChange} accept=".jpg,.jpeg,.png"/>
                  </div>
                </div>
                {this.state.imagePosterUrl!==null && this.state.imagePosterUrl!=='' ?
                   <><img src={this.state.imagePosterUrl} name="poster" alt="Poster" className="siz-coverfit"/><br/><i onClick={()=>this.imageUpload("poster")} className="fa fa-pencil"></i></>
                  :
                 <div className="topbaner-iplo" onClick={()=>this.imageUpload("poster")}>Poster <br/> (Click to update)</div>
                }
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-3 offset-md-0 text-center">
                  <div className="kv-avatar">
                    <div className="file-loading">
                      <input id="logo" name="logo" type="file" style={{display: "none"}}  onChange={this.handleImageChange} accept=".jpg,.jpeg,.png"/>
                    </div>
                  </div>
                  <div className="kv-avatar-hint">
                     {/*<small>Select file  1500 KB</small>*/}
                  </div><br/>
                  <div className="profileDiv">
                  {this.state.imageLogoUrl!==null && this.state.imageLogoUrl!=='' ?
                      <><img src={this.state.imageLogoUrl} name="logo" alt="Logo"/><br/> <i onClick={()=>this.imageUpload("logo")} className="fa fa-pencil fa-pen"></i></>
                  :
                  <div className="" onClick={()=>this.imageUpload("logo")}>Logo <br/> (Click to update)</div>
                  }
                  </div>
                </div>
              <div className="col-sm-9">
                <div className="form-group">
                  <label htmlFor="title">Title<span className="req">*</span></label>
                  <input type="text" className="form-control" name="title" value={formData.title} onChange={this.handleChange}/>
                </div>
                {this.validator.message('title', formData.title, 'required')}
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="description">Description<span className="req">*</span></label>
                  <textarea type="text" className="form-control he-discription" name="description" value={formData.description} onChange={this.handleChange}></textarea>
                </div>
                {this.validator.message('Description', formData.description, 'required')}
                
                <div className="form-group">
                  <label htmlFor="streetAddress">Street Address<span className="req">*</span></label>
                  <input type="text" className="form-control" name="streetAddress" value={formData.streetAddress} onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                  <label className="checkboxInline">
                  <input type="checkbox" name="atLocation" value={formData.atLocation} onChange={this.handleChange} checked={formData.atLocation=="1"?true:false} />&nbsp;Offered at this location
                  </label>
                </div>
                {this.validator.message('streetAddress', formData.streetAddress, 'required')}
                <div className="form-group">
                  <label htmlFor="city">City<span className="req">*</span></label>
                  <input type="text" className="form-control" name="city" value={formData.city}  onChange={this.handleChange}/>
                </div>
                {this.validator.message('city', formData.city, 'required')}
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="state">State<span className="req">*</span></label>
                          <select
                            className="form-control"
                            name="state"
                            value={formData.state}
                            onChange={this.handleChange}
                            style={{ display: 'block' }}>
                            <option value="" label="Select State"  />
                              {appConstants.stateOptions.map((data, idx) => (
                                <option value={data.value} label={data.label} key={idx} />
                              ))}
                          </select>
                        </div>
                        {this.validator.message('state', formData.state, 'required')}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="zip">Zip<span className="req">*</span></label>
                          <input type="text" className="form-control" name="zip" value={formData.zip}  onChange={this.handleChange}/>
                        </div>
                        {this.validator.message('Zip', formData.zip, 'required')}
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="phone">Phone Number<span className="req">*</span></label>
                          <InputMask {...this.props} mask="(999)-999-9999" className="form-control"  name="phone" onChange={this.handleChange} placeholder="Phone Number" alwaysShowMask value={formData.phone}/>
                        </div>
                        {this.validator.message('Phone Number', formData.phone, 'required|min:14')}
                        
                        <div className="form-group">
                          <label htmlFor="email">Email Address<span className="req">*</span></label>
                          <input type="email" className="form-control" name="email" value={formData.email}  onChange={this.handleChange} placeholder='Email'/><br/><br/>
                        </div>
                        {this.validator.message('Email Address', formData.email, 'required')}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="border-circled">
                      <div className="form-group">
                        <label htmlFor="duration">Duration<span className="req">*</span> (Minutes)</label>
                        <input type="number" className="form-control" id='durationBox' name="duration" value={formData.duration} onChange={this.handleChange}/>
                      </div>
                      {this.validator.message('Duration', formData.duration, 'required')}

                      <div className="form-group">
                        <label htmlFor="zip">Price<span className="req">*</span> (Per Slot) $</label>
                        <input type="number" className="form-control" name="price" value={formData.price} onChange={this.handleChange}/>
                      </div>
                      {this.validator.message('price', formData.price, 'required')}
                    </div>
                  </div>
                </div>
                <div className="row top-btnss">
                  <div className='col-md-6 offset-md-6'>
                    {this.state.error !==''  && <div className="alert alert-danger">{this.state.error}</div>}
                    {this.state.success !==''  && <div className="alert alert-success">{this.state.success}</div>}
                  </div>
                  <div className="col-md-3 offset-md-6">
                    <Link to="/service" type="cancel" className="cancel-link btn btn-info">Cancel</Link>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="text-right">
                        {this.state.loading ? <button className="btn text-center" disabled>
                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                        </button> : <button type="submit" className="btn">Ok</button>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
            </div>
          </form> 
        </div>

        <div id="service-details-box" className="form form-vertical bgwhite">
          <BootstrapTable data={ dataTest } pagination search={false}>
            <TableHeaderColumn tdAttr={ { 'data-attr': 'ID' }} dataField='id' isKey hidden={true}>Id</TableHeaderColumn>
            <TableHeaderColumn width="200" tdAttr={ { 'data-attr': 'Consumer Name' }} dataField='fullName'> Name </TableHeaderColumn>
            <TableHeaderColumn width="200" tdAttr={ { 'data-attr': 'User Name' }} dataField='username'> User Name </TableHeaderColumn>
            <TableHeaderColumn width="200" tdAttr={{ 'data-attr':'Service Name'}} dataField='serviceName' >Service</TableHeaderColumn>
            <TableHeaderColumn width="200" dataFormat={this.IsDate.bind(this)} tdAttr={{ 'data-attr':'Invited Date'}} dataField='InvitedAt' >Invited Data</TableHeaderColumn>
            <TableHeaderColumn width="100" tdAttr={{ 'data-attr':'Status'}} dataFormat={this.IsAccepted.bind(this)} >Status</TableHeaderColumn>
            <TableHeaderColumn width='100' tdAttr={{ 'data-attr':'Action'}} dataFormat={this.IsAction.bind(this)} dataSort={true} searchable={false}>Action</TableHeaderColumn>
          </BootstrapTable><br/>
        </div><br></br>

        <Modal show={this.state.modal} onHide={e=>this.hideModals({})} >
          <Modal.Header closeButton>
            {/*<Modal.Title>
              <img src={modalData.profile && modalData.profile?modalData.profile:defaultImg} height="50px"  alt={modalData && modalData.title} />&nbsp; User Detail
            </Modal.Title>*/}
            <Modal.Title> User Detail </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="" >
                <img src={modalData.profile && modalData.profile?modalData.profile:defaultImg} height="100x" className='text-center' alt={modalData && modalData.title}/><br/>
                <p className="head-lab">
                  Full Name : {modalData && modalData.fullName?modalData.fullName:''}</p>
                <p className="head-lab">
                  User Name : {modalData && modalData.username?modalData.username:''}</p>
                <p className="head-lab">
                  Email : {modalData && modalData.email?modalData.email:''}</p>
                <p className="head-lab">
                  Phone : {modalData && modalData.phone?modalData.phone:''}</p>
                <p className="head-lab">
                  Street Address : {modalData && modalData.street_address?modalData.street_address:''}</p>
                <p className="head-lab">
                  City : {modalData && modalData.city?modalData.city:''}</p>
                <p className="head-lab">
                  State : {modalData && modalData.state?modalData.state:''}</p>
                <p className="head-lab">
                  Zip : {modalData && modalData.zip?modalData.zip:''}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*  Description */}
        <Modal show={this.state.descriptionModal} onHide={e=>this.state.setState({descriptionModal:false})} >
          <Modal.Header closeButton>
            <Modal.Title> Update Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormControl as="textarea" placeholder="Description" aria-label="Description" onChange={e => this.setState({updateDescription: e.target.value})}/>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width:"20%"}} variant="primary" onClick={this.handleSubmitNext}>Save</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    userDetail: state.userReducer,
  }
}

const mapDispatchToProps = {
  getUserListingAction: getUserListingAction,
  EnrollNotificationGetAction: EnrollNotificationGetAction,
  updateUser: updateProfileUser,
  updateServiceAction: updateServiceAction,
  deleteServiceAction: deleteServiceAction,
  resetStatus:resetStatus
}
export default connect(mapStateToProps, mapDispatchToProps)(EditService);
