import React from 'react';
import { BrowserRouter as Router,Route, Switch } from "react-router-dom";
import Main from '../layout/Main'
import Front from '../layout/Front'

const Routes=()=>{
	return(
		<Router>
			<Switch>
				<Route path="/" component={Front} />
				<Route path="/main" component={Main} />
			</Switch>
		</Router>
	)
}
export default Routes;
