import * as actionTypes from '../constant';

import {
    createAppointment,
    getAppoinmentServicesList,
    getMyAppoinmentListApi,
    deleteAppoinment,
    addAppoinmentConfirm, 
    deleteConfirmAppoinment, 
    suggetAlternative, 
    deleteAppoinmentByProvider, 
    createAppointmentByProvider
} from '../../services/Appointment';

export const createAppointmentAction = async(service) => {
    return await createAppointment(service)
}

export const createAppointmentActionByProvider = async (service) => {
    return await createAppointmentByProvider(service);
}


const createAppointmentPost = (res) => {
    return {
        type: actionTypes.APPOINTMENT_CREATE,
        value: res
    }
}

export const getAppointmentList = (serviceId) => {
    return dispatch => {
        getAppoinmentServicesList(serviceId).then(res => {
            if (res.data !== undefined) {
                dispatch(getServiceAppointmentList(res.data));
                // dispatch(clearError());
            }
        }).catch(err => {

            console.log('error service list action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getServiceAppointmentList = (res) => {
    return {
        type: actionTypes.APPOINTMENT_LIST,
        value: res
    }
}

export const getMyAppoinmentListAction = () => {
    return dispatch => {
        getMyAppoinmentListApi().then(res => {
            if (res.data !== undefined) {
                dispatch(getMyAppointmentListData(res.data));
                dispatch(clearError());
            }
        }).catch(err => {

            console.log('error service list action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getMyAppointmentListData = (res) => {
    return {
        type: actionTypes.MY_APPOINTMENT_LIST,
        value: res
    }
}

export const deleteAppoinmentAction = (Id, data) => {
    return dispatch => {
        deleteAppoinment(Id, data).then(res => {
            if (res.data !== undefined) {
                dispatch(deleteAppoinmentPut(res.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}
export const deleteAppoinmentByProviderAction = (Id, data) => {
    return dispatch => {
            deleteAppoinmentByProvider(Id, data).then(res => {
            if (res.data !== undefined) {
                dispatch(deleteAppoinmentPut(res.data));
            } 
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const deleteAppoinmentPut = (res) => {
    return {
        type: actionTypes.MY_APPOINTMENT_DELETE,
        value: res
    }
}



export const addAppoinmentConfirmAction = (Id) => {
    return dispatch => {
        addAppoinmentConfirm(Id).then().catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

export const deleteConfirmAppoinmentAction = (data) => {
    return dispatch => {
        deleteConfirmAppoinment(data).then().catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

export const suggetAlternativeAction = (data) => {
    return dispatch => {
        suggetAlternative(data).then().catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
