import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { appConstants } from "../../_Constants/app.constants";
import {
  updateProfileUser,
  resetStatus,
} from "../../redux/actions/AuthActions";
import { getUserListingAction } from "../../redux/actions/UserActions";
import {
  updateServiceAction,
  deleteServiceAction,
  EnrollNotificationGetAction,
  serviceListAction,
} from "../../redux/actions/ServiceActions";
import { updateServiceEnrollByProviderAction } from "../../redux/actions/ServiceEnrollActions";
import { getServiceScheduleAction } from "../../redux/actions/ServiceActions";
import { getAppointmentList } from "../../redux/actions/AppointmentActions";
import { Link } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import Confirm from "../../components/ConfirmWithDescription";
import Confirm from "../../components/Confirm";
import SimpleReactValidator from "simple-react-validator";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import {
  Modal,
  FormLabel,
  FormControl,
  Button,
  Row,
  Col,
  Card,
  Form,
} from "react-bootstrap";
import defaultImg from "../../assets/images/default.png";
import { addressValidate } from "../../services/authServices";
import ProviderAppointment from "../appointment/ProviderAppointment";
import UserAppointment from "../appointment/UserAppointment";
import SchedulePageView from "../schedule/SchedulePageView";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import $ from "jquery";
import Socketpage from "../../layout/socket";
import "./css/view-service.css"
// import { appConstants } from '../../_Constants/app.constants';

class ViewService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      error: "",
      success: "",
      imagePreviewUrl: "",
      imagePosterUrl: "",
      imageLogoUrl: "",
      loading: false,
      updateStatus: false,
      formData: {},
      updateFormData: {},
      userListing: [],
      enrollNotificationList: [],
      modal: false,
      modalData: {},
      deleteDescription: "",
      provider: false,
      total_schedule: 0,
      total_appoinment: 0,
      schedule_per: 0,
      contact_details_show: false,
      contact_details_editable: false,
      service_details_show: false,
      service_details_editable: false,
      service_schedule_show: true,
      service_enrollment_show: false,
      updateDescription: "",
      serviceSheduleScreen: false,
      selectedDate: null,
      myState: 0,
      phoneError: null
    };
    this.validator = new SimpleReactValidator();
    var elem = this;
    // appConstants.socket.on("response", function (data) {
    //   console.log("d.........................sage", data);

    //   if (data && data.type) {
    //     if (data.message !== "Appointment Accepted by Provider" && data.message !== "Appointment Declined by Provider") {
    //       if (data.message === "Un-Enroll By Service Provider") {
    //         window.location.href = "https://kunneckt.com/service";
    //       } else {
    //         setTimeout(() => {
    //           window.location.reload();
    //         }, 3000);
    //       }
    //     }
    //   }
    // });
  }

  myState1Fun = (args) => {
    this.state.myState = args;
  }

  async componentDidMount() {
    document.title = "Kunneckt | Edit Service";
    const { location } = this.props;
    if (location && location.state && location.state.data) {
      let propsData = this.props.location.state.data;
      let dataObj = {
        id: propsData.id,
        title: propsData.title ? propsData.title : "",
        email: propsData.email ? propsData.email : "",
        phone: propsData.phone ? propsData.phone : "",
        streetAddress: propsData.streetAddress ? propsData.streetAddress : "",
        city: propsData.city ? propsData.city : "",
        state: propsData.state ? propsData.state : "",
        zip: propsData.zip ? propsData.zip : "",
        description: propsData.description ? propsData.description : "",
        atLocation: propsData.atLocation,
        // isMultiItemService : propsData.isMultiItemService,
        price: propsData.price ? propsData.price : "",
        duration: propsData.duration ? propsData.duration : "",
        poster: propsData.poster ? propsData.poster : "",
        //imagePosterUrl : propsData.poster,
        logo: propsData.logo ? propsData.logo : "",
        //imageLogoUrl : propsData.logo,
        descriptionModal: false,
        updateDescription: "",
        provider: propsData.provider,
        per_week: propsData.per_week,
        per_session: propsData.per_session,
        youtube: propsData.youtube,
        twitter: propsData.twitter,
        facebook: propsData.facebook,
      };

      await this.props.getServiceScheduleAction(`?service_id=${propsData.id}`);
      await this.props.getAppointmentList(propsData.id);

      let provider = false;
      if (propsData.provider === localStorage.getItem("userId")) {
        provider = true;
        document.title = "Kunneckt | Edit Service";
      } else {
        document.title = "Kunneckt | View Service";
      }
      this.setState({
        formData: dataObj,
        imagePosterUrl: propsData.poster ? propsData.poster : "",
        imageLogoUrl: propsData.logo ? propsData.logo : "",
        provider: provider,
      });

      if (propsData.section && propsData.section === "appointment") {
        if (propsData.appointmentDate) {
          this.setState({
            selectedDate: new Date(propsData.appointmentDate),
          });
        }

        scroller.scrollTo("appointment_module", {
          duration: 1500,
          delay: 1000,
          smooth: true,
        });

        const _this = this;
        setTimeout(function () {
          $("#service_schedule_show").click();
          _this.setState({
            service_schedule_show: true,
          });
        }, 2000);
      } else {
        this.setState({
          selectedDate: new Date(),
        });
      }
    }

    this.props.getUserListingAction();
    this.props.EnrollNotificationGetAction();
    this.props.resetStatus({ signupError: "", success: "" });
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/login");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.serviceData &&
      nextProps.serviceData.services &&
      this.state.deleteStatus
    ) {
      this.setState({
        deleteStatus: false,
      });
      NotificationManager.success(nextProps.serviceData.success);
      this.props.history.push({
        pathname: "/service",
        state: {},
      });
    }
    if (
      nextProps.serviceData &&
      nextProps.serviceData &&
      this.state.updateStatus &&
      nextProps.serviceData.type === "serviceUpdate"
    ) {
      this.setState({
        loading: false,
        updateStatus: false,
      });
      NotificationManager.success(nextProps.serviceData.success);
      this.props.history.push({
        pathname: "/service",
        state: {},
      });
    }
    if (
      nextProps.serviceData &&
      nextProps.serviceData.services &&
      nextProps.serviceData.type === "notification"
    ) {
      const { location } = this.props;
      let notificationData = nextProps.serviceData.services
        ? nextProps.serviceData.services
        : [];
      notificationData = notificationData.sort(function (a, b) {
        return new Date(b.requested_at) - new Date(a.requested_at);
      });
      if (
        notificationData &&
        location &&
        location.state &&
        location.state.data &&
        location.state.data.id
      ) {
        let myServiceID = location.state.data.id;
        notificationData = notificationData.filter(function (row) {
          return row.serviceId === myServiceID;
        });
      }
      this.setState({
        enrollNotificationList: notificationData,
      });
    }
    if (
      nextProps.userDetail &&
      nextProps.userDetail.users &&
      nextProps.userDetail.type === "userList"
    ) {
      let userListing = nextProps.userDetail.users;

      userListing.push(JSON.parse(localStorage.getItem("profile")));

      this.setState({
        userListing: userListing,
      });
    }

    if (
      nextProps.serviceData &&
      nextProps.serviceData.scheduleRes &&
      nextProps.serviceData.type === "response"
    ) {
      let schedule = nextProps.serviceData.scheduleRes.data;
      // let total_schedule_count = 0;
      // for (let i in schedule) {
      //   if(schedule[i].total_count){
      //     total_schedule_count += parseInt(schedule[i].total_count);
      //   }
      // }
      this.setTotalSchedule(schedule);
    }

    if (nextProps.appointmentData && nextProps.appointmentData.appoinment) {
      let total_appoinment_count = 0;

      let appointmentData = nextProps.appointmentData.appoinment;
      if (appointmentData.length > 0) {
        for (let i in appointmentData) {
          if (appointmentData[i].schedule_count) {
            total_appoinment_count += parseInt(
              appointmentData[i].schedule_count
            );
          }
        }
      }

      this.setTotalAppoinment(total_appoinment_count);
    }
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let nameOption = e.target.name;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FILE_SIZE = 1498400;
    let SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
    ];
    if (file === undefined) {
      return false;
    }
    if (!SUPPORTED_FORMATS.includes(file.type)) {
      alert("File not supported.");
      return false;
    }
    if (file.size > FILE_SIZE) {
      alert("Image size has been exceeded.");
      return false;
    }
    if (file) {
      reader.onloadend = () => {
        if (nameOption === "poster") {
          this.setState({
            posterFile: file,
            imagePosterUrl: reader.result,
          });
        }
        if (nameOption === "logo") {
          this.setState({
            logoFile: file,
            imageLogoUrl: reader.result,
          });
        }
      };
      reader.readAsDataURL(file);
      //this.props.setFieldValue(this.props.field.name, file);
    }
  };

  deleteService = (serviceId) => {
    this.setState({
      deleteStatus: true,
    });
    let deleteData = {
      description: this.state.deleteDescription,
    };
    this.props.deleteServiceAction(serviceId, deleteData);
  };

  deleteDescriptionEdit = (e) => {
    this.setState({ deleteDescription: e.target.value });
  };

  handleChange = (e) => {
    if (this.state.provider) {
      let formData = this.state.formData;
      if (e.target && e.target.name === "duration" && e.target.value < 1) {
        formData[e.target.name] = "";
        var input = document.getElementById("durationBox");
        input.value = "Empty";
      }
      if (e.target.name === "atLocation") {
        formData[e.target.name] = e.target.checked ? "1" : "0";
      } else {
        formData[e.target.name] = e.target.value;
      }
      this.setState({
        formData: formData,
      });
    }
  };

  handleContactChange = (e) => {
    if (this.state.provider && this.state.contact_details_editable) {
      let formData = this.state.formData;
      if (e.target.name === "phone") {

        const val_length_without_dashes = e.target.value.replace(/[^0-9]/g, "").length;
        if (val_length_without_dashes !== 10) {
          this.setState({ phoneError: "Please enter valid phone number" })
        } else {
          this.setState({ phoneError: null })
        }
      }
      if (e.target && e.target.name === "duration" && e.target.value < 1) {
        formData[e.target.name] = "";
        var input = document.getElementById("durationBox");
        input.value = "Empty";
      }
      if (e.target.name === "atLocation") {
        formData[e.target.name] = e.target.checked ? "1" : "0";
      } else {
        formData[e.target.name] = e.target.value;
      }
      this.setState({
        formData: formData,
      });
    }
  };

  handleServiceChange = (e) => {
    if (this.state.provider && this.state.service_details_editable) {
      let formData = this.state.formData;
      if (e.target && e.target.name === "duration" && e.target.value < 1) {
        formData[e.target.name] = "";
        var input = document.getElementById("durationBox");
        input.value = "Empty";
      }
      if (e.target.name === "atLocation") {
        formData[e.target.name] = e.target.checked ? "1" : "0";
      } else {
        formData[e.target.name] = e.target.value;
      }
      this.setState({
        formData: formData,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // this.setState({descriptionModal: true})

    // Confirm(this.handleSubmitNext, null, this.updateDescriptionEdit);
    if (!this.state.phoneError) {
      Confirm(this.handleSubmitNext, null);
    }
  };

  updateDescriptionEdit = (e) => {
    this.setState({ updateDescription: e.target.value });
  };

  handleSubmitNext = () => {
    var dt = new Date();
    let form_data = new FormData();
    let formData = this.state.formData;
    formData.atLocation =
      formData.atLocation === false ? "0" : formData.atLocation;
    formData.atLocation =
      formData.atLocation === true ? "1" : formData.atLocation;

    for (var key in formData) {
      if (key !== "id") {
        form_data.append(key, formData[key]);
      }
    }
    if (this.state.file !== "") {
      form_data.append(
        "profile",
        this.state.file,
        dt.getTime() + this.state.file.name
      );
    }
    if (
      this.state.posterFile &&
      (this.state.posterFile !== "" || this.state.posterFile !== undefined)
    ) {
      form_data.append(
        "poster",
        this.state.posterFile,
        dt.getTime() + this.state.posterFile.name
      );
    }
    if (
      this.state.logoFile &&
      (this.state.logoFile !== "" || this.state.logoFile !== undefined)
    ) {
      form_data.append(
        "logo",
        this.state.logoFile,
        dt.getTime() + this.state.logoFile.name
      );
    }
    form_data.append("updateDescription2", this.state.updateDescription);

    this.setState({ descriptionModal: false });

    if (this.validator.allValid()) {
      this.props.resetStatus({ signupError: "", success: "" });
      this.setState(
        {
          loading: true,
          updateStatus: true,
          success: "",
        },
        () => {
          let addressUrl =
            "?address=" +
            formData.streetAddress +
            "&city=" +
            formData.city +
            "&state=" +
            formData.state;
          addressValidate(addressUrl)
            .then((res) => {
              let address = res.data.ZipCodeLookupResponse.Address;
              if (
                !formData.street_address &&
                !formData.city &&
                !formData.state &&
                !formData.zip
              ) {
                this.props.updateServiceAction(formData.id, form_data);
              } else if (
                address.Zip4 === formData.zip ||
                address.Zip5 === formData.zip
              ) {
                this.props.updateServiceAction(formData.id, form_data);
              } else {
                this.setState({
                  loading: false,
                  error: "Invalid Address.",
                });
              }
            })
            .catch((err) => {
              this.setState({
                loading: false,
                error: "Invalid Address.",
              });
            });
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  imageUpload = (id) => {
    document.getElementById(id).click();
  };

  IsAcceptedCheck = (row) => {
    let status_text = "None";
    if (row.is_approve === false || row.is_approve === "False") {
      status_text = "Denied";
    } else if (row.is_approve === true || row.is_approve === "True") {
      status_text = "Approved";
    } else if (row.is_approve === "waitlisted") {
      status_text = "Waitlisted";
    }

    return status_text;
  };

  IsAccepted = (cell, row) => {
    let status_text = this.IsAcceptedCheck(row);

    return <span>{status_text}</span>;
  };

  approveDeny = async (row, e) => {
    const is_approve = e.target.value;

    const data = {
      serviceId: row.serviceId,
      consumerId: row.userId,
      isApprove: is_approve,
    };
    await updateServiceEnrollByProviderAction(data);

    let enrollNotificationList = this.state.enrollNotificationList;
    let index1 = 0;
    for (let index in enrollNotificationList) {
      index1 = parseInt(index);

      if (enrollNotificationList[index] === row) {
        break;
      }
    }

    if (is_approve === "un-enroll") {
      NotificationManager.success("Un-enroll a user successfully.");
      enrollNotificationList.splice(index1, 1);
      this.props.getAppointmentList(data.serviceId);
    } else {
      NotificationManager.success(
        "Service enrollment status has been updated."
      );
      enrollNotificationList[index1].is_approve = is_approve;
      enrollNotificationList[index1].requested_at = new Date();
    }

    this.setState({ enrollNotificationList });
  };

  IsAction = (cell, row) => {
    return (
      <Form.Select onChange={(e) => this.approveDeny(row, e)}>
        <option value="" selected={this.IsAcceptedCheck(row) === "None"}>
          None
        </option>
        {!row.is_approve &&
          <>
            <option
              value="True"
              selected={this.IsAcceptedCheck(row) === "Approved"}
            >
              Approve
            </option>
            <option value="False" selected={this.IsAcceptedCheck(row) === "Denied"}>
              Deny
            </option>
          </>
        }
        <option
          value="waitlisted"
          selected={this.IsAcceptedCheck(row) === "Waitlisted"}
        >
          Waitlist
        </option>
        <option value="un-enroll">Un-Enroll</option>
      </Form.Select>
    );
  };

  IsDate = (cell, row) => {
    var dateTimeValue = moment(row.requested_at).format("YYYY/MM/DD");
    return <span>{dateTimeValue}</span>;
  };

  hideModals = (data) => {
    this.setState({
      modalData: data ? data : {},
      modal: !this.state.modal,
    });
  };

  setTotalSchedule = (scheduleData) => {
    var total_count = 0;
    if (scheduleData && scheduleData.length > 0) {
      for (let i in scheduleData) {
        var schedule_count = 0;

        var startDate = scheduleData[i].start_date_time;
        var endDate = scheduleData[i].end_date_time;
        startDate = moment(startDate).format("YYYY-MM-DD");
        endDate = moment(endDate).format("YYYY-MM-DD");
        var dates = [];

        var currDate = moment(scheduleData[i].start_date_time).startOf("day");
        var lastDate = moment(scheduleData[i].end_date_time).startOf("day");

        while (currDate.add(1, "days").diff(lastDate) < 0) {
          dates.push(currDate.clone().toDate());
        }

        var startTime = moment(
          scheduleData[i].recurrence_list.start_time,
          "h:mma"
        );
        var endTime = moment(scheduleData[i].recurrence_list.end_time, "h:mma");
        var total_hours = endTime.diff(startTime, "hours");

        for (const k in dates) {
          var scheduleExist = false;
          const arg_date = dates[k];
          var cellDate = moment(arg_date).format("YYYY-MM-DD");

          var cellTime = moment(arg_date, "h:mma");
          if (arg_date) {
            var start_time_h = startTime.format("HH");
            var start_time_m = startTime.format("mm");

            var endTime_h = endTime.format("HH");
            var endTime_m = endTime.format("mm");

            startTime = moment(cellDate).set({
              hour: start_time_h,
              minute: start_time_m,
            });
            endTime = moment(cellDate).set({
              hour: endTime_h,
              minute: endTime_m,
            });
          }
          var checkTimeIsExist = cellTime.isBetween(
            startTime,
            endTime,
            null,
            "[]"
          );

          if (
            scheduleData[i].recurrence_rule === "Daily" ||
            scheduleData[i].recurrence_rule === "None"
          ) {
            if (checkTimeIsExist) {
              scheduleExist = true;
            }
          }
          if (scheduleData[i].recurrence_rule === "Weekly") {
            var cellDay = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDay = cellDay.format("dddd");
            if (
              scheduleData[i].recurrence_list &&
              scheduleData[i].recurrence_list.data &&
              scheduleData[i].recurrence_list.data.length > 0
            ) {
              var scheduleDayArray = scheduleData[i].recurrence_list.data;
              for (let j in scheduleDayArray) {
                if (scheduleDayArray[j] === cellDay) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                  }
                }
              }
            }
          }

          if (scheduleData[i].recurrence_rule === "Monthly") {
            cellDate = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDate = cellDate.format("DD");
            if (
              scheduleData[i].recurrence_list &&
              scheduleData[i].recurrence_list.data &&
              scheduleData[i].recurrence_list.data.length > 0
            ) {
              var scheduleDateArray = scheduleData[i].recurrence_list.data;
              for (let k in scheduleDateArray) {
                if (scheduleDateArray[k] === 1) {
                  scheduleDateArray[k] = "01";
                }
                if (scheduleDateArray[k] === 2) {
                  scheduleDateArray[k] = "02";
                }
                if (scheduleDateArray[k] === 3) {
                  scheduleDateArray[k] = "03";
                }
                if (scheduleDateArray[k] === 4) {
                  scheduleDateArray[k] = "04";
                }
                if (scheduleDateArray[k] === 5) {
                  scheduleDateArray[k] = "05";
                }
                if (scheduleDateArray[k] === 6) {
                  scheduleDateArray[k] = "06";
                }
                if (scheduleDateArray[k] === 7) {
                  scheduleDateArray[k] = "07";
                }
                if (scheduleDateArray[k] === 8) {
                  scheduleDateArray[k] = "08";
                }
                if (scheduleDateArray[k] === 9) {
                  scheduleDateArray[k] = "09";
                }
                if (scheduleDateArray[k] === cellDate) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                  }
                }
              }
            }
          }

          if (scheduleExist) {
            schedule_count++;
          }
        }

        total_count += total_hours * schedule_count;
      }
    }

    if (this.state.formData.duration === "15") {
      total_count *= 4;
    } else if (this.state.formData.duration === "30") {
      total_count *= 2;
    } else if (this.state.formData.duration === "120") {
      total_count /= 2;
    }
    this.setState({ total_schedule: total_count });
  };

  setTotalAppoinment = (total_count, total_appoinment_count) => {
    this.setState({ total_appoinment: total_count });
  };

  checkSchedulePer() {
    let schedule_per = 0;
    if (this.state.total_schedule) {
      schedule_per =
        (this.state.total_appoinment / this.state.total_schedule) * 100;
    }
    return parseInt(schedule_per);
  }

  stateToggle(type) {
    let stateObj = {};
    stateObj[type] = !this.state[type];
    this.setState(stateObj);
  }

  render() {
    const {
      modalData,
      formData,
      updateFormData,
      enrollNotificationList,
      userListing,
    } = this.state;
    // const phoneRegExp =
    //   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    // const passwordRegex = /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/
    // const passwordRegex =
    //   /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;

    //  /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/; const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    var dataTest = [];
    var notifyArr = enrollNotificationList ? enrollNotificationList : [];
    if (notifyArr && userListing) {
      for (var i = 0; i < notifyArr.length; i++) {
        if (notifyArr[i].serviceId === this.state.formData.id) {
          for (var j = 0; j < userListing.length; j++) {
            if (userListing[j].id === notifyArr[i].userId) {
              let firstName = userListing[j].firstName
                ? userListing[j].firstName
                : "";
              let lastName = userListing[j].lastName
                ? userListing[j].lastName
                : "";
              notifyArr[i]["fullName"] = firstName + " " + lastName;
              var a = Object.assign(notifyArr[i], userListing[j]);
              dataTest.push(a);

              break;
            }
          }
        }
      }
    }

    const propsData = this.props.location.state?.data || [];
    let selectedDate = new Date();
    if (propsData.appointmentDate) {
      selectedDate = new Date(propsData.appointmentDate);
    }

    return (
      <React.Fragment>
        <Col xs={12} className="profile-form">
          <Form onSubmit={this.handleSubmit} name="serviceForm">
            <Card
              className="service-view-card"
              style={{
                background:
                  "transparent url('" +
                  this.state.imagePosterUrl +
                  "') no-repeat center center /cover",
              }}
            >
              <Col>
                <Row>
                  <Col className="mt-2 servicetitle" xs={12}>
                    <Form.Group>
                      <Form.Control
                        className="no-resize"
                        as="textarea"
                        name="title"
                        placeholder="Service Name*"
                        value={formData.title}
                        onChange={this.handleChange}
                        readOnly={!this.state.provider}
                      />
                      {this.validator.message(
                        "Service Name",
                        formData.title,
                        "required"
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2 mb-2">
                  <Col lg="3" className="col-sm-3 offset-md-0 text-center">
                    <div className="profileDiv">
                      {this.state.imageLogoUrl !== null &&
                        this.state.imageLogoUrl !== "" ? (
                        <>
                          <img
                            src={this.state.imageLogoUrl}
                            name="logo"
                            className="service-logo"
                            alt="Logo"
                          />
                          <br />{" "}
                          {this.state.provider && (
                            <i
                              onClick={() => this.imageUpload("logo")}
                              className="fa fa-pencil fa-pen"
                            ></i>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.provider && (
                            <div
                              className=""
                              onClick={() => this.imageUpload("logo")}
                            >
                              Logo <br /> (Click to update)
                            </div>
                          )}{" "}
                        </>
                      )}

                      <input
                        id="logo"
                        name="logo"
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleImageChange}
                        accept=".jpg,.jpeg,.png"
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Control
                        className="no-resize"
                        rows={4}
                        as="textarea"
                        placeholder="Service Description*"
                        name="description"
                        value={formData.description}
                        onChange={this.handleChange}
                        readOnly={!this.state.provider}
                      />
                      {this.validator.message(
                        "Service Description",
                        formData.description,
                        "required"
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {this.state.provider && (
                  <Row>
                    {/* <Col xs={11}> </Col> */}
                    <Col xs={12}>
                      <>
                        {this.state.imagePosterUrl !== null &&
                          this.state.imagePosterUrl !== "" ? (
                          <i
                            onClick={() => this.imageUpload("poster")}
                            className="fa fa-pencil fa-pen pull-right"
                            style={{ position: "relative" }}
                          ></i>
                        ) : (
                          <div
                            className="topbaner-iplo pull-right"
                            onClick={() => this.imageUpload("poster")}
                          >
                            Poster <br /> (Click to update)
                          </div>
                        )}
                      </>
                      <input
                        id="poster"
                        name="poster"
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleImageChange}
                        accept=".jpg,.jpeg,.png"
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Card>

            <Card className="service-view-card">
              <Card.Title className="ml-2" style={{ textAlign: "left" }}>
                <a
                  href="javascript:void(0);"
                  className="mr-2"
                  onClick={() => this.stateToggle("contact_details_show")}
                >
                  {this.state.contact_details_show ? (
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  )}
                </a>
                {this.state.provider ? (
                  <button
                    type="button"
                    style={{ width: "180px" }}
                    className= 
                    {
                      this.state.contact_details_editable
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                  
                    }
                    onClick={() => this.stateToggle("contact_details_editable")} 
                  >
                    Contact Details:{" "}
                  </button>
                ) : (
                  "Contact Details:"
                )}
              </Card.Title>

              {this.state.contact_details_show && (
                <Card.Body>
                  <Row className="mt-2">
                    <Col sm={6}>
                      <Form.Control
                        placeholder="Street Address*"
                        name="streetAddress"
                        value={formData.streetAddress}
                        onChange={this.handleContactChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                      {this.validator.message(
                        "Street Address",
                        formData.streetAddress,
                        "required"
                      )}
                    </Col>
                    <Col sm={6}>
                      <InputMask
                        {...this.props}
                        mask="(999)-999-9999"
                        className="form-control"
                        name="phone"
                        onChange={this.handleContactChange}
                        placeholder="Phone Number"
                        alwaysShowMask
                        value={formData.phone}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                      <span className="text-danger">{this.state.phoneError}</span>
                      {/* {this.validator.message('Phone Number', formData.phone, 'required|min:13|max:14')} */}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={4}>
                      <Form.Control
                        placeholder="City*"
                        name="city"
                        value={formData.city}
                        onChange={this.handleContactChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                      {this.validator.message(
                        "city",
                        formData.city,
                        "required"
                      )}
                    </Col>
                    <Col sm={2}>
                      <select
                        aria-label="Select State"
                        name="state"
                        value={formData.state}
                        onChange={this.handleContactChange}
                        className="form-control"
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      >
                        {appConstants.stateOptions.map((data, idx) => (
                          <option
                            value={data.value}
                            label={data.label}
                            key={idx}
                          />
                        ))}
                      </select>
                    </Col>
                    <Col sm={2}>
                      <Form.Control
                        placeholder="Zip*"
                        name="zip"
                        value={formData.zip}
                        onChange={this.handleContactChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                      {this.validator.message("zip", formData.zip, "required")}
                    </Col>

                    <Col sm={4}>
                      <Form.Control
                        placeholder="Email*"
                        name="email"
                        value={formData.email}
                        onChange={this.handleContactChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                      {this.validator.message(
                        "email",
                        formData.email,
                        "required"
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Twitter"
                        name="twitter"
                        value={formData.twitter}
                        onChange={this.handleContactChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Facebook"
                        name="facebook"
                        value={formData.facebook}
                        onChange={this.handleContactChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Youtube"
                        name="youtube"
                        value={formData.youtube}
                        onChange={this.handleContactChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.contact_details_editable
                        }
                      />
                    </Col>
                  </Row>

                  {this.state.provider && (
                    <Row className="top-btnss">
                      <Col sm={3} md={3} lg={6}>
                        {" "}
                      </Col>
                      <Col sm={3} md={3} lg={2}>
                        {this.state.error !== "" && (
                          <div className="alert alert-danger">
                            {this.state.error}
                          </div>
                        )}
                        {this.state.success !== "" && (
                          <div className="alert alert-success">
                            {this.state.success}
                          </div>
                        )}

                        {/* <button type="button" className="btn btn-info" onClick={e=>{Confirm(this.deleteService,formData.id, this.deleteDescriptionEdit)}}> Delete</button><br/> */}
                      </Col>
                      <Col sm={3} md={3} lg={2}>
                        <Link
                          to="/service"
                          type="cancel"
                          className="cancel-link btn btn-info"
                        >
                          Cancel
                        </Link>
                      </Col>
                      <Col sm={3} md={3} lg={2}>
                        <div className="form-group">
                          <div className="text-right">
                            {this.state.loading ? (
                              <button className="btn text-center" disabled>
                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-primary">
                                Ok
                              </button>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              )}
            </Card>

            <Card className="service-view-card">
              <Card.Title className="ml-2" style={{ textAlign: "left" }}>
                <a
                  href="javascript:void(0);"
                  className="mr-2"
                  onClick={() => this.stateToggle("service_details_show")}
                >
                  {this.state.service_details_show ? (
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  )}
                </a>
                {this.state.provider ? (
                  <button
                    type="button"
                    className=
                    {
                      this.state.service_details_editable
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                    }
                    style={{ width: "180px" }}
                    onClick={() => this.stateToggle("service_details_editable")}
                  >
                    Service details:{" "}
                  </button>
                ) : (
                  "Service details:"
                )}
              </Card.Title>
              {this.state.service_details_show && (
                <Card.Body>
                  <Row className="mt-2">
                    <Col sm={6}>
                      <Form.Control
                        placeholder="Service name"
                        name="title"
                        value={formData.title}
                        onChange={this.handleServiceChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.service_details_editable
                        }
                      />
                      {this.validator.message(
                        "service name",
                        formData.title,
                        "required"
                      )}
                    </Col>
                    <Col>
                      <Form.Check
                        label="Service offered only at business address"
                        name="atLocation"
                        value={formData.atLocation}
                        onChange={this.handleServiceChange}
                        checked={formData.atLocation === "1" ? true : false}
                        readOnly={
                          !this.state.provider ||
                          !this.state.service_details_editable
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col sm={6}>
                      <Form.Control
                        rows={4}
                        as="textarea"
                        placeholder="Service Description"
                        name="description"
                        value={formData.description}
                        onChange={this.handleServiceChange}
                        readOnly={
                          !this.state.provider ||
                          !this.state.service_details_editable
                        }
                      />
                      {this.validator.message(
                        "Service Description",
                        formData.description,
                        "required"
                      )}
                    </Col>
                    <Col sm={6}>
                      <Row>
                        <Col sm={6}>
                          {/* <Form.Control type="number" placeholder="Duration" id='durationBox' name="duration" value={formData.duration} onChange={this.handleChange} readOnly={!this.state.provider || !this.state.service_details_editable}/> */}
                          <Form.Group>
                            <Form.Select
                              readOnly={
                                !this.state.provider ||
                                !this.state.service_details_editable
                              }
                              className="form-control"
                              onChange={this.handleServiceChange}
                              value={formData.duration}
                              name="duration"
                              defaultValue={"15"}
                            >
                              <option value={"15"}>15 Minutes</option>
                              <option value="30">30 Minutes</option>
                              <option value="60">1 Hour</option>
                              <option value="120">2 Hours</option>
                            </Form.Select>
                          </Form.Group>

                          {this.validator.message(
                            "duration",
                            formData.duration,
                            "required"
                          )}
                        </Col>
                        <Col sm={6}>
                          <Form.Group>
                            <label
                              className="form-control"
                              readOnly={
                                !this.state.provider ||
                                !this.state.service_details_editable
                              }
                            >
                              Session
                            </label>
                          </Form.Group>

                          {this.validator.message(
                            "per session",
                            formData.per_session,
                            "required"
                          )}
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col sm={6}>
                          <Form.Control
                            type="number"
                            placeholder="Price"
                            id="price"
                            name="price"
                            value={formData.price}
                            onChange={this.handleServiceChange}
                            readOnly={
                              !this.state.provider ||
                              !this.state.service_details_editable
                            }
                          />
                          {this.validator.message(
                            "Price",
                            formData.price,
                            "required"
                          )}
                        </Col>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Select
                              readOnly={
                                !this.state.provider ||
                                !this.state.service_details_editable
                              }
                              className="form-control"
                              onChange={this.handleServiceChange}
                              value={formData.per_week}
                              name="per_week"
                            >
                              <option value="session">Session</option>
                              <option value="hour">Hour</option>
                              <option value="day">Day</option>
                              <option value="week">Week</option>
                              <option value="month">Month</option>
                            </Form.Select>
                          </Form.Group>
                          {this.validator.message(
                            "per week",
                            formData.per_week,
                            "required"
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {this.state.provider && (
                    <Row className="top-btnss">
                      <Col sm={3} md={3} lg={6}>
                        {" "}
                      </Col>
                      <Col sm={3} md={3} lg={2}>
                        {this.state.error !== "" && (
                          <div className="alert alert-danger">
                            {this.state.error}
                          </div>
                        )}
                        {this.state.success !== "" && (
                          <div className="alert alert-success">
                            {this.state.success}
                          </div>
                        )}

                        {/* <button type="button" className="btn btn-info" onClick={e=>{Confirm(this.deleteService,formData.id, this.deleteDescriptionEdit)}}> Delete</button><br/> */}
                      </Col>
                      <Col sm={3} md={3} lg={2}>
                        <Link
                          to="/service"
                          type="cancel"
                          className="cancel-link btn btn-info"
                        >
                          Cancel
                        </Link>
                      </Col>
                      <Col sm={3} md={3} lg={2}>
                        <div className="form-group">
                          <div className="text-right">
                            {this.state.loading ? (
                              <button className="btn text-center" disabled>
                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-primary">
                                Ok
                              </button>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              )}
            </Card>
            <Element name="appointment_module" className="element" />
          </Form>

          <Card className="service-view-card">
            <Card.Title className="ml-2" style={{ textAlign: "left" }}>
              <Row className="service-book">
                <Col sm={4} md={4} lg={4}>
                  <a
                    href="javascript:void(0);"
                    className="mr-2"
                    onClick={() => this.stateToggle("service_schedule_show")}
                    id="service_schedule_show"
                  >
                    {this.state.service_schedule_show ? (
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                    )}
                  </a>{" "}
                  Service Schedule:
                </Col>
                {this.state.provider && (
                  <Col sm={4} md={4} lg={4}>
                    <button
                      style={{ width: "100%" }}
                      className={"primary3d smt-2"}
                      onClick={() =>
                        this.setState({
                          serviceSheduleScreen:
                            !this.state.serviceSheduleScreen,
                        })
                      }
                    >
                      {" "}
                      {this.state.serviceSheduleScreen
                        ? "Go to Manage Appointment"
                        : "Go to Manage Schedule"}{" "}
                    </button>
                  </Col>
                )}
                {/* <Col xs={4} md={2}><Link to={{pathname: '/schedule', state: formData }} className="btn btn-info" > Service Schedule </Link></Col> */}

                <Col sm={4} md={4} lg={4}>
                  <span className="bookservice last-book-span">
                    {" "}
                    Service Booked:
                    {" "}
                    {this.state.myState === 0 ? "0" :
                      Math.round(((this.props.count / this.state.myState) * 100) * 100) / 100} %{" "}
                    {/* % Service Booked: {(this.state.myState2/this.state.myState) * 100} %{" "} */}
                  </span>{" "}
                </Col>
              </Row>
            </Card.Title>
            {this.state.service_schedule_show && (
              <Card.Body className="card-inner" style={{ display: "block" }}>
                {this.state.serviceSheduleScreen && formData.id && (
                  <SchedulePageView serviceId={formData.id} />
                )}
                {!this.state.serviceSheduleScreen && (
                  <>
                    {this.state.provider && selectedDate && (
                      <ProviderAppointment
                        {...this.props}
                        enrolled_users={dataTest}
                        selectedDate={selectedDate}
                        myState1Fun={this.myState1Fun.bind(this)}
                      />
                    )}

                    {!this.state.provider && selectedDate && (
                      <UserAppointment
                        {...this.props}
                        selectedDate={selectedDate}
                        myState1Fun={this.myState1Fun.bind(this)}
                      />
                    )}
                  </>
                )}
              </Card.Body>
            )}
          </Card>
          <Card className="service-view-card">
            <Card.Title className="ml-2" style={{ textAlign: "left" }}>
              <a
                href="javascript:void(0);"
                className="mr-2"
                onClick={() => this.stateToggle("service_enrollment_show")}
              >
                {this.state.service_enrollment_show ? (
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                )}
              </a>
              Service Enrollment:
            </Card.Title>
            {this.state.service_enrollment_show && (
              <Card.Body className="card-inner">
                <BootstrapTable data={dataTest} pagination search={false}>
                  <TableHeaderColumn
                    tdAttr={{ "data-attr": "ID" }}
                    dataField="id"
                    isKey
                    hidden={true}
                  >
                    Id
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="200"
                    tdAttr={{ "data-attr": "User Name" }}
                    dataField="username"
                  >
                    {" "}
                    User Name{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="200"
                    tdAttr={{ "data-attr": "Consumer Name" }}
                    dataField="fullName"
                  >
                    {" "}
                    User{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="200"
                    tdAttr={{ "data-attr": "Enroll Method" }}
                    dataField="enroll_type"
                  >
                    {" "}
                    Enroll Method{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    tdAttr={{ "data-attr": "Enroll status" }}
                    dataFormat={this.IsAccepted.bind(this)}
                  >
                    Enroll Status
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="200"
                    dataFormat={this.IsDate.bind(this)}
                    tdAttr={{ "data-attr": "Status Date" }}
                    dataField="requested_at"
                  >
                    Status Date
                  </TableHeaderColumn>
                  {formData.provider === localStorage.getItem("userId") && (
                    <TableHeaderColumn
                      width="100"
                      tdAttr={{ "data-attr": "Action" }}
                      dataFormat={this.IsAction.bind(this)}
                      dataSort={true}
                      searchable={false}
                    >
                      Pend Action
                    </TableHeaderColumn>
                  )}
                </BootstrapTable>
              </Card.Body>
            )}
          </Card>
        </Col>

        <Modal show={this.state.modal} onHide={(e) => this.hideModals({})}>
          <Modal.Header closeButton>
            {/*<Modal.Title>
              <img src={modalData.profile && modalData.profile?modalData.profile:defaultImg} height="50px"  alt={modalData && modalData.title} />&nbsp; User Detail
            </Modal.Title>*/}
            <Modal.Title> User Detail </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="">
                <img
                  src={
                    modalData.profile && modalData.profile
                      ? modalData.profile
                      : defaultImg
                  }
                  height="100x"
                  className="text-center"
                  alt={modalData && modalData.title}
                />
                <br />
                <p className="head-lab">
                  Full Name :{" "}
                  {modalData && modalData.fullName ? modalData.fullName : ""}
                </p>
                <p className="head-lab">
                  User Name :{" "}
                  {modalData && modalData.username ? modalData.username : ""}
                </p>
                <p className="head-lab">
                  Email : {modalData && modalData.email ? modalData.email : ""}
                </p>
                <p className="head-lab">
                  Phone : {modalData && modalData.phone ? modalData.phone : ""}
                </p>
                <p className="head-lab">
                  Street Address :{" "}
                  {modalData && modalData.street_address
                    ? modalData.street_address
                    : ""}
                </p>
                <p className="head-lab">
                  City : {modalData && modalData.city ? modalData.city : ""}
                </p>
                <p className="head-lab">
                  State : {modalData && modalData.state ? modalData.state : ""}
                </p>
                <p className="head-lab">
                  Zip : {modalData && modalData.zip ? modalData.zip : ""}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*  Description */}
        <Modal
          show={this.state.descriptionModal}
          onHide={(e) => this.setState({ descriptionModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title> Update Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormControl
              as="textarea"
              placeholder="Description"
              aria-label="Description"
              onChange={(e) =>
                this.setState({ updateDescription: e.target.value })
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: "20%" }}
              variant="primary"
              onClick={this.handleSubmitNext}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    userDetail: state.userReducer,
    appointmentData: state.appointmentReducer,
    count: state.countReducer.parentCounter,
    childCount: state.countReducer.childCounter
  };
};

const mapDispatchToProps = {
  getUserListingAction: getUserListingAction,
  EnrollNotificationGetAction: EnrollNotificationGetAction,
  updateUser: updateProfileUser,
  updateServiceAction: updateServiceAction,
  deleteServiceAction: deleteServiceAction,
  resetStatus: resetStatus,
  getServiceScheduleAction: getServiceScheduleAction,
  getAppointmentList: getAppointmentList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewService);
