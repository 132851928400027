import * as actionTypes from '../constant';

const initialState = {
    parentCounter: 0,
    childCounter: 0,
};

export const countReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INCREMENT_PARENT:
            return { ...state, parentCounter: action.value };
        case actionTypes.INCREMENT_CHILD:
            return { ...state, childCounter: action.value };
        default:
            return state;
    }
}