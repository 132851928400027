import React, { Component } from 'react'
import '../assets/css/login.css'
import Logo from '../assets/images/logo-bottomline.png'
import { userResetPassword, checkUserResetPassword } from '../services/authServices'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            success: '',
            loading: false,
            title: 'Reset Password',
            masked_name: "",
            passwordShown: false,
            ReTypePasswordShown: false
        }
    }

    togglePasswordShown = () => {
        this.setState({
          passwordShown: !this.state.passwordShown
        })
      }
    
      toggleReTypePasswordShown = () => {
        this.setState({
          ReTypePasswordShown: !this.state.ReTypePasswordShown
        })
      }

    handleResetPassword = (value) => {
        let key = new URLSearchParams(this.props.location.search).get("key")
        this.setState({ loading: true }, () => {
            userResetPassword(value,key).then(res => {
                this.setState({
                    success: true,
                    loading: false,
                    title: 'Password reset successfully.'
                })
            }).catch(err => {
                this.setState({
                    success: false,
                    loading: false,
                    title: 'No any account associated with this email.'

                })
            })
        })

    }


    componentDidMount() {
        document.title = 'Reset Password'
        let key = new URLSearchParams(this.props.location.search).get("key")
        checkUserResetPassword(key).then(res => {
            console.log(" res res res res", res.data.firstName);
            var full_name = res.data.firstName+res.data.lastName;
            var masked_name = full_name[0]+"***"+full_name.split("").pop();
            this.setState({
                masked_name: masked_name,
            })
        });
    }

    render() {
        const passwordRegex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        // const passwordRegex =  /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/
    // const passwordRegex = /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/

        return (
            <div className="login_page">
                <table className="highlight-line"></table>
                <table className="logo-center text-center">
                    <tbody>
                        <tr><td><img src={Logo} alt="" height="200px" /></td></tr>
                    </tbody>
                </table>
                <div id="rcornersbox">
                    <div className="login-prompt-text">Reset password</div>
                    <div className="login-prompt-text">{this.state.masked_name}</div>
                    <Formik
                        initialValues={{
                            password: '',
                            confirm_password:''

                        }}
                        onSubmit={values => {
                           
                            this.handleResetPassword({'new_password':values.password})
                            

                        }}
                        validationSchema={Yup.object().shape({

                            password: Yup.string().required('Password is required').min(8).matches(passwordRegex, 'Need one special character'),
                            confirm_password: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password'), null],
                                'Passwords must match',
                            )
                        })}
                    >
                        {
                            props => {
                                const { errors, handleChange, handleSubmit, values, touched } = props;
                                return (
                                    <form onSubmit={handleSubmit} >
                                        <div className="input-container">
                                        <div className="d-flex">
                                            <i className="fa fa-key icon"></i>
                                            <input className="input-field" type={this.state.passwordShown ? "text" : "password"} placeholder="New Password" name="password" onChange={handleChange} />
                                            <i className={`fa ${this.state.passwordShown ? 'fa-eye-slash' : 'fa-eye'} icon`} onClick={this.togglePasswordShown} style={{ cursor: 'pointer' }}></i>
                                           </div>
                                            {touched.password && <p className="text text-danger">{errors.password} </p>}
                                        </div>
                                        <div className="input-container">
                                        <div className="d-flex">
                                            <i className="fa fa-key icon"></i>
                                            <input className="input-field" type={this.state.ReTypePasswordShown ? "text" : "password"} placeholder="Retype new Password" name="confirm_password" onChange={handleChange} />
                                            <i className={`fa ${this.state.ReTypePasswordShown ? 'fa-eye-slash' : 'fa-eye'} icon`} onClick={this.toggleReTypePasswordShown} style={{ cursor: 'pointer' }}></i>
                                            </div>
                                            {touched.confirm_password && <p className="text text-danger">{errors.confirm_password} </p>}
                                        </div>
                                        {this.state.success !== '' && !this.state.success && <p className="alert alert-danger mt-2 ">{this.state.title}</p>}
                                        {this.state.success && <p className="alert alert-success mt-2 ">{this.state.title}  <Link to='/login'>Login Here</Link></p>}

                                        {this.state.loading ? <button className="btn"><i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i></button> : <button type="submit" className="btn" style={{color: '#212529', backgroundColor: '#0E76BC'}}> Reset Password </button>}

                                    </form>
                                )
                            }
                        }
                    </Formik>

                </div>
                <table className="highlight-line"></table>
            </div >
        )
    }
}

export default ResetPassword;