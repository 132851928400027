import React from 'react';
import Logo from '../assets/images/logo-bottomline.png'
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { signup, resetStatus } from '../redux/actions/AuthActions'

class Signup extends React.Component {
  constructor() {
    super()
    this.state = {
      error: '',
      success: '',
      loading: false,
      passwordShown: false,
      ReTypePasswordShown: false
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let nextResult = {}
    nextResult.error = ''
    if (nextProps.error !== undefined && nextProps.error !== '') {
      nextResult.error = nextProps.error
      nextResult.loading = false
    }
    if (nextProps.success !== undefined && nextProps.success === true) {
      nextResult.success = nextProps.success
      nextResult.loading = false

      nextProps.history.push({
        pathname: '/login',
        state: { newSignup: true }
      })
    }
    return { ...nextResult }
  }

  togglePasswordShown = () => {
    this.setState({
      passwordShown: !this.state.passwordShown
    })
  }

  toggleReTypePasswordShown = () => {
    this.setState({
      ReTypePasswordShown: !this.state.ReTypePasswordShown
    })
  }

  saveUser = (user) => {
    this.props.resetStatus({ signupError: '' })
    this.setState({ loading: true, error: '' }, () => { this.props.signup(user) })
  }
  componentDidMount() {
    this.props.resetStatus({ signupError: '' });
  }

  render() {
    document.title = 'Kunneckt | Signup'
    const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

    const passwordRegex = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)
    return (
      <div className="login_page">
        <table className="highlight-line"></table>
        <table className="logo-center text-center">
          <tbody>
            <tr><td><img src={Logo} alt="" height="200px" /></td></tr>
          </tbody>
        </table>
        <div id="rcornersbox">
          <div className="login-prompt-text">
            Sign into your account
          </div>
          <br />
          <Formik
            initialValues={{
              username: '',
              password: '',
              firstName: '',
              lastName: '',
              phone: '',
              email: '',
              confirm_password: ''

            }}
            onSubmit={values => {
              this.saveUser(values)
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required('Password is required')
                .matches(passwordRegex, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
                .min(8, "Password must contain 8 characters and one special character."),
              confirm_password: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password'), null],
                'Passwords must match',
              ),
              email: Yup.string().email('Email must be a valid email').required('Email is required'),
              firstName: Yup.string().required('FirstName is required'),
              lastName: Yup.string().required('LastName is required'),
              username: Yup.string().min(4).max(20).required('Username is requred.'),
              phone: Yup.string().min(10).max(14).matches(phoneRegExp, 'Phone number is not valid').required('Phone is required')
            })}
          >

            {
              props => {
                const { errors, handleChange, handleSubmit, values, touched, setFieldValue } = props;
                return (
                  <form onSubmit={handleSubmit}>

                    <div className="input-container">
                      <div className="d-flex">
                        <i className="fa fa-address-card-o icon"></i>
                        <input className="input-field" type="text" placeholder="First Name" name="firstName" onChange={handleChange} />

                      </div>
                      {touched.firstName && <p className="text text-danger">{errors.firstName}</p>}
                    </div>

                    <div className="input-container">
                      <div className="d-flex">
                        <i className="fa fa-address-card-o icon"></i>
                        <input className="input-field" type="text" placeholder="Last Name" name="lastName" onChange={handleChange} />
                      </div>
                      {touched.lastName && <p className="text text-danger">{errors.lastName}</p>}
                    </div>

                    <div className="input-container">
                      <div className="d-flex">
                        <i className="fa fa-envelope-o icon"></i>
                        <input className="input-field" type="text" placeholder="Email Address" name="email" onChange={handleChange} />

                      </div>
                      {touched.email && <p className="text text-danger"> {errors.email}</p>}
                    </div>

                    <div className="input-container">
                      <div className="d-flex">
                        <i className="fa fa-phone icon"></i>
                        {/* <input className="input-field" type="text" placeholder="Phone Number" name="phone" onChange={handleChange} /> */}
                        <InputMask {...this.props} mask="(999)-999-9999" className="input-field" name="phone" onChange={handleChange} placeholder="Phone Number" alwaysShowMask />
                      </div>
                      {touched.phone && <p className="text text-danger">{errors.phone} </p>}
                    </div>

                    <hr />

                    <div className="input-container">
                      <div className="d-flex">
                        <i className="fa fa-user icon"></i>
                        <input className="input-field" type="text" placeholder="Username" name="username" onChange={handleChange} />
                      </div>
                      {touched.username && <p className="text text-danger">{errors.username} </p>}
                    </div>

                    <div className="input-container">
                      <div className="d-flex">
                        <i className="fa fa-key icon"></i>
                        <input className="input-field" type={this.state.passwordShown ? "text" : "password"} placeholder="Password" name="password" onChange={handleChange} value={values.password} />
                        <i className={`fa ${this.state.passwordShown ? 'fa-eye-slash' : 'fa-eye'} icon`} onClick={this.togglePasswordShown} style={{ cursor: 'pointer' }}></i>
                        &nbsp;<i className="fa fa-times icon" onClick={() => setFieldValue('password', '')} aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                      </div>
                      {touched.password && <p className="text text-danger">{errors.password} </p>}
                    </div>

                    <div className="input-container">
                      <div className="d-flex">
                        <i className="fa fa-key icon"></i>
                        <input className="input-field" type={this.state.ReTypePasswordShown ? "text" : "password"} placeholder="Retype Password" name="confirm_password" onChange={handleChange} value={values.confirm_password} />
                        <i className={`fa ${this.state.ReTypePasswordShown ? 'fa-eye-slash' : 'fa-eye'} icon`} onClick={this.toggleReTypePasswordShown} style={{ cursor: 'pointer' }}></i>
                        &nbsp;<i className="fa fa-times icon" onClick={() => setFieldValue('confirm_password', '')} aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                      </div>
                      {touched.confirm_password && <p className="text text-danger">{errors.confirm_password} </p>}
                    </div>
                    {this.state.error !== '' && <div className="alert alert-danger">{this.state.error}</div>}
                    {this.state.loading ? <button className="btn btn-primary text-center" disabled><i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i></button> : <button type="submit" className="btn btn-primary">Sign Up for Kunneckt</button>}


                    <br /><br />
                  </form>
                )
              }
            }</Formik>
          <hr />

          <div className="prompt-text">

            <Link to="/login">I already have a Kunneckt account.</Link>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.signupSuccess
  }
}
const mapDispatchToProps = {
  signup: signup,
  resetStatus: resetStatus
}
export default connect(mapStateToProps, mapDispatchToProps)(Signup)