import * as actionTypes from '../constant';
const INITIAL_STATE = {};

export const appointmentReducer = (state = INITIAL_STATE, action) => {   
    switch (action.type) {
        case actionTypes.APPOINTMENT_CREATE: //--Service Schedule
            return {
                ...state,
                type: "create",
                service: action.value,
                success: 'Appontment created successfully'
            };

        case actionTypes.APPOINTMENT_LIST:
            return {
                ...state,
                type: "appoinmentList",
                appoinment: action.value,
                success: ''
            };

        case actionTypes.MY_APPOINTMENT_LIST:
            return {
                ...state,
                type: "myAppoinmentList",
                appoinment: action.value,
                success: ''
            };
            case actionTypes.MY_APPOINTMENT_DELETE:
                return {
                    ...state,
                    // users: [...state.users, action.value],
                    // user: action.value,
                    success: 'Appointment deleted successfully'
                };

        case actionTypes.LOG_ERROR:
            return {
                ...state,
                type: "",
                error: action.value,
                success: ''
            };
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                type: "",
                error: '',
                success: ''
            };
        default:
            return { ...state, error: '', success: '', type: '' };
    }
}
