import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../assets/css/login.css'
import Logo from '../assets/images/logo-bottomline.png'
import { loginUser, resetStatus } from '../redux/actions/AuthActions'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { getAllNotificationAction } from '../redux/actions/ServiceActions'

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: '',
			success: '',
			loading: false,
			newSignup: false,
			passwordShown: false
		}
	}


	onSubmit = (user) => {

		this.props.resetStatus({ error: '', success: '' })
		if (user.autoLogin == 1) {
			localStorage.setItem('username', user.username)
			localStorage.setItem('password', user.password)
			localStorage.setItem('autoLogin', 1)
			//this.props.getAllNotificationAction();
		} else {
			localStorage.removeItem('username')
			localStorage.removeItem('password')
			localStorage.removeItem('autoLogin')
		}

		this.setState({ loading: true, error: '' }, () => { this.props.login(user) })

	}

	static getDerivedStateFromProps(nextProps, state) {

		let nextResult = {}

		nextResult.error = ''

		if (nextProps.error !== undefined && nextProps.errors !== '' && nextProps.error !== state.error) {
			nextResult.error = nextProps.error
			nextResult.loading = false
			//nextProps.resetStatus({error:''})
		}
		if (nextProps.success !== undefined && nextProps.success !== state.success) {
			nextResult.success = nextProps.success
			nextResult.loading = false
			if (nextProps.success && localStorage.getItem('token') !== null) {
				nextProps.history.push({ pathname: '/my-appointment', state: nextProps.userProfileData })
			}

		}

		return { ...nextResult }
	}
	togglePasswordShown = () => {
		this.setState({
			passwordShown: !this.state.passwordShown
		})
	}


	componentDidMount() {
		document.title = 'Kunneckt | Login'
		let token = localStorage.getItem('token')
		this.props.resetStatus({ success: '', })
		if (token !== null) {
			//this.props.history.push('/profile');
			localStorage.clear()
		}
		if (this.props.location.state !== undefined && this.props.location.state.newSignup) {
			this.setState({
				newSignup: true
			}, () => {
				this.props.resetStatus({ error: '', success: '' })
				// const state = { ...history.location.state };
				// delete state.newSignup;
				// history.replace({ ...history.location, state });
			})
		}
	}



	render() {

		return (
			<div className="login_page">
				<table className="highlight-line"></table>
				<table className="logo-center text-center">
					<tbody>
						<tr><td><img src={Logo} alt="" height="200px" /></td></tr>
					</tbody>
				</table>
				<div id="rcornersbox">
					<div className="login-prompt-text">Sign into your account</div>
					<br />
					{this.state.newSignup && <div className="alert alert-success">Registration has been successfully completed.Please verify your email to login. </div>}
					<Formik
						enableReinitialize
						initialValues={{
							username: localStorage.getItem('username') !== null ? localStorage.getItem('username') : '',
							password: localStorage.getItem('password') !== null ? localStorage.getItem('password') : '',
							autoLogin: localStorage.getItem('autoLogin') == null ? '0' : localStorage.getItem('autoLogin')
						}}
						onSubmit={values => {
							this.onSubmit(values)

						}}
						validationSchema={Yup.object().shape({
							password: Yup.string().required('Password is a required field.'),
							username: Yup.string().required('Username is a required field.')
						})}
					>
						{
							props => {
								const { errors, handleChange, handleSubmit, setFieldValue, values, touched } = props;
								return (
									<form onSubmit={handleSubmit} autoComplete="on">
										<div className="input-container">
											<div className="d-flex">
												<i className="fa fa-user icon"></i>
												<input className="input-field" type="text" placeholder="Username" name="username" onChange={handleChange} value={values.username} autoComplete='on' />

											</div>
											{touched.username && <p className="text text-danger">{errors.username}</p>}

										</div>
										<div className="input-container">
											<div className="d-flex">
												<i className="fa fa-key icon"></i>
												<input className="input-field" type={this.state.passwordShown ? "text" : "password"} placeholder="Password" name="password" onChange={handleChange} value={values.password} autoComplete='on' />
												<i className={`fa ${this.state.passwordShown ? 'fa-eye-slash' : 'fa-eye'} icon`} onClick={this.togglePasswordShown} style={{ cursor: 'pointer' }}></i>
												&nbsp;<i className="fa fa-times icon" onClick={() => setFieldValue('password', '')} aria-hidden="true"></i>
											</div>
											{touched.password && <p className="text text-danger">{errors.password} </p>}
										</div>
										{this.state.error !== '' ? <p className="alert alert-danger mt-2 ">
											{this.state.error === 'user is not active' ? 'User is not active' : this.state.error}
										</p> : ''}
										<label className="checkbox-inline">
											<input type="checkbox" name="autoLogin" value="1" onChange={() => setFieldValue('autoLogin', values.autoLogin == 1 ? '0' : '1')} checked={values.autoLogin == 1} />
											&nbsp;Sign-in automatically from next time.
										</label>

										{this.state.loading ? <button className="btn btn-primary"><i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i></button> : <button type="submit" className="btn btn-primary">Sign In</button>}

									</form>
								)
							}
						}
					</Formik>

					<hr />



					<div className="prompt-text">
						First time on Kunneckt? Please <Link to="/register" >register here.</Link>

						<Link to="/forgot-password"> I forgot my password.</Link>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		error: state.authReducer.error,
		success: state.authReducer.success,
		userProfileData: state.authReducer.userProfileData,
		serviceReducer: state.serviceReducer,
	}
}

const mapDispatchToProps = {
	login: loginUser,
	resetStatus: resetStatus,
	getAllNotificationAction: getAllNotificationAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);