import React, { Component } from 'react'
import '../assets/css/login.css'
import Logo from '../assets/images/logo-bottomline.png'
import { userForgotPassword } from '../services/authServices'
import { Formik } from 'formik';
import * as Yup from 'yup';

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            success: '',
            loading: false,
            title: 'Forgot Password'

        }
    }
    handleforgotPassword = (value) => {
        this.setState({ loading: true }, () => {
            userForgotPassword(value).then(res => {
                this.setState({
                    success: true,
                    loading: false,
                    title: 'Password reset link sent successfully.'
                })
                setTimeout(() => {
                    this.setState({
                        success: '',
                        title: 'Forgot Password'
                    })
                  }, 3000);
            }).catch(err => {
                this.setState({
                    success: false,
                    loading: false,
                    title: 'Email not found.'

                })
            })
        })

    }


    componentDidMount() {
        document.title = 'Kunncekt | Forgot Password'
    }

    render() {

        return (
            <div className="login_page">
                <table className="highlight-line"></table>
                <table className="logo-center text-center">
                    <tbody>
                        <tr><td><img src={Logo} alt="" height="200px" /></td></tr>
                    </tbody>
                </table>
                <div id="rcornersbox">
                    <div className="login-prompt-text">Forgot Password</div>
                    <Formik
                        initialValues={{
                            email: ''

                        }}
                        onSubmit={values => {

                            this.handleforgotPassword(values)

                        }}
                        validationSchema={Yup.object().shape({

                            email: Yup.string().email().required('Email is required')
                        })}
                    >
                        {
                            props => {
                                const { errors, handleChange, handleSubmit, values, touched } = props;
                                return (
                                    <form onSubmit={handleSubmit} >
                                        <div className="input-container">
                                            <div className="d-flex">
                                                <i className="fa fa-envelope-o icon"></i>
                                                <input className="input-field" type="text" placeholder="Email" name="email" onChange={handleChange} />

                                            </div>
                                            {touched.email && <p className="text text-danger capitalize">{errors.email}</p>}

                                        </div>

                                        {this.state.success !== '' && !this.state.success && <p className="alert alert-danger mt-2 capitalize">{this.state.title}</p>}
                                        {this.state.success && <p className="alert alert-success mt-2 ">{this.state.title}</p>}


                                        {this.state.loading ? <button className="btn btn-primary"><i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i></button> : <button type="submit" className="btn btn-primary">Send Reset Password Link</button>}

                                    </form>
                                )
                            }
                        }
                    </Formik>

                </div>
            </div >
        )
    }
}

export default ForgotPassword;