import React from 'react'
import { Switch, Route } from "react-router-dom"
import NotFoundPage from '../pages/NotFoundPage'
import FrontHeader from './FrontHeader'
import FrontFooter from './FrontFooter'
import Routes from '../routes/RoleRoutes'

class Front extends React.Component{

	constructor(props){
	super(props);

		this.state ={
			toggleMenu : false
		}
		
	}
	toggleButton = () =>{
		//alert('hello');
		this.setState(prevState => ({toggleMenu : !prevState.toggleMenu}))
	}

	render(){
		return(

			<React.Fragment>
				<FrontHeader/>
                 <Switch>
                
                 {Routes.Guest.map((route,index)=>  <Route exact path={route.url} component={route.component} key={index}/>)}
                 <Route path="*" component={NotFoundPage} />
                
                </Switch>
   
   
			
                <FrontFooter/>
			</React.Fragment>

			)
	}
} 

export default Front