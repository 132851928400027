import React from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default (callback,data,deleteDescriptionEdit,text="Are you sure?") =>{
    confirmAlert({
        title: text,
        childrenElement: () => <textarea onChange={e => {deleteDescriptionEdit(e)}}/>,
        buttons: [
            {
                label: 'Yes',
                onClick: () => callback(data)
            },
            {
                label: 'No',
            }
        ]
    });
}