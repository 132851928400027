import React from 'react'
import { Switch, Route } from "react-router-dom"
import NotFoundPage from '../pages/NotFoundPage'


export default class MainContainer extends React.Component{
    componentDidMount(){
        if(localStorage.getItem('token')===null){
           
           this.props.history.push('/');
        }
    }
    render(){
        let  {roles}= this.props
        let routes=[...roles.Common]
        
        let allRoutes =  routes.map(route=>{
             if(route.subMenu!==undefined && route.subMenu.length){
                return route.subMenu
             }else{
                return route
             }
            
        })
        
        return(
            <div className={this.props.handleMainClass ? "main-container" : "main-container full"} id="main-container">
             
               
                	<Switch>
                		{/* <Route exact path="/main" component={Dashboard} /> */}
                        {allRoutes.map((route,index)=>  <Route exact path={route.url} component={route.component} key={index}/>)}
                        <Route path="*" component={NotFoundPage} />
                       
                	</Switch>
          
			</div>

        )
    }
}