import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { appConstants } from "../../_Constants/app.constants"
import { createServiceAction } from '../../redux/actions/ServiceActions'
import { updateProfileUser, resetStatus } from '../../redux/actions/AuthActions'
import { Link } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SimpleReactValidator from 'simple-react-validator';
import { addressValidate } from '../../services/authServices'

class AddService extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      posterFile: '',
      imagePosterUrl: '',
      logoFile: '',
      imageLogoUrl: '',
      error: '',
      success: '',
      loading: false,
      formData: {
        atLocation: 1,
        per_session: "session",
        duration: 15,
        per_week: "session",
      },
      phoneError: null
    }
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    document.title = "Kunneckt | Add Service"
    this.props.resetStatus({ signupError: '', success: '' })
    if (localStorage.getItem('token') === null) {
      this.props.history.push('/login')
    }
    if (this.props && this.props.location && this.props.location.state === undefined) {
      this.props.history.push('/service')
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceData && nextProps.serviceData.service && nextProps.serviceData.type == "create") {
      this.setState({
        loading: false,
        updateStatus: false
      })
      NotificationManager.success(nextProps.serviceData.success);
      let _this = this;
      setTimeout(function () {
        _this.props.history.push({
          pathname: "/service",
          state: {}
        });
      }, 100)
    }
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let nameOption = e.target.name;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FILE_SIZE = 1498400;
    let SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];
    if (file === undefined) {
      return false
    }
    if (!SUPPORTED_FORMATS.includes(file.type)) {
      alert("File not supported.")
      return false
    }
    if (file.size > FILE_SIZE) {
      alert("Image size has been exceeded.")
      return false
    }
    if (file) {
      reader.onloadend = () => {
        if (nameOption == "poster") {
          this.setState({
            posterFile: file,
            imagePosterUrl: reader.result
          });
        }
        if (nameOption == "logo") {
          this.setState({
            logoFile: file,
            imageLogoUrl: reader.result
          });
        }
      };
      reader.readAsDataURL(file);
      //this.props.setFieldValue(this.props.field.name, file);
    }
  }

  handleChange = (e) => {
    let formData = this.state.formData;
    if (e.target.name === "phone") {
      const val_length_without_dashes = e.target.value.replace(/[^0-9]/g, "").length;
      if (val_length_without_dashes !== 10) {
        this.setState({ phoneError: "Please enter valid phone number" })
      } else {
        this.setState({ phoneError: null })
      }
    }
    if (e.target && e.target.name == 'duration' && e.target.value < 1) {
      formData[e.target.name] = '';
      var input = document.getElementById('durationBox');
      input.value = "Empty";
    }
    if (e.target.name == "atLocation") {
      formData[e.target.name] = e.target.checked ? "1" : "0";
    } else {
      formData[e.target.name] = e.target.value;
    }
    this.setState({
      formData: formData
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.phoneError) {
      var dt = new Date();
      let form_data = new FormData();
      let formData = this.state.formData;
      for (var key in formData) {
        if (key == 'duration' || key == 'price') {
          form_data.append(key, formData[key].toString());
        } else {
          form_data.append(key, formData[key]);
        }
      }
      form_data.append("isMultiItemService", "0");
      if (this.state.posterFile !== '') {
        form_data.append('poster', this.state.posterFile, dt.getTime() + this.state.posterFile.name)
      }
      if (this.state.logoFile !== '') {
        form_data.append('logo', this.state.logoFile, dt.getTime() + this.state.logoFile.name)
      }
      if (this.validator.allValid()) {
        this.props.resetStatus({ signupError: '', success: '' })
        this.setState({
          loading: true,
          success: ''
        }, () => {
          const serviceArr = this.props.location.state;
          var status = serviceArr.some(function (el) {
            return (el.title === formData.title && el.zip === formData.zip);
          })
          if (status) {
            this.setState({
              loading: false,
              error: 'This service is already registered for this zip code.'
            })
            return false;
          }

          let addressUrl = "?address=" + formData.streetAddress + "&city=" + formData.city + "&state=" + formData.state
          addressValidate(addressUrl).then(res => {
            let address = res.data.ZipCodeLookupResponse.Address;
            if (!formData.street_address && !formData.city && !formData.state && !formData.zip) {
              this.props.createServiceAction(form_data)
            } else if (address.Zip4 == formData.zip || address.Zip5 == formData.zip) {
              this.props.createServiceAction(form_data)
            } else {
              this.setState({
                loading: false,
                error: 'Invalid Address.'
              })
            }
          }).catch(err => {
            this.setState({
              loading: false,
              error: 'Invalid Address.'
            })
          })
        })
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  }

  imageUpload = (id) => {
    document.getElementById(id).click();
  }

  render() {
    const { formData } = this.state
    const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

    // const passwordRegex = /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/
    const passwordRegex = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/
    //   const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return (
      <React.Fragment>
        <div className="profile-form pad-top0">
          <form className="form form-vertical" onSubmit={this.handleSubmit} name="serviceForm">
            <div className="banner-addservice">
              <div className="mid-mange">
                <div className="kv-avatar">
                  <div className="file-loading">
                    <input id="poster" name="poster" type="file" style={{ display: "none" }} onChange={this.handleImageChange} accept=".jpg,.jpeg,.png" />
                  </div>
                </div>
                {this.state.imagePosterUrl !== null && this.state.imagePosterUrl !== '' ?
                  <><img src={this.state.imagePosterUrl} name="poster" alt="Poster" className="siz-coverfit" /><br /> <i onClick={() => this.imageUpload("poster")} className="fa fa-pencil"></i></>
                  :
                  <div className="topbaner-iplo" onClick={() => this.imageUpload("poster")}>Poster <br /> (Click to update)</div>
                }
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-3 offset-md-0 text-center">
                  <div className="kv-avatar">
                    <div className="file-loading">
                      <input id="logo" name="logo" type="file" style={{ display: "none" }} onChange={this.handleImageChange} accept=".jpg,.jpeg,.png" />
                    </div>
                  </div>
                  <div className="kv-avatar-hint">
                    {/*<small>Select file  1500 KB</small>*/}
                  </div><br />
                  <div className="profileDiv add-service">
                    {this.state.imageLogoUrl !== null && this.state.imageLogoUrl !== '' ?
                      <><img src={this.state.imageLogoUrl} name="logo" alt="Logo" /><br /> <i onClick={() => this.imageUpload("logo")} className="fa fa-pencil fa-pen"></i></>
                      :
                      <div className="" onClick={() => this.imageUpload("logo")}>Logo <br /> (Click to update)</div>
                    }
                  </div>
                </div>

                <div className="col-sm-9">
                  <div className="form-group">
                    <label htmlFor="title">Title<span className="req">*</span></label>
                    <input type="text" className="form-control" name="title" defaultValue={formData.title} onChange={this.handleChange} />
                  </div>
                  {this.validator.message('title', formData.title, 'required')}
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="description">Description<span className="req">*</span></label>
                    <textarea type="text" className="form-control he-discription" name="description" defaultValue={formData.description} onChange={this.handleChange}></textarea>
                  </div>
                  {this.validator.message('Description', formData.description, 'required')}

                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="twitter">Twitter</label>
                        <input type="text" className="form-control" name="twitter" defaultValue={formData.twitter} onChange={this.handleChange} />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="facebook">Facebook</label>
                        <input type="text" className="form-control" name="facebook" defaultValue={formData.facebook} onChange={this.handleChange} />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="youtube">Youtube</label>
                        <input type="text" className="form-control" name="youtube" defaultValue={formData.youtube} onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>


                  <div className="form-group">
                    <label htmlFor="streetAddress">Street Address<span className="req">*</span></label>
                    <input type="text" className="form-control" name="streetAddress" defaultValue={formData.streetAddress} onChange={this.handleChange} />
                  </div>
                  <div className="form-group pull-right ">
                    <label className="form-check-label">
                      <input type="checkbox" name="atLocation" value={formData.atLocation} onChange={this.handleChange} checked={formData.atLocation == "1" ? true : false} />&nbsp;Offered at this location
                    </label>
                  </div>
                  {this.validator.message('streetAddress', formData.streetAddress, 'required')}
                  <div className="form-group">
                    <label htmlFor="city">City<span className="req">*</span></label>
                    <input type="text" className="form-control" name="city" defaultValue={formData.city} onChange={this.handleChange} />
                  </div>
                  {this.validator.message('city', formData.city, 'required')}
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="state">State<span className="req">*</span></label>
                            <select
                              className="form-control"
                              name="state"
                              defaultValue={formData.state}
                              onChange={this.handleChange}
                              style={{ display: 'block' }}>
                              <option value="" label="Select State" />
                              {appConstants.stateOptions.map((data, idx) => (
                                <option value={data.value} label={data.label} key={idx} />
                              ))}

                            </select>
                          </div>
                          {this.validator.message('state', formData.state, 'required')}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="zip">Zip<span className="req">*</span></label>
                            <input type="text" className="form-control" name="zip" defaultValue={formData.zip} onChange={this.handleChange} />
                          </div>
                          {this.validator.message('Zip', formData.zip, 'required')}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="phone">Phone Number<span className="req">*</span></label>
                            {/* <input type="text" className="form-control" name="phone" defaultValue={formData.phone}  onChange={this.handleChange}/> */}
                            <InputMask {...this.props} mask="(999)-999-9999" className="form-control" name="phone" onChange={this.handleChange} placeholder="Phone Number" alwaysShowMask defaultValue={formData.phone} />
                            <span className="text-danger">{this.state.phoneError}</span>
                          </div>
                          {this.validator.message('Phone Number', formData.phone, 'required|min:14')}

                          <div className="form-group">
                            <label htmlFor="email">Email Address<span className="req">*</span></label>
                            <input type="email" className="form-control" name="email" defaultValue={formData.email} onChange={this.handleChange} />
                          </div>
                          {this.validator.message('Email Address', formData.email, 'required')}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="border-circled">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="duration">Service Duration<span className="req">*</span></label>
                              {/* <input type="number" className="form-control" id='durationBox' name="duration" defaultValue={formData.duration} onChange={this.handleChange}/> */}

                              <select className="form-control" onChange={this.handleChange} value={formData.duration} name="duration">
                                <option value="15">15 Minutes</option>
                                <option value="30">30 Minutes</option>
                                <option value="60">1 Hour</option>
                                <option value="120">2 Hours</option>
                              </select>
                            </div>
                            {this.validator.message('Duration', formData.duration, 'required')}
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="per_session">Service Per Session<span className="req">*</span></label>
                              {/* <select className="form-control" onChange={this.handleChange} value={formData.per_session} name="per_session">
                                  <option value="session">Session</option>
                                </select> */}
                              <label className="form-control">Session</label>
                            </div>
                            {this.validator.message('per_session', formData.per_session, 'required')}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="zip">Service Price<span className="req">*</span></label>
                              <input type="number" className="form-control" name="price" defaultValue={formData.price} onChange={this.handleChange} />
                            </div>
                            {this.validator.message('price', formData.price, 'required')}
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="zip"> Service Per Week<span className="req">*</span></label>
                              <select className="form-control" onChange={this.handleChange} value={formData.per_week} name="per_week">
                                <option value="session">Session</option>
                                <option value="hour">Hour</option>
                                <option value="day">Day</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                              </select>
                            </div>
                            {this.validator.message('per_week', formData.per_week, 'required')}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="row top-btnss">
                    <div className='col-md-6 offset-md-6'>
                      {this.state.error !== '' && <div className="alert alert-danger">{this.state.error}</div>}
                      {this.state.success !== '' && <div className="alert alert-success">{this.state.success}</div>}
                    </div>
                    <div className="col-md-3 offset-md-6">
                      <Link to="/service" type="cancel" className="cancel-link btn btn-outline-light">Cancel</Link>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <div className="text-right">
                          {this.state.loading ? <button className="btn text-center" disabled>
                            <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                          </button> : <button type="submit" className="btn btn-light">Ok</button>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    userProfileData: state.authReducer.userProfileData,
  }
}

const mapDispatchToProps = {
  updateUser: updateProfileUser,
  createServiceAction: createServiceAction,
  resetStatus: resetStatus
}
export default connect(mapStateToProps, mapDispatchToProps)(AddService);