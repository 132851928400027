import * as actionTypes from '../constant';

import {getInviteServices,getServicesEnroll, createServiceEnroll, updateServiceEnrollByProvider, deleteServiceEnroll } from '../../services/ServicesEnroll';

export const enrollServiceAction = (service) => {
    return dispatch => {
        createServiceEnroll(service).then(res => {
            if (res.data !== undefined) {
                dispatch(createEnrollServicePost(res.data));
            }

        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const createEnrollServicePost = (res) => {
    return {
        type: actionTypes.SERVICE_ENROLL_CREATE,
        value: res
    }
}

export const updateServiceEnrollByProviderAction = async (data) => {
    return await updateServiceEnrollByProvider(data).then().catch();
}

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
