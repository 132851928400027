import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { appConstants } from "../../_Constants/app.constants"
import { getAppointmentList } from '../../redux/actions/AppointmentActions'
import { getServiceScheduleAction, updateServiceScheduleAction } from '../../redux/actions/ServiceActions'
import { resetStatus } from '../../redux/actions/AuthActions'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "react-datepicker/dist/react-datepicker.css";
import "./schedule.css"
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import $ from 'jquery';
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import { Container, Row, FormLabel, Card, Modal, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import CalendarComponent from './CalendarPage';
import Select from 'react-select';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, ViewsDirective, RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
var todayDate = moment().format("YYYY-MM-DD");

class SchedulePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      success: '',
      loading: false,
      daysOption: [],
      availabilityTime: [],
      daysDataValue: {},
      formData: {
        "recurrence_list": []
      },
      timeSloat: ['5 min', '30 min', '1 hrs'],
      selectedTimeSloat: 1,
      selectedInterval: 60,
      timeSloats: "1 hrs",
      showValidationMsg: false,
      showSchedule: true
    }
    this.validator = new SimpleReactValidator();
  }

  setAvailabilityTime() {
    var tt = "01:00 AM";
    var interval = 60;
    let timeList = [];
    for (let i = 1; i < 24; i++) {
      if (moment(todayDate + ' ' + tt, "YYYY-MM-DD hh:mm A").format("x") <= moment(todayDate + ' 00:00:00', "YYYY-MM-DD hh:mm A").add(i * interval, 'minutes').format("x")) {
        timeList.push(moment(todayDate + ' 00:00:00', "YYYY-MM-DD hh:mm A").add(i * interval, 'minutes').format("hh:mm A"));
      }
    }
    this.setState({ availabilityTime: timeList })
  }

  componentDidMount() {
    
    document.title = "Kunneckt | Add Schedule"
    this.props.resetStatus({ signupError: '', success: '' })
    if (localStorage.getItem('token') === null) {
      // this.props.history.push('/login');
      window.location.href = '/login';
    }
    else if (this.props && this.props.location && this.props.location.schedule){
      let formData = this.props.location.schedule;
      formData['start_date_time'] = new Date(formData['start_date_time']);
      formData['end_date_time'] = new Date(formData['end_date_time']);
      formData["start_time"] = formData["recurrence_list"].start_time;
      formData["end_time"] = formData["recurrence_list"].end_time;
      formData["recurrence_list"] = formData["recurrence_list"].data;

      this.setState({formData:formData});
      
      this.changeDaysOption(formData['recurrence_rule']);
    }
    
    if (!this.props || !this.props.location || !this.props.location.state) {
      this.props.history.push('/service');
    }
    else{
      this.setAvailabilityTime();
      this.props.getServiceScheduleAction(`?service_id=${this.props.location.state.id}`)
      // this.props.getAppointmentList(this.props.location.state.id);
    }
  }

  changeDaysOption(recurrence_rule){
    let daysOption = [];
    if (recurrence_rule === "Weekly") {
      daysOption = [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
      ];
    }
    if (recurrence_rule === "Monthly") {
      for (var i = 1; i <= 31; i++) {
        daysOption.push({ value: i, label: i });
      }
    }
    this.setState({ daysOption: daysOption });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.serviceData && nextProps.serviceData.scheduleRes && nextProps.serviceData.type == "response") {
      this.setState({ serviceScheduleData: nextProps.serviceData.scheduleRes.data, showSchedule: false })

      let _this = this;
      setTimeout(function(){
        _this.setState({showSchedule: true});
      }, 200);
    }

    if (nextProps.serviceData && nextProps.serviceData.scheduleRes && nextProps.serviceData.type === "update") {
      this.setState({ loading: false, formData: { recurrence_list: [] }, })
      NotificationManager.success(nextProps.serviceData.scheduleRes.message);
    }
  }

  handleChange = (field, e) => {
    let formData = this.state.formData;
    if (field === 'recurrence_list') {
      let dataList = [];
      for (let x in e) {
        dataList.push(e[x].value);
      }
      formData[field] = dataList;
    } else if (field === 'start_date_time' || field === 'end_date_time') {
      formData[field] = e;
    } else {
      formData[e.target.name] = e.target.value;
    }
    this.setState({ formData: formData })
  }

  handleRuleChange = (field, e) => {
    let formData = this.state.formData;
    
    formData[e.target.name] = e.target.value;
    this.setState({ formData: formData });

    this.changeDaysOption(e.target.value);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let formData = JSON.parse(JSON.stringify(this.state.formData));
    formData.service_id = this.props.location.state.id;
    if (formData.recurrence_rule === "None") {
      formData.end_date_time = formData.start_date_time;
    }
    formData.recurrence_list = {
      start_time: formData.start_time,
      end_time: formData.end_time,
      data: formData.recurrence_list ? formData.recurrence_list : []
    }
    
    if (this.validator.allValid()) {
      let [recuranceStatus, total_count] = this.checkScheduleAvailable();
      
      if(recuranceStatus){
        formData.total_count = total_count;
        this.props.updateServiceScheduleAction(formData);
        let serviceScheduleData = this.state.serviceScheduleData;

        if(formData.id){
          for(let x in serviceScheduleData){
            if(formData.id === serviceScheduleData[x].id){
              delete serviceScheduleData[x];
            }
          }
        }

        serviceScheduleData.push(formData);
        this.setState({serviceScheduleData});

      }
      else{
        this.setState({showValidationMsg:true});
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  checkScheduleAvailable = () => {
    let total_count = 0;
    let end_date_time_iso = this.state.formData.end_date_time.toISOString();
    end_date_time_iso = end_date_time_iso.replace("-","").replace("-","");
    end_date_time_iso = end_date_time_iso.replace(":","").replace(":","");
    end_date_time_iso = end_date_time_iso.replace(".","");
    end_date_time_iso = end_date_time_iso.replace("000Z","Z");

    let start_date_time = this.state.formData.start_date_time.toDateString();
    start_date_time +=" "+this.state.formData.start_time;
    start_date_time = Date.parse(start_date_time);
    start_date_time = new Date(start_date_time);


    let end_date_time = this.state.formData.start_date_time.toDateString();
    end_date_time +=" "+this.state.formData.end_time;
    end_date_time = Date.parse(end_date_time);
    end_date_time = new Date(end_date_time);

    let recurrenceRule = "";
    if(this.state.formData.recurrence_rule === "None"){
      recurrenceRule = "FREQ=DAILY;INTERVAL=1;COUNT=1;";
      return this.checkDateAvailable(start_date_time, end_date_time, recurrenceRule);
    }
    else if(this.state.formData.recurrence_rule === "Daily"){
      recurrenceRule = "FREQ=DAILY;INTERVAL=1;UNTIL="+end_date_time_iso+";";
      return this.checkDateAvailable(start_date_time, end_date_time, recurrenceRule);
    }
    else if(this.state.formData.recurrence_rule === "Weekly"){
      let recurrence_days = [];
      let recurrence_list_day_map = {
        'Monday': 'MO',
        'Tuesday': 'TU',
        'Wednesday': 'WE',
        'Thursday': 'TH',
        'Friday': 'FR',
        'Saturday': 'SA',
        'Sunday': 'SU',
      };

      for(let x in this.state.formData.recurrence_list){
        recurrence_days.push(recurrence_list_day_map[this.state.formData.recurrence_list[x]]);
      }
      recurrenceRule = "FREQ=WEEKLY;BYDAY="+recurrence_days.join(",")+";INTERVAL=1;UNTIL="+end_date_time_iso+";";
      return this.checkDateAvailable(start_date_time, end_date_time, recurrenceRule);
    }
    else if(this.state.formData.recurrence_rule === "Monthly"){
      for(let x in this.state.formData.recurrence_list){
        let date = this.state.formData.recurrence_list[x];
        recurrenceRule = "FREQ=MONTHLY;BYMONTHDAY="+date+";INTERVAL=1;UNTIL="+end_date_time_iso+";";
        
        let [recuranceStatus, totalCount] = this.checkDateAvailable(start_date_time, end_date_time, recurrenceRule);
        total_count = total_count + totalCount;
        if(!recuranceStatus){
          return [false, total_count];
        }
      }
    }
    return [true, total_count];
  }

  checkDateAvailable(start_date_time, end_date_time, recurrenceRule){
    var hours = Math.abs(end_date_time.getTime() - start_date_time.getTime()) / 3600000;

    let dates = this.recObject.getRecurrenceDates(start_date_time, recurrenceRule);
    for(let x in dates){
      var startDate1 = new Date(dates[x]);
      if(this.checkScheduleExist(startDate1, this.state.formData.id)){
        return [false, dates.length*hours];
      }
    }
    return [true, dates.length*hours];
  }

  handleCheckboxChange = (value, event) => {
    let formData = this.state.formData;
    formData.recurrence_list = formData.recurrence_list ? formData.recurrence_list : [];
    if (event.target.checked === true && !formData.recurrence_list.includes(value)) {
      formData.recurrence_list.push(value.toString());
    } else {
      var index = formData.recurrence_list.indexOf(value.toString());
      if (index >= 0) {
        formData.recurrence_list.splice(index, 1);
      }
    }
    this.setState({ formData: formData })
  }

  handleDateChange = (field, e) => {
    let formData = this.state.formData;
    formData[field] = e;
    this.setState({ formData: formData })
  }


  checkScheduleExist(arg_date, schedule_id=null) {
    var scheduleExist = false;
    var scheduleData = this.state.serviceScheduleData;
    if (scheduleData && scheduleData.length > 0) {
      for (let i in scheduleData) {
        if(schedule_id === scheduleData[i].id){
          continue;
        }

        var startDate = scheduleData[i].start_date_time;
        var endDate = scheduleData[i].end_date_time;
        startDate = moment(startDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');
        var cellDate = moment(arg_date).format('YYYY-MM-DD');
        var checkDateIsExist = moment(cellDate).isBetween(startDate, endDate, undefined, '[]');
        if (checkDateIsExist) {
          // if (scheduleData[i].recurrence_rule == 'Daily') {
          var startTime = moment(scheduleData[i].recurrence_list.start_time, 'h:mma');
          var endTime = moment(scheduleData[i].recurrence_list.end_time, 'h:mma');
          var cellTime = moment(arg_date, 'h:mma');
          if (arg_date) {
            var cellDate = arg_date.getDate();
            startTime = moment(startTime).set('date', cellDate)
            endTime = moment(endTime).set('date', cellDate)
          }
          var checkTimeIsExist = cellTime.isBetween(startTime, endTime, null, '[]');

          if (scheduleData[i].recurrence_rule == 'Daily') {
            if (checkTimeIsExist) {
              scheduleExist = true;
            }
          }
          if (scheduleData[i].recurrence_rule == 'Weekly') {
            var cellDay = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDay = cellDay.format('dddd');
            if (scheduleData[i].recurrence_list && scheduleData[i].recurrence_list.data && scheduleData[i].recurrence_list.data.length > 0) {
              var scheduleDayArray = scheduleData[i].recurrence_list.data;
              for (let j in scheduleDayArray) {
                if (scheduleDayArray[j] === cellDay) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                  }
                }
              }
            }
          }

          if (scheduleData[i].recurrence_rule == 'Monthly') {
            var cellDate = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDate = cellDate.format('DD');
            if (scheduleData[i].recurrence_list && scheduleData[i].recurrence_list.data && scheduleData[i].recurrence_list.data.length > 0) {
              var scheduleDateArray = scheduleData[i].recurrence_list.data;
              for (let k in scheduleDateArray) {
                if (scheduleDateArray[k] == 1) {
                  scheduleDateArray[k] = '01';
                }
                if (scheduleDateArray[k] == 2) {
                  scheduleDateArray[k] = '02';
                }
                if (scheduleDateArray[k] == 3) {
                  scheduleDateArray[k] = '03';
                }
                if (scheduleDateArray[k] == 4) {
                  scheduleDateArray[k] = '04';
                }
                if (scheduleDateArray[k] == 5) {
                  scheduleDateArray[k] = '05';
                }
                if (scheduleDateArray[k] == 6) {
                  scheduleDateArray[k] = '06';
                }
                if (scheduleDateArray[k] == 7) {
                  scheduleDateArray[k] = '07';
                }
                if (scheduleDateArray[k] == 8) {
                  scheduleDateArray[k] = '08';
                }
                if (scheduleDateArray[k] == 9) {
                  scheduleDateArray[k] = '09';
                }

                if (scheduleDateArray[k] === cellDate) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                  }
                }
              }
            }
            
          }
        }
      }
    }

    return scheduleExist;
  }

  hideModals(){
    this.setState({showValidationMsg:false});
  }


  onRenderCell(args) {
    const scheduleExist = this.checkScheduleExist(args.date);

    if (scheduleExist) {
      args.element.classList.replace("e-work-cells", "orange-work-cells");
      args.element.classList.remove("e-work-hours");
    }
  }

  checkChecked(value){
    return this.state.formData.recurrence_list.indexOf(value+"") == -1?false:true;
  }

  render() {
    const { formData, daysOption, availabilityTime, daysDataValue, secdArr } = this.state
    var locationState = '-'
    if(this.props.location.state && this.props.location.state.title){
       locationState = this.props.location.state.title;
    }
    
    return (
      
      <React.Fragment>
        <div className="schedule">
          <div className="container-fluid">
            <div className="content-setion ">
              <div className="section_title m-25">
                <h3 className="page-title"> Change Service Schedule </h3>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      {this.state.showSchedule &&
                      <ScheduleComponent readonly={true} renderCell={this.onRenderCell.bind(this)} selectedDate={new Date()} cssClass='schadule-table' showTimeIndicator={false} timeScale={{ enable: true, interval: this.state.selectedInterval, slotCount: 1 }} eventSettings={{
                        dataSource: this.state.scheduleData,
                        fields: {
                          id: 'Id',
                          subject: { name: 'Subject' },

                          startTime: { name: 'StartTime' },
                          endTime: { name: 'EndTime' }
                        }
                      }}>
                        <ViewsDirective>
                        </ViewsDirective>
                        <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
                      </ScheduleComponent>
                    }

                      <div className='RecurrenceEditor' style={{ display: "none" }}>
                        <RecurrenceEditorComponent id='RecurrenceEditor' ref={t => this.recObject = t}></RecurrenceEditorComponent>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h3>Service:- {locationState ? locationState : '-'}</h3>
                      <form onSubmit={this.handleSubmit} name="schedule" id="myForm">
                        <div className="gray_boxx">
                          <div className="row">
                            <div className="col-md-12">
                              <label htmlFor="recurrence_rule">Recurrence Rule<span className="req">*</span></label>
                              <div className="form-group">
                                <select id="recurrence_rule" className="form-control" name="recurrence_rule" value={formData.recurrence_rule ? formData.recurrence_rule : ''} onChange={e => this.handleRuleChange('recurrence_rule', e)}>
                                  <option value="" label="Select Rule" />
                                  <option value="Daily" label="Daily" />
                                  <option value="Weekly" label="Weekly" />
                                  <option value="Monthly" label="Monthly" />
                                  <option value="None" label="None" />
                                </select>
                                {this.validator.message('Recurrence Rule', formData.recurrence_rule, 'required')}
                              </div>
                            </div>

                            <div className={formData && formData.recurrence_rule === 'None' ? 'col-md-12' : 'col-md-6'}>
                              <label htmlFor="start_date_time">
                                {formData && formData.recurrence_rule === 'None' ? 'Date' : 'Start Date'}
                                <span className="req">*</span></label>
                              <div className="form-group">
                                <DatePicker
                                  width='100'
                                  id="start_date_time"
                                  name="start_date_time"
                                  placeholderText="Select Start Date"
                                  className="form-control wh-300"
                                  minDate={new Date()}
                                  selected={formData.start_date_time}
                                  dateFormat={appConstants.dateFormate}
                                  onChange={value => this.handleDateChange('start_date_time', value)}
                                  autoComplete={false}
                                />
                                {this.validator.message('Start Date', formData.start_date_time, 'required')}
                              </div>
                            </div>

                            {formData && (formData.recurrence_rule !== 'None') &&
                              <div className="col-md-6">
                                <label htmlFor="end_date_time">End Date<span className="req">*</span></label>
                                <div className="form-group">
                                  <DatePicker
                                    id="end_date_time"
                                    name="end_date_time"
                                    placeholderText="Select End Date"
                                    className="form-control wh-300"
                                    minDate={new Date()}
                                    selected={formData.end_date_time}
                                    dateFormat={appConstants.dateFormate}
                                    onChange={value => this.handleDateChange('end_date_time', value)}
                                    autoComplete={false}
                                  />
                                </div>
                              </div>
                            }
                            
                            {formData && (formData.recurrence_rule === 'Monthly' || formData.recurrence_rule === 'Weekly') &&
                              <div className="col-md-12">
                                {daysOption && daysOption.map((item, i) => (
                                  <div className={formData.recurrence_rule === 'Weekly' ? 'box week-box' : 'box'} key={i}>
                                    <div className="form-group">
                                      <label className="checkboxInline">
                                        <input type="checkbox" name="recurrence_list" onChange={e => { this.handleCheckboxChange(item.value, e) }} checked={this.checkChecked(item.value)}/> {item.value}
                                        <span class="check-bg"></span>
                                      </label>
                                    </div>
                                  </div>)
                                )}
                                {this.validator.message('Days', formData.recurrence_list, 'required')}
                                <br />
                              </div>
                            }

                            <div className="col-md-6">
                              <label htmlFor="start_time">Start Time<span className="req">*</span></label>
                              <div className="form-group">
                                <select className="form-control" name="start_time" onChange={e => this.handleChange('start_time', e)} value={formData.start_time ? formData.start_time : ''}>
                                  <option value="" label="Select Start Time" />
                                  {availabilityTime.map(function (time, i) {
                                    if(availabilityTime.length-1 > i){
                                      return <option value={time} label={time} />
                                    }
                                  })}
                                </select>
                                {this.validator.message('Start Time', formData.start_time, 'required')}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <label htmlFor="end_time">End Time<span className="req">*</span></label>
                              <div className="form-group">
                                <select className="form-control" name="end_time" onChange={e => this.handleChange('end_time', e)} value={formData.end_time ? formData.end_time : ''}>
                                  <option value="" label="Select End Time" />
                                  {availabilityTime.map(function (time, i) {
                                    if (formData.start_time !== "" && moment(todayDate + ' ' + formData.start_time, 'YYYY-MM-DD hh:mm A').format('x') < moment(todayDate + ' ' + time, 'YYYY-MM-DD hh:mm A').format('x')) {
                                      return <option key={i} value={time} label={time} />
                                    }
                                  })}
                                </select>
                                {this.validator.message('End Time', formData.end_time, 'required')}
                              </div>
                            </div>
                          </div>

                          <div className="row top-btnss">
                            <div className='col-md-6 offset-md-6'>
                              {this.state.error !== '' && <div className="alert alert-danger">{this.state.error}</div>}
                              {this.state.success !== '' && <div className="alert alert-success">{this.state.success}</div>}
                            </div>
                            <div className="col-md-3 offset-md-6">
                              <Link to={{ pathname: "/my-schedule", state: this.props.location.state }} type="cancel" className="cancel-link btn btn-info"> View Schedule List </Link>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <div className="text-right">
                                  {this.state.loading ? <button className="btn text-center" disabled>
                                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                                  </button> : <button type="submit" className="btn">{formData.id?"Update":"Save"}</button>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.showValidationMsg} onHide={e=>this.hideModals({})} enforceFocus={false} className="count-modal">
            <Modal.Header closeButton>
              <div className="body-modal">
                <span>
                  <p><strong>This schedule is conflicting with other schedule</strong></p>
                </span>
              </div>
            </Modal.Header>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    appointmentData: state.appointmentReducer,
  }
}
const mapDispatchToProps = {
  getServiceScheduleAction: getServiceScheduleAction,
  updateServiceScheduleAction: updateServiceScheduleAction,
  getAppointmentList: getAppointmentList,
  resetStatus: resetStatus
}
export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage);