import * as actionTypes from '../constant';

export const ServiceIdAction = (value) => {
    return dispatch => {
                dispatch(ServiceIdCounter(value));
        }
    }

const ServiceIdCounter = (res) => {
    return {
        type: actionTypes.SERVICE_ID,
        value: res
    }
}