import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {appConstants} from "../../_Constants/app.constants"
import {updateProfileUser,resetStatus} from '../../redux/actions/AuthActions'
import {getUserListingAction} from '../../redux/actions/UserActions'
import {updateServiceAction, deleteServiceAction, EnrollNotificationGetAction, serviceListAction} from '../../redux/actions/ServiceActions'
import { Link } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Confirm from '../../components/Confirm';
import SimpleReactValidator from 'simple-react-validator';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import {Modal, FormLabel, Form} from 'react-bootstrap';
import defaultImg from '../../assets/images/default.png';
import {addressValidate} from '../../services/authServices'
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject,ViewsDirective, ViewDirective } from '@syncfusion/ej2-react-schedule';

class EditService extends React.Component {
  constructor(props){
    super(props)
    this.state={
      file:'',
      error:'',
      success:'',
      imagePreviewUrl:'',
      imagePosterUrl:'',
      imageLogoUrl:'',
      selectedInterval:60,
      loading:false,
      updateStatus:false,
      formData:{},
      updateFormData:{},
      userListing:[],
      enrollNotificationList:[],
      modal:false,
      modalData:{}
    }
    this.validator = new SimpleReactValidator();
    this.data = [{
      Id: 5,
      Subject: 'Meeting',
      StartTime: new Date(2021, 7, 5, 10, 0),
      EndTime: new Date(2021, 7, 5, 12, 30),
      IsAllDay: false,
      Status: 'Completed',
      Priority: 'High'
  }];
  }

  componentDidMount() {
  
  }

  componentWillReceiveProps(nextProps) {

  }



  

  render() {
    return <ScheduleComponent  height='550px' selectedDate={new Date(2021, 7, 7)} timeScale={{ enable: true, interval: this.state.selectedInterval, slotCount: 1 }} eventSettings={{ dataSource: this.data,
      fields: {
          id: 'Id',
          subject: { name: 'Subject' },
          isAllDay: { name: 'IsAllDay' },
          startTime: { name: 'StartTime' },
          endTime: { name: 'EndTime' }
      }
  }}>
    <Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
 
 
</ScheduleComponent>;
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    userDetail: state.userReducer,
  }
}

const mapDispatchToProps = {
  getUserListingAction: getUserListingAction,
  EnrollNotificationGetAction: EnrollNotificationGetAction,
  updateUser: updateProfileUser,
  updateServiceAction: updateServiceAction,
  deleteServiceAction: deleteServiceAction,
  resetStatus:resetStatus
}
export default connect(mapStateToProps, mapDispatchToProps)(EditService);


