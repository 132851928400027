import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {appConstants} from "../../_Constants/app.constants"
import {updateProfileUser,resetStatus} from '../../redux/actions/AuthActions'
import {getUserListingAction} from '../../redux/actions/UserActions'
import {updateServiceAction, deleteServiceAction, EnrollNotificationGetAction, serviceListAction} from '../../redux/actions/ServiceActions'
import {updateServiceEnrollByProviderAction, enrollServiceAction} from '../../redux/actions/ServiceEnrollActions'
import { Link } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Confirm from '../../components/ConfirmWithDescription';
import SimpleReactValidator from 'simple-react-validator';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import {Modal, FormLabel, FormControl, Button, Row, Col, Card, Form } from 'react-bootstrap';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import defaultImg from '../../assets/images/default.png';
import {addressValidate} from '../../services/authServices'
import { classNames } from '@syncfusion/ej2-react-buttons';

class EnrollService extends React.Component {
  constructor(props){
    super(props)
    this.state={
      file:'',
      error:'',
      success:'',
      imagePreviewUrl:'',
      imagePosterUrl:'',
      imageLogoUrl:'',
      loading:false,
      updateStatus:false,
      formData:{},
      updateFormData:{},
      userListing:[],
      enrollNotificationList:[],
      modal:false,
      modalData:{},
      deleteDescription: "",
      provider:false
    }
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    document.title = "Kunneckt | Edit Service"

    const { location } = this.props;
    if(location && location.state && location.state.data){
      let propsData = this.props.location.state.data;
      let dataObj = {
        id : propsData.id,
        title : propsData.title?propsData.title:'',
        email : propsData.email?propsData.email:'',
        phone : propsData.phone?propsData.phone:'',
        streetAddress : propsData.streetAddress?propsData.streetAddress:'',
        city : propsData.city?propsData.city:'',
        state : propsData.state?propsData.state:'',
        zip : propsData.zip?propsData.zip:'',
        description : propsData.description?propsData.description:'',
       // atLocation : propsData.atLocation,
       // isMultiItemService : propsData.isMultiItemService,
        price : propsData.price?propsData.price:'',
        duration : propsData.duration?propsData.duration:'',
        poster : propsData.poster?propsData.poster:'',
        //imagePosterUrl : propsData.poster,
        logo : propsData.logo?propsData.logo:'',
        //imageLogoUrl : propsData.logo,
        descriptionModal: false,
        updateDescription: "",
        provider: propsData.provider
      }

      let provider = false;
      if(propsData.provider === localStorage.getItem("userId")){
        provider = true;
      }
      this.setState({
        formData: dataObj,
        imagePosterUrl : propsData.poster?propsData.poster:'',
        imageLogoUrl : propsData.logo?propsData.logo:'',
        provider: provider
      })
    }

    this.props.getUserListingAction()
    this.props.EnrollNotificationGetAction()
    this.props.resetStatus({signupError:'',success:''});
    if (localStorage.getItem('token') === null) {
        this.props.history.push('/login')
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.serviceData && nextProps.serviceData.services && this.state.deleteStatus){
      this.setState({
        deleteStatus:false
      })
      NotificationManager.success(nextProps.serviceData.success);
      this.props.history.push({
        pathname: "/service",
        state: {}
      });
    }
    if(nextProps.serviceData && nextProps.serviceData && this.state.updateStatus && nextProps.serviceData.type === "serviceUpdate"){
      this.setState({
        loading:false,
        updateStatus:false
      })
      NotificationManager.success(nextProps.serviceData.success);
      this.props.history.push({
        pathname: "/service",
        state: {}
      });
    }
    if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "notification"){
      const { location } = this.props;
      let notificationData = nextProps.serviceData.services?nextProps.serviceData.services:[];
      notificationData = notificationData.sort(function(a,b){
        return new Date(b.requested_at) - new Date(a.requested_at)
      })
      if(notificationData && location && location.state && location.state.data && location.state.data.id){
        let myServiceID = location.state.data.id;
        notificationData = notificationData.filter(function(row){
          return row.serviceId === myServiceID;
        })
      }
      this.setState({
        enrollNotificationList:notificationData,
      })
    }
    if(nextProps.userDetail && nextProps.userDetail.users && nextProps.userDetail.type === "userList"){
      let userListing = nextProps.userDetail.users;

      userListing.push(JSON.parse(localStorage.getItem("profile")));

      this.setState({
        userListing : userListing,
      })
    }
  }

  imageUpload=(id)=>{
    document.getElementById(id).click();
  }

  addEnrollService =()=> {
    this.props.enrollServiceAction({serviceId:this.state.formData.id});
    // NotificationManager.success("Successfully sent a request for enrollment of a service.");
    this.props.history.push('/service')
  }

  render() {
    const {modalData, formData, updateFormData, enrollNotificationList, userListing} = this.state ;
    const phoneRegExp =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    // const passwordRegex = /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/
    const passwordRegex = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;

    //   const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    var dataTest = [];
    var notifyArr = enrollNotificationList?enrollNotificationList:[];
    if(notifyArr && userListing){
      for (var i = 0; i < notifyArr.length; i++) {
        if(notifyArr[i].serviceId === this.state.formData.id){
          for (var j = 0; j < userListing.length; j++) {
            if(userListing[j].id===notifyArr[i].userId){
              let firstName = userListing[j].firstName?userListing[j].firstName:'';
              let lastName = userListing[j].lastName?userListing[j].lastName:'';
              notifyArr[i]['fullName'] = firstName+' '+lastName;
              var a = Object.assign(notifyArr[i], userListing[j]);
              dataTest.push(a);

              break;
            }
          }
        }
      }
    }

    return (
      <React.Fragment>
        <Col xs={12} className="profile-form">
          <Card className="service-view-card" style={{"background":"transparent url('"+this.state.imagePosterUrl+"') no-repeat center center /cover"}}>
            <Col>
              <Row>
                <Col className="mt-2" xs={12}>
                  <Form.Group>
                    <Form.Control as="textarea" name="title" placeholder="Service Name*" value={formData.title} readOnly={true}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2 mb-2">
                <Col sm="3" className="">
                  {this.state.imageLogoUrl &&
                      <><img src={this.state.imageLogoUrl} className="service-logo" name="logo" alt="Logo"/><br/></>
                  }
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Control rows={4} as="textarea" placeholder="Service Description*" name="description" value={formData.description} readOnly={true}/>
                  </Form.Group>
                </Col>
              </Row>
              
            </Col>
          </Card>

          <Card className="service-view-card">
            
            <Card.Body>
              <Card.Title>Service Details:</Card.Title>

              <Row className="mb-2">
                <Form.Control placeholder="" name="" value={"Multiple services from " + formData.duration} readOnly={true}/>
              </Row>

              <Row>
                <Form.Control placeholder="" name="" value={"Services starting from " + formData.price} readOnly={true}/>
              </Row>

            </Card.Body>
          </Card>

          <Card className="service-view-card">
            
            <Card.Body>
              <Card.Title>Enrollment Info:</Card.Title>

              <Row className="mb-2">
                <Form.Control placeholder="" name="" value="We are accepting new members…" readOnly={true}/>
              </Row>

              <Row>
                <Col sm={4}> </Col>
                <Col sm={8}>
                  <Button className="inviteServiceBtn" onClick={this.addEnrollService}>Please enroll today!</Button>
                </Col>
                
              </Row>

            </Card.Body>
          </Card>

        </Col>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    userDetail: state.userReducer,
  }
}

const mapDispatchToProps = {
  getUserListingAction: getUserListingAction,
  EnrollNotificationGetAction: EnrollNotificationGetAction,
  updateUser: updateProfileUser,
  updateServiceAction: updateServiceAction,
  deleteServiceAction: deleteServiceAction,
  resetStatus:resetStatus,
  enrollServiceAction: enrollServiceAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollService);
