import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import '../assets/css/header.css'
import classNames from "classnames";
import Logo from '../assets/images/Kunneckt-Logo.png';
import TitleLogo from '../assets/images/Kunneckt-Title.png';
import AppointmentAddLogo from '../assets/images/appointment-add.png';
import appointment_delete_schedule_change from '../assets/images/appointment_delete_schedule_change.png';
import appointment_delete_user from '../assets/images/appointment_delete_user.png';
import service_deleted from '../assets/images/service_deleted.png';
import service_update from '../assets/images/service_update.jpg';
import appointment_update from '../assets/images/appointment_update.png';
import appointment_delete_by_provider from '../assets/images/appointment_delete_by_provider.png';
import service_schedule_changed from '../assets/images/service_schedule_changed.png';
import alert_img from '../assets/images/alert.png';
import info_img from '../assets/images/info.png';
import warning_img from '../assets/images/warning.png';
import { logout } from '../services/authServices';
import { Nav, Navbar, NavDropdown, Container, Modal, Card, Badge, Button, FormControl, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { appConstants } from "../_Constants/app.constants"
import "./style.css";
import { getAllNotificationAction, readAllNotification, readNotificationAction, acceptInvitationAction, EnrollNotificationGetAction, serviceEnrollListAction } from '../redux/actions/ServiceActions'
import { addAppoinmentConfirmAction, deleteConfirmAppoinmentAction, getAppointmentList, suggetAlternativeAction } from '../redux/actions/AppointmentActions';
import { updateServiceEnrollByProviderAction } from '../redux/actions/ServiceEnrollActions'
import BellIcon from 'react-bell-icon';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Socketpage from './socket';
import moment from 'moment';
import Confirm from '../components/Confirm';
class MainNavbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loginUser: JSON.parse(localStorage.getItem("userData")),
      userType: 'Admin',
      logOutRequest: false,
      popupStatus: false,
      noficationModal: false,
      notificationData: [],
      loading: true,
      // badgeCount:JSON.parse(localStorage.getItem("badgeCount")),
      badgeCount: JSON.parse(localStorage.getItem("badgeCount")) ? JSON.parse(localStorage.getItem("badgeCount")) : 0,
      deleteConfirmaProcessData: {},
      deleteConfirmaProcessRequestModel: false,
      suggest_alternative_modal: false,
      suggest_alternative_text: "",
      suggest_alternative_item: null,
      showNotificationDetails: {},
      enroll_deny_modal: false,
      enrollDenyDescription: "",
      enrollDenyItem: null,
      serviceObj: {},
      enrollNotificationList: [],
      formData: {
        message: "",
        providerName: ""
      },
    }
    if (localStorage.getItem('userId')) {
      let userId = localStorage.getItem('userId')
      var send_data = { "user_id": userId };
      setTimeout(function () {
        appConstants.socket.emit('login', send_data);
      }, 1000);

    }
    var elem = this
    appConstants.socket.on('response', function (data) {
      if (data && data.type) {
        NotificationManager.info(data.message);
        elem.updateState(data);
        if (
          data.message === "Un-Enroll By Service Provider" || 
          data.message === "Enroll Request Accepted"
        ) {
          if (data.message === "Un-Enroll By Service Provider") {
            window.location.href = "https://kunneckt.com/service";
          } else {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }

        if(
          window.location.pathname == "/my-appointment" ||
          data.message === "Appointment Accepted by Provider" ||
          data.message === "Appointment Declined by Provider" ||
          data.message === "Appointment Accepted by Consumer" ||
          data.message === "Appointment Declined by Consumer"
        ) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    });
  }

  updateState(data) {
    var dataNotification = this.state.notificationData;
    if (dataNotification.length > 0) {
      dataNotification.unshift(data);
    } else {
      dataNotification = [];
      dataNotification.push(data);
    }
    var badge = this.state.badgeCount;
    badge = badge + 1
    this.setState({
      badgeCount: badge,
      notificationData: dataNotification,
    })

    localStorage.setItem('badgeCount', badge);
    if (data.message === "Appointment Accepted by Provider" || data.message === "Appointment Declined by Provider") {
      this.props.getAppointmentList(data.service_id);
    }
  }

  componentDidMount() {
    // document.title = "Kunneckt | Invite Notification"
    if (localStorage.getItem('token') === null) {
      if (this.props.location.pathname !== "/reset-password" && this.props.location.pathname !== "/" && this.props.location.pathname !== "/login" && this.props.location.pathname !== "/register") {
        this.props.history.push('/login')
      }

    } else {
      this.props.getAllNotificationAction()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceReducer && nextProps.serviceReducer.notificationData) {
      let notiData = nextProps.serviceReducer.notificationData;

      this.setState({
        notificationData: notiData ? notiData : [],
        badgeCount: JSON.parse(localStorage.getItem("badgeCount")),
        loading: false
      })
    }

    if (nextProps.serviceReducer && nextProps.serviceReducer.services && nextProps.serviceReducer.type === "list") {
      let serviceList = nextProps.serviceReducer.services.results;
      let serviceObj = {};
      for (const i in serviceList) {
        serviceObj[serviceList[i].id] = serviceList[i];
      }

      this.setState({
        serviceObj: serviceObj
      })
    }
  }

  logoutHandle = () => {
    logout().then(res => {
      localStorage.clear();
      window.location.href = '/'
    }).catch(err => {
      console.log(err)
    })
  }

  handelNotificationModel = (status) => {
    this.setState({
      noficationModal: !this.state.noficationModal,
    })

    if (status == 1) {
    } else {
      this.props.getAllNotificationAction();
    }
  }

  popupProfile = (status) => {
    if (status == 1) {
      this.setState({ popupStatus: (false) })
    } else {
      this.setState({ popupStatus: (!this.state.popupStatus) })
    }
  }



  addAppoinmentConfirmFun = (status, itemData, index, userType='provider') => {
    let apiData = {
      status: status,
      appointment_id: itemData['appointment_id'],
      notification_id: itemData['id'],
      notification_type_id: itemData['notificationTypeId'],
      user_id: itemData['sender_id'],
      sent_by: userType
    };
    this.props.addAppoinmentConfirmAction(apiData);
    setTimeout(() => {
      this.props.getAppointmentList(itemData.service_id);
    }, 1000);
    // window.location.reload();
    this.removeNotification(index);
  }

  deleteConfirmaProcessRequest = (deleteConfirmaProcessData, index) => {
    deleteConfirmaProcessData["index"] = index;
    this.setState({
      deleteConfirmaProcessData: deleteConfirmaProcessData,
      deleteConfirmaProcessRequestModel: true
    });
  }

  hideDeleteConfirmaProcessRequestModel = () => {
    this.setState({ deleteConfirmaProcessRequestModel: false });
  }

  deleteConfirm = (status) => {
    let deleteConfirmaProcessData = this.state.deleteConfirmaProcessData;
    deleteConfirmaProcessData["confirmStatus"] = status;
    this.props.deleteConfirmAppoinmentAction(deleteConfirmaProcessData);

    let notificationData = this.state.notificationData;
    notificationData.splice(this.state.deleteConfirmaProcessData.index, 1);
    this.setState({ notificationData });

    this.setState({ deleteConfirmaProcessRequestModel: false });
  }

  suggestAlternative = (item, index, userType='provider') => {
    item.index = index;

    this.setState({
      suggest_alternative_modal: true,
      suggest_alternative_text: "",
      suggest_alternative_item: item,
      suggest_alternative_user_type: userType
    });
  }

  handleChange = (name, e) => {
    let updateData = {};
    updateData[name] = e.target.value;
    this.setState(updateData);
  }

  suggestAlternativeSend = () => {
    this.setState({ suggest_alternative_modal: false });

    let notificationData = this.state.notificationData;
    notificationData.splice(this.state.suggest_alternative_item.index, 1);
    this.setState({ notificationData });

    let data = this.state.suggest_alternative_item;
    data.description = this.state.suggest_alternative_text;
    data.userType = this.state.suggest_alternative_user_type;

    this.props.suggetAlternativeAction(data);

    this.dismissNotification(this.state.suggest_alternative_item, this.state.suggest_alternative_item.index);
  }

  showDetailsNotificaiton = (id) => {
    let showNotificationDetails = this.state.showNotificationDetails;
    if (showNotificationDetails[id]) {
      showNotificationDetails[id] = false;
    }
    else {
      showNotificationDetails[id] = true;
    }
    this.setState({ showNotificationDetails: showNotificationDetails });
  }

  dismissNotification = (item, index) => {
    this.removeNotification(index);
    readNotificationAction(item.id, item.notificationTypeId);
  }

  approveEnrollDeny = async (item, is_approve, index) => {

    const data = {
      serviceId: item.service_id,
      consumerId: item.sender_id,
      isApprove: is_approve,
      description: this.state.enrollDenyDescription
    };

    await updateServiceEnrollByProviderAction(data);
    this.props.EnrollNotificationGetAction();
    this.removeNotification(index);
  }

  enrollDeny = async (item, index) => {
    item["index"] = index;
    this.setState({
      enroll_deny_modal: true,
      enrollDenyItem: item
    });
  }

  submitEnrollDeny = async () => {
    this.approveEnrollDeny(this.state.enrollDenyItem, "enrollment_rejection", this.state.enrollDenyItem.index);
    this.setState({
      enroll_deny_modal: false
    });
  }
  acceptRejectInvitation = async (item, is_approve, index) => {
    const data = {
      isAccepted: is_approve
    };
    this.props.acceptInvitationAction(item.service_id, data);

    this.props.serviceEnrollListAction();
    this.removeNotification(index);
  }
  // hideAcceptInvitationModel = () => {
  //   this.setState({acceptInvitationModel: false});
  // }


  serviceSchedulePage(item) {
    if (item.service_id) {
      let data = this.state.serviceObj[item.service_id];
      this.props.history.push({
        pathname: `view-service/${item.service_id}`,
        state: { data: data }
      });
      this.handelNotificationModel(1)
    }
  }
  formatToUpperCase(item) {
    return item.charAt(0).toUpperCase() + item.slice(1);
  }

  removeNotification = (index) => {
    let notificationData = this.state.notificationData;
    notificationData.splice(index, 1);
    this.setState({ notificationData });

    let badgeCount = localStorage.getItem('badgeCount');
    if (badgeCount && badgeCount > 0) {
      badgeCount = badgeCount - 1;
      localStorage.setItem('badgeCount', badgeCount + "");
      this.setState({ badgeCount: badgeCount });
    }
  }

  render() {
    const { formData } = this.state;
    var text = formData.description;
    var text1 = "";
    var text2 = "";
    var summaryLength = (text && text.length ? text.length : 0);
    if (summaryLength > 280) {
      text1 = text.substr(0, 280);
      if ((summaryLength - 280) > 20) {
        text2 = text.substr(280, summaryLength);
      } else {
        text1 = text;
      }
    } else {
      text1 = text;
    }

    let pathname = window.location.pathname
    let token = localStorage.getItem('token');
    let firstName = localStorage.getItem('firstName') ? localStorage.getItem('firstName') : '';
    let lastName = localStorage.getItem('lastName') ? localStorage.getItem('lastName') : '';
    let username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let fullName = firstName + ' ' + lastName;
    let matches = '';
    if (fullName && fullName != '') {
      matches = fullName.match(/\b(\w)/g);
      matches = matches ? matches.join('') : '';
      matches = matches.toUpperCase();
    }

    const { userType } = this.state;
    const classes = classNames(
      "main-navbar",
      "bg-white",
      true && "sticky-top",
      "mb-4"
    );
    const { location } = this.props;
    const mainUrl = token !== null ? "/" : "/";
    return (


      <div className="webheader">
        <div className="">
          <Container fluid>
            <Navbar bg="light" expand="lg">
              <Navbar.Brand width="60">
                <Link to={mainUrl} className="widthLogo">
                  <img src={Logo} width="35" alt="Logo" />
                  <img
                    id="main-logo"
                    className=""
                    src={TitleLogo}
                    height="30"
                    alt="Title"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto" activeKey={location.pathname} >
                  <React.Fragment>
                    <Link to="/contact" className="nav-link">Contact Us</Link>
                  </React.Fragment>
                  {token !== null &&
                    <>
                      <Link to="/service" className={"nav-link " + ((location.pathname == "/service" || location.pathname == "/add-service" || location.pathname == "/edit-service") ? " active" : "")} > Home </Link>
                      <Link to="#" onClick={(e) => this.handelNotificationModel({})} className={"nav-link " + (location.pathname == "/my-service" ? "active" : "")}>
                        {this.state.loading ?
                          <Spinner
                            size="sm"
                          /> :
                          <BellIcon width='20' animate={false} />
                        }
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {!this.state.loading ? this.state.notificationData.length : ''}</span>
                      </Link>
                      <div onClick={e => { this.popupProfile() }} onMouseLeave={e => { this.popupProfile(1) }} className="nav-link">
                        <div className="dropdown">
                          <button className="dropbtn">{matches ? matches : ''}</button>
                          {this.state.popupStatus &&
                            <div className="dropdown-content">
                              <Link to={{ pathname: "/profile", state: 'profile' }}><i className="fa fa-user" aria-hidden="true"></i>{fullName ? fullName : ''}</Link>
                              <Link to="" onClick={e => { this.logoutHandle() }} ><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</Link>
                            </div>
                          }
                        </div>
                      </div>
                    </>
                  }
                  {token === null && <Link to="login" className={"nav-link " + (location.pathname == "/login" ? "active" : "")}>Login</Link>}
                  {token === null && <Link to="register" className={"nav-link " + (location.pathname == "/register" ? "active" : "")}>Sign Up</Link>}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
          {/* <Socketpage /> */}

          <Modal className="modal-close" show={this.state.noficationModal && this.state.notificationData.length > 0} onHide={(e) => this.handelNotificationModel(1)} enforceFocus={false}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="notificationModal">
              <div className="">
                {this.state.notificationData.length === 0 &&
                  <div>
                    <Card>
                      <Card.Body>
                        <div className="noticebox">
                          <div >
                            <div className="notification-buttons" style={{ "text-align": "center" }}>
                              Notification data not found.
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                }

                {this.state.notificationData && this.state.notificationData.length > 0 && this.state.notificationData.map((item, index) => {

                  if (item.type == "enroll") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft enroll-left"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                <div className="noitce-content">
                                  <div><span className="notice-item">New Enroll Request</span></div>
                                  <div>Service: <span className="notice-item">{this.formatToUpperCase(item.service_name)} </span></div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                </div>
                                <div className="noticicon-boxright enroll-left">
                                  {/* <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.approveEnrollDeny(item, "True", index) }}>Accept</a>
                                  <a href="javascript:void(0);" onClick={e => { this.enrollDeny(item, index) }}>Reject</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>

                      </div>
                    )
                  } else if (item.type == "enroll_request_accepted") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft enroll-left"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                <div className="noitce-content">
                                  <div><span className="notice-item">Enroll Request Accepted</span></div>
                                  <div>Service: <span className="notice-item">{this.formatToUpperCase(item.service_name)} </span></div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                </div>
                                <div className="noticicon-boxright enroll-left">
                                  {/* <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>

                      </div>
                    )
                  } else if (item.type == "enroll_request_declined") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft enroll-left"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                <div className="noitce-content">
                                  <div><span className="notice-item">Enroll Request Declined</span></div>
                                  <div>Service: <span className="notice-item">{this.formatToUpperCase(item.service_name)} </span></div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                </div>
                                <div className="noticicon-boxright enroll-left">
                                  {/* <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>

                      </div>
                    )
                  } if (item.type == "unenroll_by_service_provider") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft enroll-left"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                <div className="noitce-content">
                                  <div><span className="notice-item">Un-Enrolled By Service Provider</span></div>
                                  <div>Service: <span className="notice-item">{this.formatToUpperCase(item.service_name)} </span></div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                </div>
                                <div className="noticicon-boxright enroll-left">
                                  {/* <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>

                      </div>
                    )
                  } else if (item.type == "invite") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft invite-left"><i className="fa fa-thumbs-up" aria-hidden="true"></i></div>
                                <div className="noitce-content">
                                  <div><span className="notice-item">New Invitaion Request</span></div>
                                  <div>Service: <span className="notice-item">{this.formatToUpperCase(item.service_name)} </span></div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                </div>
                                <div className="noticicon-boxright invite-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>
                              <br /> <br /> <br />
                              {item.description &&
                                <div> Message:
                                  <div className="notification-description">{item.description}</div>
                                </div>
                              }
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.acceptRejectInvitation(item, 1, index) }}>Accept</a>
                                  <a href="javascript:void(0);" onClick={e => { this.acceptRejectInvitation(item, 0, index) }}>Reject</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "invitation_accepted") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft invite-left"><i className="fa fa-thumbs-up" aria-hidden="true"></i></div>
                                <div className="noitce-content">
                                  <div><span className="notice-item">Invitation Accepted</span></div>
                                  <div>Service: <span className="notice-item">{this.formatToUpperCase(item.service_name)} </span></div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                </div>
                                <div className="noticicon-boxright invite-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>

                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "invitation_declined") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft enroll-left"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                <div className="noitce-content">
                                  <div><span className="notice-item">Invitation Declined</span></div>
                                  <div>Service: <span className="notice-item">{this.formatToUpperCase(item.service_name)} </span></div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                </div>
                                <div className="noticicon-boxright enroll-left">
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>

                      </div>
                    )
                  } else if (item.type == "appointment_update") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appoinment-left"><img className="notification-logo" src={appointment_update} /></div>
                                <div className="noitce-content">
                                  <div> Appointment Reschedule Request</div>
                                  <div> Initiated by:  <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Date/Time: <span className="notice-item">
                                    {item.start_date === item.end_date ?
                                      <>
                                        {moment(item.start_date).format('MMMM Do, hh:mm A')} - {moment(item.end_time).add(item.service_duration?item.service_duration:0, 'minutes').format('hh:mm A')}
                                      </>
                                      :
                                      item.start_date ?
                                        <>
                                          {moment(item.start_date).format('MMMM Do, hh:mm A')} - {item.end_date ? moment(item.end_time).format('MMMM Do, hh:mm A') : ''}
                                        </>
                                        : ''
                                    }
                                  </span></div>
                                  {/*<div> To: <span className="notice-item"> {item.update_schedule ? moment(item.update_schedule.start_date).format('MMMM Do, hh:mm A') : ''} - {item.update_schedule ? moment(item.update_schedule.end_time).format('hh:mm A') : ''}</span></div>*/}
                                </div>

                                <div className="noticicon-boxright appointment-cancel-right">
                                  {/* <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> */}
                                  <img src={alert_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('accept', item, index, 'provider') }}>Accept</a>&nbsp;
                                  {/* <a href="javascript:void(0);" onClick={e => { this.suggestAlternative(item, index) }}>Suggest Alternative</a>&nbsp; */}
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('decline', item, index, 'provider') }}>Decline</a> &nbsp;
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "appointment_update_provider") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appoinment-left"><img className="notification-logo" src={appointment_update} /></div>
                                <div className="noitce-content">
                                  <div> Appointment Reschedule by Service Provider</div>
                                  <div> Initiated by:  <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Date/Time: <span className="notice-item">
                                    {item.start_date === item.end_date ?
                                      <>
                                        {moment(item.start_date).format('MMMM Do, hh:mm A')} - {moment(item.end_time).add(item.service_duration?item.service_duration:0, 'minutes').format('hh:mm A')}
                                      </>
                                      :
                                      item.start_date ?
                                        <>
                                          {moment(item.start_date).format('MMMM Do, hh:mm A')} - {item.end_date ? moment(item.end_time).format('MMMM Do, hh:mm A') : ''}
                                        </>
                                        : ''
                                    }
                                  </span></div>
                                  {/*<div> To: <span className="notice-item"> {item.update_schedule ? moment(item.update_schedule.start_date).format('MMMM Do, hh:mm A') : ''} - {item.update_schedule ? moment(item.update_schedule.end_time).format('hh:mm A') : ''}</span></div>*/}
                                </div>
                                <div className="noticicon-boxright appointment-cancel-right">
                                  {/* <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <br /> <br /> <br />
                              {item.description &&
                                <div> Reason For Reshedule:
                                  <div className="notification-description">{item.description}</div>
                                </div>
                              }

                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {/* {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                } */}

                                <div className="notification-buttons">
                                  {/* <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a> */}
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('accept', item, index, 'consumer') }}>Accept</a>&nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('decline', item, index, 'consumer') }}>Decline</a> &nbsp;
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "appointment_add") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appoinment-addleft"> <img className="notification-logo" src={AppointmentAddLogo} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> New Appointment Request </div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                  <div> Request: <span className="notice-item">
                                    {item.start_date === item.end_date ?
                                      <>
                                        {moment(item.start_date).format('MMMM Do, hh:mm A')} - {moment(item.end_time).add(item.service_duration?item.service_duration:0, 'minutes').format('hh:mm A')}
                                      </>
                                      :
                                      item.start_date ?
                                        <>
                                          {moment(item.start_date).format('MMMM Do, hh:mm A')} - {item.end_date ? moment(item.end_time).format('MMMM Do, hh:mm A') : ''}
                                        </>
                                        : ''
                                    }
                                  </span></div>
                                </div>

                                <div className="noticicon-boxright appoinment-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>

                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('accept', item, index, 'provider') }}>Accept</a>&nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.suggestAlternative(item, index, 'provider') }}>Suggest Alternative</a>&nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('decline', item, index, 'provider') }}>Decline</a>&nbsp;
                                </div>
                              </div>

                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "appointment_add_by_provider") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appoinment-addleft"> <img className="notification-logo" src={AppointmentAddLogo} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> New Appointment Booked by Service Provider </div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                  <div> Request: <span className="notice-item">
                                    {item.start_date === item.end_date ?
                                      <>
                                        {moment(item.start_date).format('MMMM Do, hh:mm A')} - {moment(item.end_time).add(item.service_duration?item.service_duration:0, 'minutes').format('hh:mm A')}
                                      </>
                                      :
                                      item.start_date ?
                                        <>
                                          {moment(item.start_date).format('MMMM Do, hh:mm A')} - {item.end_date ? moment(item.end_time).format('MMMM Do, hh:mm A') : ''}
                                        </>
                                        : ''
                                    }
                                  </span></div>
                                </div>

                                <div className="noticicon-boxright appoinment-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>

                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }
                                {/* <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div> */}

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('accept', item, index, 'consumer') }}>Accept</a>&nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.suggestAlternative(item, index, 'consumer') }}>Suggest Alternative</a>&nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.addAppoinmentConfirmFun('decline', item, index, 'consumer') }}>Decline</a>&nbsp;
                                </div>
                              </div>

                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "appointment_delete") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left">  <img className="notification-logo" src={appointment_delete_user} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Appointment Cancelled by Consumer</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Cancel:  <span className="notice-item">
                                    {item.start_date === item.end_date ?
                                      <>
                                        {moment(item.start_date).format('MMMM Do, hh:mm A')} - {moment(item.end_time).add(item.service_duration?item.service_duration:0, 'minutes').format('hh:mm A')}
                                      </>
                                      :
                                      item.start_date ?
                                        <>
                                          {moment(item.start_date).format('MMMM Do, hh:mm A')} - {item.end_date ? moment(item.end_time).format('MMMM Do, hh:mm A') : ''}
                                        </>
                                        : ''
                                    }
                                  </span></div>
                                  {item.description && <div> Reason For Cancellation:  <span className="notice-item"> </span></div>}
                                  {item.description &&

                                    <div className="notification-description">{item.description}</div>
                                  }



                                </div>


                                <div className="noticicon-boxright appointment-cancel-right">
                                  {/* <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                <div className="notification-buttons">
                                  {/* <a href="javascript:void(0);">Service Schedule Page</a>&nbsp; */}
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                  {/* <a href="javascript:void(0);" onClick={e => { this.suggestAlternative(item, index) }}>Suggest Alternative</a>&nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.deleteConfirmaProcessRequest(item, index) }}>Process Request</a>&nbsp; */}
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "appointment_delete_by_provider") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left">  <img className="notification-logo" src={appointment_delete_by_provider} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Appointment Cancelled by Service Provider</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Cancelled: <span className="notice-item">
                                    {item.start_date === item.end_date ?
                                      <>
                                        {moment(item.start_date).format('MMMM Do, hh:mm A')} - {moment(item.end_time).add(item.service_duration?item.service_duration:0, 'minutes').format('hh:mm A')}
                                      </>
                                      :
                                      item.start_date ?
                                        <>
                                          {moment(item.start_date).format('MMMM Do, hh:mm A')} - {item.end_date ? moment(item.end_time).format('MMMM Do, hh:mm A') : ''}
                                        </>
                                        : ''
                                    }
                                  </span></div>
                                  {item.description && <div> Reason For Cancellation:  <span className="notice-item"> </span></div>}
                                  {item.description &&

                                    <div className="notification-description">{item.description}</div>
                                  }


                                </div>


                                <div className="noticicon-boxright appointment-cancel-right">
                                  {/* <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {/* {item.description && 
                                  <div className="notification-description">{item.description}</div>
                                } */}

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  }
                  else if (item.type == "appointment_accepted_by_provider") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appoinment-addleft"> <img className="notification-logo" src={AppointmentAddLogo} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Appointment Accepted by Provider</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                  <div> Request: <span className="notice-item"> {item.start_date ? moment(item.start_date).format('MMMM Do, hh:mm A') : ''} - {item.start_date ? moment(item.end_time).format('hh:mm A') : ''}</span></div>
                                </div>

                                <div className="noticicon-boxright appoinment-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>

                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>

                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  }
                  else if (item.type == "appointment_accepted_by_consumer") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appoinment-addleft"> <img className="notification-logo" src={AppointmentAddLogo} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Appointment Accepted by Consumer</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by}</span></div>
                                  <div> Request: <span className="notice-item"> {item.start_date ? moment(item.start_date).format('MMMM Do, hh:mm A') : ''} - {item.start_date ? moment(item.end_time).format('hh:mm A') : ''}</span></div>
                                </div>

                                <div className="noticicon-boxright appoinment-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>

                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }
                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>

                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  }
                  else if (item.type == "appointment_declined_by_provider") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left">  <img className="notification-logo" src={appointment_delete_by_provider} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Appointment Declined by Provider</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Cancelled:  <span className="notice-item"> {item.start_date ? moment(item.start_date).format('MMMM Do, hh:mm A') : ''} - {item.start_date ? moment(item.end_time).format('hh:mm A') : ''}</span></div>


                                </div>


                                <div className="noticicon-boxright appointment-cancel-right">
                                  {/* <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  }
                  else if (item.type == "appointment_declined_by_consumer") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left">  <img className="notification-logo" src={appointment_delete_by_provider} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Appointment Declined by Consumer</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Cancelled:  <span className="notice-item"> {item.start_date ? moment(item.start_date).format('MMMM Do, hh:mm A') : ''} - {item.start_date ? moment(item.end_time).format('hh:mm A') : ''}</span></div>


                                </div>


                                <div className="noticicon-boxright appointment-cancel-right">
                                  {/* <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  }
                  else if (item.type == "appointment_delete_schedule_change") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left"> <img className="notification-logo" src={appointment_delete_schedule_change} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Appointment Cancelled by Provider</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Cancel:  <span className="notice-item"> {item.start_date ? moment(item.start_date).format('MMMM Do, hh:mm A') : ''} - {item.start_date ? moment(item.end_time).format('hh:mm A') : ''}</span></div>
                                </div>

                                <div className="noticicon-boxright appointment-cancel-right">
                                  {/* <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>

                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "service_deleted") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left"> <img className="notification-logo" src={service_deleted} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Service Deletion</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Takes effect:  <span className="notice-item"> {item.timestamp ? moment(item.start_date).format('MMMM Do, YYYY') : ''}</span></div>
                                </div>

                                <div className="noticicon-boxright invite-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={warning_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "service_update") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left"> <img className="notification-logo" src={service_update} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Change in Service</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Takes effect:  <span className="notice-item"> {item.timestamp ? moment(item.start_date).format('MMMM Do, YYYY') : ''}</span></div>
                                </div>

                                <div className="noticicon-boxright invite-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "service_schedule_changed") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left"> <img className="notification-logo" src={service_schedule_changed} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div> Change in Service Schedule</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Takes effect:  <span className="notice-item"> {item.timestamp ? moment(item.start_date).format('MMMM Do, YYYY') : ''}</span></div>
                                </div>

                                <div className="noticicon-boxright invite-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  } else if (item.type == "sugget_alternative") {
                    return (
                      <div key={index}>
                        <Card>
                          <Card.Body>
                            <div className="noticebox">
                              <div onClick={() => this.showDetailsNotificaiton(item.id)} >
                                <div className="noticicon-boxleft appointment-cancel-left"> <img className="notification-logo" src={service_deleted} /></div>
                                <div className="noitce-content">
                                  {item.service_logo &&
                                    <div className="service-icon"><img className="notification-logo" src={item.service_logo} /></div>
                                  }
                                  <div>Suggest Alternative</div>
                                  <div> Initiated by: <span className="notice-item"> {item.initiated_by} </span></div>
                                  <div> Takes effect:  <span className="notice-item"> {item.timestamp ? moment(item.start_date).format('MMMM Do, YYYY') : ''}</span></div>
                                </div>

                                <div className="noticicon-boxright invite-right">
                                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                  <img src={info_img} />
                                </div>
                              </div>
                              <div style={{ "display": this.state.showNotificationDetails[item.id] ? "block" : "none" }}>
                                {item.description &&
                                  <div className="notification-description">{item.description}</div>
                                }

                                <div className="notification-buttons">
                                  <a href="javascript:void(0);" onClick={e => { this.serviceSchedulePage(item) }}>Service Details Page</a> &nbsp;
                                  <a href="javascript:void(0);" onClick={e => { this.dismissNotification(item, index) }}>Dismiss</a>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  }

                })}
              </div>
            </Modal.Body>
          </Modal>

          {/** Delete confirmation box */}
          <Modal show={this.state.deleteConfirmaProcessRequestModel} onHide={this.hideDeleteConfirmaProcessRequestModel} enforceFocus={false}>
            <Modal.Header closeButton>
              Delete Confirmation
            </Modal.Header>
            <Modal.Body className="notificationModal">
              Are you sure?
            </Modal.Body>
            <Modal.Footer>
              <a href="javascript:void(0);" onClick={e => { this.deleteConfirm('yes') }}>Accept</a>&nbsp;
              <a href="javascript:void(0);" onClick={e => { this.deleteConfirm('no') }}>Decline</a>
            </Modal.Footer>
          </Modal>
          {/** /Delete confirmation box */}

          {/** Suggest Alternative box */}
          <Modal show={this.state.suggest_alternative_modal} onHide={e => { this.setState({ suggest_alternative_modal: false }) }} enforceFocus={false}>
            <Modal.Header closeButton>
              Suggest Alternative
            </Modal.Header>
            <Modal.Body className="notificationModal">
              <FormControl as="textarea" aria-label="Suggest Alternative" onChange={e => this.handleChange("suggest_alternative_text", e)} />
            </Modal.Body>
            <Modal.Footer>
              <Button style={{ width: "20%" }} variant="primary" onClick={this.suggestAlternativeSend}>Send</Button>
            </Modal.Footer>
          </Modal>
          {/** /Suggest Alternative box */}

          {/** Reject request box */}
          <Modal show={this.state.enroll_deny_modal} onHide={e => { this.setState({ enroll_deny_modal: false }) }} enforceFocus={false}>
            <Modal.Header closeButton>
              Reject Request
            </Modal.Header>
            <Modal.Body className="notificationModal">
              <FormControl as="textarea" aria-label="Enroll Deny" placeholder='Rejection Message' onChange={e => this.handleChange("enrollDenyDescription", e)} />
            </Modal.Body>
            <Modal.Footer>
              <Button style={{ width: "15%" }} variant="primary" onClick={this.submitEnrollDeny}>Ok</Button>
            </Modal.Footer>
          </Modal>
          {/** /Reject request box */}

        </div>
      </div>

    );
  }

}
const mapStateToProps = state => {
  return {
    error: state.serviceReducer.signupError,
    success: state.serviceReducer.success,
    serviceReducer: state.serviceReducer,
    serviceData: state.serviceReducer,

    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    userDetail: state.userReducer,
    appointmentData: state.appointmentReducer,

  }
}

const mapDispatchToProps = {
  getAllNotificationAction: getAllNotificationAction,
  readAllNotification: readAllNotification,
  addAppoinmentConfirmAction: addAppoinmentConfirmAction,
  deleteConfirmAppoinmentAction: deleteConfirmAppoinmentAction,
  suggetAlternativeAction: suggetAlternativeAction,
  readNotificationAction: readNotificationAction,
  acceptInvitationAction: acceptInvitationAction,
  getAppointmentList: getAppointmentList,
  EnrollNotificationGetAction: EnrollNotificationGetAction,
  serviceEnrollListAction: serviceEnrollListAction
}
// export default MainNavbar
//export default withRouter(MainNavbar);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar));