import Api from '../Api'

export const login = user => { 
	return Api.post('auth/login',user)
};

export const saveUser = user => { 
	return Api.post('user/',user)
};

export const verifyEmail =(key) => { 
	return Api.put('auth/verify-email?key='+key)
};

export const userForgotPassword = user => { 
	
	return Api.post('auth/forgot-password',user)
};

export const userResetPassword = (user,key) => { 
	return Api.post('auth/reset-password?key='+key,user)
};

export const checkUserResetPassword = (key) => { 
	return Api.post('auth/check-reset-password?key='+key, {})
};

export const sendNewPassword = user => { 
	return Api.forgotPasswordSubmit(user.email,user.code,user.newPassword)
} 

export const updateProfile = user => {
	return Api.put('user/', user)
}

export const logout=() => {
	return Api.post('auth/logout')
}

export const addressValidate=(address) => {
	return Api.get('validate-address/'+address)
}

export const getTimezoneApi=() => {
	return Api.get('timezone/')
}



