import React from 'react'
import { NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { UncontrolledCollapse } from 'reactstrap';


class Sidebar extends React.Component {
	subMenu=(subMenuRoutes)=>{
		return(
			<UncontrolledCollapse toggler="#toggler" id="reportDropdown">
				<ul className="mt-0">
					{this.menuItem(subMenuRoutes)}
				</ul>
			</UncontrolledCollapse>
		)
	}
	adminMenu=()=>{
		return this.menuItem(this.props.roles.Admin)
	}
	menuItem=(routes)=>{
		
		return routes.map((route,index) => {
			return (
				<li key={index}>
					<NavLink to={route.url} className={route.className!==undefined ?route.className:"sidebar-menu-item"} activeClassName='active' id={route.subMenu!==undefined?'toggler':''} title={this.props.t(route.title)}>
						<i className={route.iconClassName}></i>
						<span className={this.props.handleClass ? "menu hide" : "menu"}>
							{this.props.t(route.title)}
						</span>
					</NavLink>
					{route.subMenu!==undefined && this.subMenu(route.subMenu)}
				</li>
			)
		})
	}

	render() {

		return (
			<div className={this.props.handleClass ? "main-layout" : "main-layout open"} id="side-menu">
				<aside className="left-sidebar-layout ">
					<div className="sidebar-content">
						<ul>
							{this.menuItem(this.props.roles.Common)}
						</ul>
					</div>
				</aside>
			</div>

		)
	}
}

export default withTranslation()(Sidebar)