import * as actionTypes from '../constant';

export const ParentCountAction = (value) => {
    return dispatch => {
                dispatch(ParentCounter(value));
        }
    }

const ParentCounter = (res) => {
    return {
        type: actionTypes.INCREMENT_PARENT,
        value: res
    }
}

export const ChildCountAction = (value) => {
    return dispatch => {
            if(value) {
                dispatch(ChildCounter(value));
            }
        }
    }

    const ChildCounter = (res) => {
        return {
            type: actionTypes.INCREMENT_CHILD,
            value: res
        }
    }