import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { appConstants } from "../../_Constants/app.constants"
import { updateProfileUser, resetStatus } from '../../redux/actions/AuthActions'
import { serviceListAction, createInviteServiceAction, serviceEnrollListAction, serviceUnEnrollAction } from '../../redux/actions/ServiceActions'
import { getUserListingAction } from '../../redux/actions/UserActions'
import { enrollServiceAction } from '../../redux/actions/ServiceEnrollActions'
import imageService from "./images/service-thumbnails/thumbnail-1.png"
import Slider from "react-slick";
import { Container, Row, Col, Card, Modal, FormLabel, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import defaultImg from '../../assets/images/default.png';
import Autosuggest from 'react-autosuggest';
import SimpleReactValidator from 'simple-react-validator';
import Confirm from '../../components/Confirm';
import "./css/services.css"
import { Tab, Tabs, TabList, TabPanel, resetIdCounter } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ProfileComponent from '../../pages/Profile';
import MyAppointmentComponent from '../../pages/MyAppointment';
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

var user_timezone = '';
var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
class Service extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: '',
      searchInput: "",
      error: '',
      invalidEmailError: '',
      success: '',
      imagePreviewUrl: '',
      loading: false,
      emailErorr: false,
      modal: false,
      enrollModal: false,
      enrollType: false,
      serviceList: [],
      serviceMyList: [],
      otherServiceList: [],
      serviceEnrolledList: [],
      formData: {},
      enrollFormData: {},
      userOption: [{}],
      suggesValue: '',
      suggestions: [],
      defaultTab: 0,
      currentTab: 0
    }
    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
    this.addInviteService = this.addInviteService.bind(this);
  }

  async componentDidMount() {
    document.title = "Kunneckt | Service"
    if (localStorage.getItem('token') === null) {
      this.props.history.push('/login')
    }
    if (this.props.history.location.pathname === "/my-appointment") {
      this.setState({ currentTab: 1 });
    }
    else if (this.props.history.location.pathname === "/profile") {
      this.setState({ currentTab: 2 });
    }


    await this.props.getUserListingAction()
    await this.props.serviceEnrollListAction()
    await this.props.serviceListAction("?t=all")
    await this.props.resetStatus({ signupError: '', success: '' })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps?.serviceData?.error === "Invalid User Name or Email") {
      this.setState({invalidEmailError: nextProps.serviceData.error})
    }
    var myUserData = JSON.parse(localStorage.getItem('persist:auth'));

    myUserData = JSON.parse(myUserData.authReducer ? myUserData.authReducer : '');
    var myUserId = (myUserData && myUserData.userProfileData && myUserData.userProfileData.id ? myUserData.userProfileData.id : '');
    user_timezone = (myUserData && myUserData.userProfileData && myUserData.userProfileData.user_timezone ? myUserData.userProfileData.user_timezone : '');

    if (nextProps.userDetail && nextProps.userDetail.users && nextProps.userDetail.type === "userList") {
      let newUserOption = [];
      nextProps.userDetail.users.forEach(item => {
        newUserOption.push({ value: item.email, name: item.username, firstName: item.firstName, lastName: item.lastName })
      })
      this.setState({
        userOption: newUserOption,
      })
    }

    if (nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "list") {
      let myServiceArray = nextProps.serviceData.services.results;
      if (myServiceArray) {
        myServiceArray = myServiceArray.filter(function (item) {
          return (item.provider === myUserId && item.status === true);
        })
      }
      let otherServiceArray = nextProps.serviceData.services.results;
      if (otherServiceArray) {
        otherServiceArray = otherServiceArray.filter(function (item) {
          return (item.provider !== myUserId && item.status === true && item.enrolled === false);
        })
      }

      this.setState({
        serviceMyList: myServiceArray ? myServiceArray : [],
        otherServiceList: otherServiceArray ? otherServiceArray : [],
      })
    }



    if (nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "myEnrollList") {
      let myEnrollArray = nextProps.serviceData.services;
      if (myEnrollArray) {
        myEnrollArray = myEnrollArray.filter(function (item) {
          return item.serviceStatus === true || item.serviceStatus === "True"
        })
      }


      this.setState({
        serviceEnrolledList: myEnrollArray ? myEnrollArray : [],
      })
    }

    if (nextProps.serviceEnroll && nextProps.serviceEnroll.service && nextProps.serviceEnroll.type === "create") {
      this.setState({
        enrollModal: false,
      })
      this.props.serviceListAction("?t=all");
      NotificationManager.success(nextProps.serviceEnroll.service.message);
    }

    if (nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "createInvite") {
      this.setState({
        modal: false,
        suggesValue: ''
      })
      NotificationManager.success(nextProps.serviceData.success);
    }

    if (nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "unEnroll") {
      this.setState({
        enrollModal: false,
      })
      this.props.serviceEnrollListAction();
      this.props.serviceListAction("?t=all");
      NotificationManager.success(nextProps.serviceData.success);
    }
  }

  editDetails = (data, e) => {
    this.props.history.push({
      pathname: `/view-service/${data.id}`,
      state: { data: data }
    });
  }

  enrollService = (data, e) => {
    this.props.history.push({
      pathname: "/enroll-service",
      state: { data: data }
    });
  }

  hideModals = (data) => {
    let newData = {};
    if (data.id) {
      newData.serviceId = data.id
    }
    this.setState({
      formData: newData,
      modal: !this.state.modal,
      suggesValue: '',
    })
  }

  enrolledModals = (item, type) => {
    this.setState({
      enrollFormData: item,
      enrollModal: !this.state.enrollModal,
      enrollType: type
    })
  }

  handleChange(type, e) {
    let data = this.state.formData;
    if (type === "ext") {
      data[type] = e;
    } else {
      data[type] = e.target.value;
    }
    this.setState({formData: data});
  }

  addInviteService = () => {
    const { formData, suggestions } = this.state;
    if (this.validator.allValid()) {
      this.props.createInviteServiceAction(formData)
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  addEnrollService = () => {
    const { enrollFormData } = this.state;
    this.props.enrollServiceAction({ serviceId: enrollFormData.id })
  }

  unEnrolService = (serviceId) => {
    this.props.serviceUnEnrollAction(serviceId)
  }

  onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let suggestionsArr = inputLength === 0 ? [] : this.state.userOption.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );

    let formData = this.state.formData;
    // if (formData.name !== inputValue) {
    //   formData.firstName = '';
    //   formData.lastName = '';
    // }
    var element = document.getElementById("autoSuggest");
    element.classList.remove("react-autosuggest__input--focused");
    element.classList.add("mystyle");

    let color = "#e1e5eb"
    if (suggestionsArr.length > 0) {
      color = "green"
    } else {
      let userNameEmail = inputValue.match(mailformat);
      if (userNameEmail) {
        // formData.email = inputValue;
        color = "green";
      } else {
        color = "red"
      }
    }
    document.getElementById("autoSuggest").style.borderColor = color;
    document.getElementById("autoSuggest").style.outlineColor = color;
    this.setState({ suggestions: suggestionsArr, formData: formData });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      emailErorr: false,
      invalidEmailError: false,
      suggesValue: newValue
    });

    this.blurAutoSuggest(newValue);
  };

  blurAutoSuggest = (suggesValue) => {
    const { suggestions } = this.state;
    let color = "#e1e5eb"
    if (suggestions.length > 0) {
      let userValue = suggestions.find((item) => {
        return item.name === suggesValue
      });
      if (userValue && userValue.value) {
        color = "#e1e5eb";
        // formData = this.state.formData;
        let formData = {
          serviceId: this.state.formData.serviceId,
          message: this.state.formData.message,
          email: userValue.value,
          firstName: userValue.firstName,
          lastName: userValue.lastName,
          name: userValue.name
        }
        this.setState({
          formData: formData
        })
      } else {
        color = "red"
      }
    } else {
      let userNameEmail = suggesValue.match(mailformat);
      if (!userNameEmail) {
        color = "red"
      } else {
        let formData = this.state.formData;
        formData.email = suggesValue;  
        formData.name = suggesValue;
        this.setState({formData: formData});   
      }
    }
    document.getElementById("autoSuggest").style.borderColor = color;
    document.getElementById("autoSuggest").style.outlineColor = color;

    this.setState({
      emailErorr: color === "red" ? true : false
    })
  }

  isAppointment = (data, e) => {
    this.props.history.push({
      pathname: "/appointment",
      state: { data }
    });
  }

  changeUrl = (url) => {
    this.props.history.push(url);
  }

  render() {
    resetIdCounter();
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          }
        }
      ]
    };

    const { location } = this.props;
    const { defaultTab, serviceMyList, emailErorr, invalidEmailError, serviceEnrolledList, otherServiceList, enrollFormData, suggestions } = this.state;
    // console.log(serviceMyList);
    const inputProps = {
      placeholder: 'Enter user-name or email',
      value: this.state.suggesValue,
      onChange: this.onChange,
      // onBlur: this.blurAutoSuggest ,
      id: "autoSuggest"
    };

    // console.log("otherServiceList", otherServiceList)

    const searchItems = (searchValue) => {
      // setSearchInput(searchValue)
      this.setState({ searchInput: searchValue });

      console.log("searchValue", searchValue)

      if (searchValue !== '') {
        console.log("otherServiceList.data", otherServiceList)
        const filteredData = otherServiceList.filter((item) => {

          return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
        })
        // setFilteredResults(filteredData)
        this.setState({ filteredResults: filteredData });

        console.log("filteredData", filteredData)
      }

    }

    return (
      <Tabs className="tab-sevices-d" selectedIndex={this.state.currentTab} onSelect={index => (this.props.history.replace(), this.setState({ defaultTab: index }))} >
        <TabList>
          <Tab tabIndex="0" onClick={() => this.changeUrl('/service')}>My Services</Tab>
          <Tab tabIndex="1" onClick={() => this.changeUrl('/my-appointment')}>My Appointment</Tab>
          <Tab tabIndex="2" onClick={() => this.changeUrl('/profile')}>My Profile</Tab>
        </TabList>
        <div className="container-fluid">
          <TabPanel>
            <div className="row">
              <div className="col-md-12">
                <Link to={{ pathname: "/add-service", state: serviceMyList }}><i className="fa fa-user-envelope" aria-hidden="true"></i>
                  <button align="right" className="add-service-btn"><i className="fa fa-plus fa-3x" aria-hidden="true"></i></button>
                </Link>
              </div><br />

              <div className="col-md-12">
                <div className="under-ssc">
                  <div className="heading-text">Services provided by you</div>
                  <div className="col-md-12 p-l00">
                    <Slider {...settings}>
                      {/* <p>{serviceMyList.length}</p> */}
                      {serviceMyList.map((item, key) => {
                        return (
                          <div className="col-md-7-grid" data-index={key} key={key}>
                            <div className="service-secc">
                              <div>
                                <img src={item.logo ? item.logo : defaultImg} alt="" onClick={(e) => this.editDetails(item, e)}/>
                              </div>
                              <h3 onClick={(e) => this.editDetails(item, e)}>{item.title}</h3>
                              <p onClick={(e) => this.editDetails(item, e)}>{item.providerName}</p>
                              <button onClick={(e) => this.hideModals(item)} className="invite-service-btn"><i className="fa fa-user-plus fa-3x" aria-hidden="true"></i></button>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="under-ssc">
                  <div className="heading-text">SERVICES YOU'RE ENROLLED IN</div>
                  <div className="col-md-12 p-l00">
                    {serviceEnrolledList && serviceEnrolledList.length > 0 &&
                      <Slider {...settings}>
                        {serviceEnrolledList.map((item, index) => {
                          return (
                            <div className="col-md-7-grid" data-index={index} key={index}>
                              <div className="service-secc" onClick={(e) => this.editDetails(item, e)}>
                                <img src={item.logo ? item.logo : defaultImg} alt={item.title} />
                                <h3>{item.title}</h3>
                                <p>{item.providerName}</p>
                              </div>
                              <button onClick={(e) => this.enrolledModals(item, 1)} className="invite-service-btn"><i className="fa fa-info-circle fa-3x" aria-hidden="true"></i></button>
                            </div>
                          )
                        })}
                      </Slider>
                    }
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="under-ssc">
                  <div className="heading-text">SERVICES YOU MIGHT LIKE</div>
                  <TextField className='searchinputfield' placeholder='Search Here' type="text"

                    onChange={(e) => searchItems(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <SearchOutlined />
                      ),
                    }}
                  />
                  {/* <TextField
                
                id="standard-bare"
                className='searchinputfield'
                placeholder='Search Here'
                onChange={(e) => searchItems(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              /> */}
                  <div className="col-md-12 p-r0">

                    {this.state.searchInput?.length > 1 ? (
                      this.state.filteredResults && this.state.filteredResults.map((item, index) => {
                        return (<Slider {...settings}>


                          <div className="col-md-7-grid" data-index={index} key={index}>
                            <div className="service-secc" onClick={(e) => this.enrollService(item, e)}>
                              <img src={item.logo ? item.logo : defaultImg} alt={item.title} />
                              <h3>{item.title}</h3>
                              <p>{item.providerName}</p>
                            </div>
                            <button onClick={(e) => this.enrolledModals(item, 2)} className="invite-service-btn"><i className="fa fa-heart fa-3x" aria-hidden="true"></i></button>
                          </div>


                        </Slider>)
                        // console.log(item,"iiiiiiiiiiiiiii")
                      })
                    ) : (

                      otherServiceList && otherServiceList.length > 0 &&
                      <Slider {...settings}>
                        {otherServiceList.map((item, index) => {
                          return (
                            <div className="col-md-7-grid" data-index={index} key={index}>
                              <div className="service-secc" onClick={(e) => this.enrollService(item, e)}>
                                <img src={item.logo ? item.logo : defaultImg} alt={item.title} />
                                <h3>{item.title}</h3>
                                <p>{item.providerName}</p>
                              </div>
                              <button onClick={(e) => this.enrolledModals(item, 2)} className="invite-service-btn"><i className="fa fa-heart fa-3x" aria-hidden="true"></i></button>
                            </div>
                          )
                        })}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>

              <Modal show={this.state.modal} onHide={(e) => this.hideModals({})} enforceFocus={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Invite Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <FormLabel>User-Name or Email</FormLabel>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      // onSuggestionsClearRequested={(suggestion)=> this.setState({suggestions:[]})}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={(suggestion) => suggestion.name}
                      renderSuggestion={(suggestion) => suggestion.name}
                      inputProps={inputProps}
                    />
                    {emailErorr &&
                      <div className="srv-validation-message">Please provide valid User-Name Or Email address.</div>
                    }
                    {invalidEmailError &&
                      <div className="srv-validation-message">{invalidEmailError}.</div>
                    }
                    {this.validator.message('User-Name or Email', this.state.suggesValue, 'required')}

                    <FormLabel>First Name </FormLabel>
                    <InputGroup className="mb-3">
                      <input type="text" rows="5" className="form-control" name="firstName" placeholder="First Name" defaultValue={this.state.formData.firstName} onChange={e => { this.handleChange("firstName", e) }} disabled={suggestions.length > 0 ? true : false}></input>
                    </InputGroup>
                    {this.validator.message('firstName', this.state.formData.firstName, 'required')}

                    <FormLabel>Last Name </FormLabel>
                    <InputGroup className="mb-3">
                      <input type="text" rows="5" className="form-control" name="lastName" placeholder="Last Name" defaultValue={this.state.formData.lastName} onChange={e => { this.handleChange("lastName", e) }} disabled={suggestions.length > 0 ? true : false}></input>
                    </InputGroup>
                    {this.validator.message('lastName', this.state.formData.lastName, 'required')}


                    <FormLabel>Your Personal Message </FormLabel>
                    <InputGroup className="mb-3">
                      <textarea type="text" rows="5" className="form-control he-discription" name="description" placeholder="Message" defaultValue={this.state.formData.message} onChange={e => { this.handleChange("message", e) }}></textarea>
                    </InputGroup>
                    {this.validator.message('message', this.state.formData.message, 'required')}

                    <div className="row">
                      <InputGroup className="mb-3">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 p-r0">
                          <Button className="inviteServiceBtn" onClick={() => this.hideModals({})}>Back</Button>&nbsp;&nbsp;
                          <Button className="inviteServiceBtn" onClick={this.addInviteService}>Invite</Button>
                        </div>

                      </InputGroup> </div>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal show={this.state.enrollModal} onHide={(e) => this.enrolledModals({})} enforceFocus={false}>
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.enrollType === 1 ? 'Enrolled Service' : 'Enroll Service'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="serviceEnrollModal">
                  <div className="">
                    <div className="" >
                      <img src={enrollFormData && enrollFormData.poster ? enrollFormData.poster : defaultImg} height="300px" alt={enrollFormData && enrollFormData.title ? enrollFormData.title : ''} /><br />
                      <p className="head-lab">Service Name : {enrollFormData && enrollFormData.title}</p>
                      <p className="head-lab">Provide By -{enrollFormData && enrollFormData.providerName}</p>
                      <p className="head-lab">Description -{enrollFormData && enrollFormData.description}</p>
                      <p className="head-lab">Street Address -{enrollFormData && enrollFormData.streetAddress}</p>
                      <p className="head-lab">City -{enrollFormData && enrollFormData.city}</p>
                      <p className="head-lab">State -{enrollFormData && enrollFormData.state}</p>
                      <p className="head-lab">Zip -{enrollFormData && enrollFormData.zip}</p>
                    </div>
                  </div>
                  {/* {this.state.enrollType!==1 &&
                    <div className="row">
                      <InputGroup className="mb-3">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 p-r0">
                          <Button className="inviteServiceBtn" onClick={(e)=>this.enrolledModals({})}>Back</Button>&nbsp;&nbsp;
                          <Button className="inviteServiceBtn" onClick={this.addEnrollService}>Enroll</Button>
                        </div>
                      </InputGroup>
                    </div>
                  }                   */}
                  {this.state.enrollType === 1 && enrollFormData.serviceStatus === true &&
                    <div className="row">
                      <InputGroup className="mb-3">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 p-r0">
                          <Button className="inviteServiceBtn" onClick={(e) => this.enrolledModals({})}>Back</Button>&nbsp;&nbsp;
                          <Button className="inviteServiceBtn" onClick={e => { Confirm(this.unEnrolService, enrollFormData.id) }}>Unenroll</Button>
                        </div>
                      </InputGroup>
                    </div>
                  }
                </Modal.Body>
              </Modal>
            </div>
          </TabPanel>
          <TabPanel>
            <MyAppointmentComponent history={this.props.history} />
            {/* <div className="container-fluid main-contain">
              My Appointment
            </div> */}
          </TabPanel>
          <TabPanel>
            <ProfileComponent history={this.props.history} />
          </TabPanel>
        </div>
      </Tabs>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.serviceReducer.signupError,
    success: state.serviceReducer.success,
    serviceData: state.serviceReducer,
    serviceEnroll: state.serviceEnrollReducer,
    userDetail: state.userReducer,
  }
}

const mapDispatchToProps = {
  updateUser: updateProfileUser,
  serviceListAction: serviceListAction,
  serviceUnEnrollAction: serviceUnEnrollAction,
  enrollServiceAction: enrollServiceAction,
  serviceEnrollListAction: serviceEnrollListAction,
  createInviteServiceAction: createInviteServiceAction,
  resetStatus: resetStatus,
  getUserListingAction: getUserListingAction
}
export default connect(mapStateToProps, mapDispatchToProps)(Service);
