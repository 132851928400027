import React from 'react';
import { connect } from 'react-redux';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Edit, CommandColumn, Toolbar } from '@syncfusion/ej2-react-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { getAppointmentList } from '../../redux/actions/AppointmentActions'
import { getServiceScheduleAction, updateServiceScheduleAction,deleteScheduleAction } from '../../redux/actions/ServiceActions'
import { resetStatus } from '../../redux/actions/AuthActions'
import {Modal, FormLabel, FormControl, Button, Row, Col, Card, Form } from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';

class SchedulePageView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      scheduleList: [],
      toolbarOptions:['Add', 'Edit', 'Delete', 'Update','Cancel'],
      editSettings:  { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top',  showDeleteConfirmDialog: true, allowSaveOnRowClick: true },
      pageSettings: { pageCount: 5},
      dateFormat: "MMM, dd yyyy",
      timeFormat: "hh:mm a",
      availabilityTime: [],
      recurrenceRule:"",
      daysOption: [],
      selectedDaysOption: [],
      daysOptionModal: false,
      validation: { required: true }
    }
  }

  commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
            { type: 'Delete', buttonOption: { iconCss: ' e-icons e-delete', cssClass: 'e-flat' } },
          ]


  recurrenceRules = {
    params:   {
      change: (args) => {
        if(args.value === "Weekly" || args.value === "Monthly"){
          this.changeDaysOption(args.value);
        }
      },
      dataSource: new DataManager(
        [
          { text: "Daily", value: "Daily"},
          { text: "Weekly", value: "Weekly"},
          { text: "Monthly", value: "Monthly"},
          { text: "None", value: "None"},
        ]
      ),
      fields: { text: "text", value: "value"},
      query: new Query().select(['text', 'value']),
    }
  };

  timeRules = {
    params:   {
      actionComplete: () => false,
      dataSource: new DataManager(
        this.setAvailabilityTime()
      ),
      fields: { text: "text", value: "value", key: "key"},
      query: new Query().select(['text', 'value', 'key']),
      sortOrder: 'None'
    }
  };

  changeDaysOption(recurrence_rule){
    let daysOption = [];
    if (recurrence_rule === "Weekly") {
      daysOption = [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
      ];
    }
    if (recurrence_rule === "Monthly") {
      for (var i = 1; i <= 31; i++) {
        daysOption.push({ value: i, label: i });
      }
    }
    this.setState({ daysOption: daysOption, daysOptionModal: true, selectedDaysOption: [], recurrenceRule: recurrence_rule });
  }

  componentDidMount() {
    this.props.getServiceScheduleAction(`?service_id=${this.props.serviceId}`)

    $("#id_update").find(".e-tbar-btn-text").text("Save");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceData && nextProps.serviceData.scheduleRes && nextProps.serviceData.type === "response") {
      this.setState({ scheduleList: nextProps.serviceData.scheduleRes.data })
    }

  }
  
  actionBegin(args, abcd) {
    console.log(args, '@@@@@@')
    if(args.requestType === "save"){
      let schedule_data = {
        "total_count":0,
        "service_id": this.props.serviceId,
        // "recurrence":true,
        "recurrence_rule": args.data.recurrence_rule,
        "start_time": args.data.start_time,
        "end_date_time": args.data.end_date_time,
        "recurrence_list":{
          "start_time":args.data.start_time,
          "data": this.state.selectedDaysOption,
          "end_time": args.data.end_time,
        },
        "end_time": args.data.end_time,
        "id": args.data.id?args.data.id:null,
        "start_date_time": args.data.start_date_time
      }
      console.log("args.data", args.data)
      this.props.updateServiceScheduleAction(schedule_data);
    }
    else if (args.requestType === "delete"){    
      this.props.deleteScheduleAction(args.data[0].id, '');
    }
    else{
      if(args?.rowData?.recurrence_list?.data?.length){
        this.setState({"selectedDaysOption": args.rowData.recurrence_list.data})
      }
    }
  }

  

  setAvailabilityTime() {
    let availabilityTime = [];
    for (var i = 0; i < 24; i++) {
        let time = moment(`${i}:00`, "HH:mm").format("hh:mm A");
        let timeValue = moment(`${i}:00`, "HH:mm").format("HH:mm");

        if(i == 0){
          time = "00:00 AM";
        }
        availabilityTime.push({ text: time, value: timeValue, key: `${i}:00` });
    }
    // availabilityTime.push( {text: `24:00`, value: `23:59`, key: `23:59` })
    return availabilityTime;
  }

  selectDays = (value, event) => {
    let recurrence_list = this.state.selectedDaysOption;
    console.log("recurrence_list", recurrence_list)
    recurrence_list = recurrence_list ? recurrence_list : [];
    if (event.target.checked === true && !recurrence_list.includes(value)) {
      recurrence_list.push(value.toString());
    } else {
      var index = recurrence_list.indexOf(value.toString());
      if (index >= 0) {
        recurrence_list.splice(index, 1);
      }
    }
    this.setState({ selectedDaysOption: recurrence_list });
  }
  
  
  checkChecked(value){
    return this.state.selectedDaysOption.indexOf(value+"") == -1?false:true;
  }
  
  start_end_time_format(args, row) {
    let value = row[args.field];
    value = moment(value, "HH:mm").format("hh:mm A");
    return value;
  }

  render() {  
    return (
      <React.Fragment>
        <GridComponent id='id' dataSource={this.state.scheduleList} toolbar={this.state.toolbarOptions} allowPaging={true} editSettings={this.state.editSettings} pageSettings={this.state.pageSettings}
        actionBegin={this.actionBegin.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field='start_date_time' textAlign='Left' headerText='Start Date' editType='datepickeredit' type='date' format={this.state.dateFormat} validationRules={this.state.validation} />
            <ColumnDirective field='end_date_time' textAlign='Left' headerText='End Date' editType='datepickeredit' type='date' format={this.state.dateFormat} validationRules={this.state.validation} />
            <ColumnDirective field='recurrence_rule' textAlign='Left' headerText='Recurrence Rule' editType='dropdownedit' edit={this.recurrenceRules} validationRules={this.state.validation} />
            <ColumnDirective field='start_time' textAlign='Left' headerText='Start Time' editType='dropdownedit' formatter={this.start_end_time_format} edit={this.timeRules} validationRules={this.state.validation} />
            <ColumnDirective field='end_time' textAlign='Left' headerText='End Time' editType='dropdownedit' formatter={this.start_end_time_format} edit={this.timeRules} validationRules={this.state.validation} />
            <ColumnDirective headerText='Action' textAlign='center' headerTextAlign="center" width='160' commands={this.commands} />
          </ColumnsDirective>
          <Inject services={[Page, CommandColumn, Toolbar, Edit]} />
        </GridComponent>

        {/*  Weekly Model */}
        <Modal show={this.state.daysOptionModal} onHide={e=>this.setState({daysOptionModal:false})} >
          <Modal.Header closeButton>
            <Modal.Title> Update Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.recurrenceRule && (this.state.recurrenceRule === 'Monthly' || this.state.recurrenceRule === 'Weekly') &&
              <div className="col-md-12">
                {this.state.daysOption && this.state.daysOption.map((item, i) => (
                  <div className={this.state.recurrenceRule === 'Weekly' ? 'box week-box' : 'box'} key={i}>
                    <div className="form-group">
                      <label className="checkboxInline">
                        <input type="checkbox" name="recurrence_list" onChange={e => { this.selectDays(item.value, e) }} checked={this.checkChecked(item.value)}/> {item.value}
                        <span className="check-bg"></span>
                      </label>
                    </div>
                  </div>)
                )}
                <br />
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width:"20%"}} variant="primary" onClick={() => this.setState({daysOptionModal:false})}>Save</Button>
          </Modal.Footer>
        </Modal>
        {/*  /Weekly Model */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    appointmentData: state.appointmentReducer,
  }
}
const mapDispatchToProps = {
  getServiceScheduleAction: getServiceScheduleAction,
  updateServiceScheduleAction: updateServiceScheduleAction,
  getAppointmentList: getAppointmentList,
  resetStatus: resetStatus,
  deleteScheduleAction: deleteScheduleAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(SchedulePageView);