import Api from '../Api'

export const getServicesEnroll = (type) => {
    return Api.get( type ? 'service-enroll/'+type :'service-enroll/');
};

export const createServiceEnroll = (data) => {
    return Api.post('service-enroll/', data);
}

export const updateServiceEnrollByProvider = (data) => {
    return Api.put('service-enroll/', data);
}

export const updateServiceEnroll = (serviceId, data) => {
    return Api.put('service-enroll/' + serviceId, data);
}

export const deleteServiceEnroll = (serviceId) => {
    return Api.delete('service-enroll/' + serviceId);
}