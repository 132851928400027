import React, { Component } from 'react';
import LoaderManager from './LoaderManager';

class LoaderContainer extends Component{
  constructor(props){
    super(props);
    this.state = {
      show:false
    }
    this.hideShow = this.hideShow.bind(this);
  }

  componentWillMount = () => {
    LoaderManager.addChangeListener(this.hideShow);
  };

  hideShow(type){
    this.setState({show:type});
  }

  render(){
    return (
      <div className="loader" style={{display:this.state.show?"block":"none"}}>
        <img src={require("./tenor.gif")} alt="Loading" width="200px" style={{"zIndex":"99999 !important"}}/>
        {/* <center>Loading...</center> */}
      </div>
    )
  }
}

export default LoaderContainer;