import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {appConstants} from "../../_Constants/app.constants"
import {updateProfileUser,resetStatus} from '../../redux/actions/AuthActions'
import {serviceListAction, serviceEnrollListAction, serviceUnEnrollAction, serviceEnrollinvitedAction} from '../../redux/actions/ServiceActions'
import { createInviteServiceAction} from '../../redux/actions/ServiceActions'
import imageService from "./images/service-thumbnails/thumbnail-1.png"
import Slider from "react-slick";
import {Container, Row, Col, Card, Modal, FormLabel, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import "./css/services.css"
import defaultImg from '../../assets/images/default.png';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

class MyService extends React.Component {
    constructor(props){
      super(props)
      this.state={
        file:'',
        error:'',
        success:'',
        imagePreviewUrl:'',
        loading:false,
        modal:false,
        modelType:'',
        serviceList:[],
        serviceEnrolledList:[],
        serviceEnrollInvitedList:[],
        formData:{
          serviceId:""
        },
      }
      this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      document.title = "Kunneckt | MyService"
      this.props.serviceEnrollListAction()
      this.props.serviceListAction("?t=all");
      this.props.serviceEnrollinvitedAction()
      if (localStorage.getItem('token') === null) {
        this.props.history.push('/login')
      }
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type == "myEnrollList"){
        let myEnrollArray = nextProps.serviceData.services;
        if(myEnrollArray){            
          myEnrollArray = myEnrollArray.filter(function(item){
            return item.serviceStatus===''
          })
        }
        this.setState({
          serviceEnrolledList:myEnrollArray?myEnrollArray:[],
        })
      }         

      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type == "enrollInvited"){
        let enrollInvitedList = nextProps.serviceData.services?nextProps.serviceData.services:[];
        enrollInvitedList.sort(function(a,b){
          return new Date(b.timestamp) - new Date(a.timestamp)
        })
        if(enrollInvitedList){            
          enrollInvitedList = enrollInvitedList.filter(function(row){
            return (row.isAccepted==='None' || row.isAccepted==='')
          })
        }        
        this.setState({
          serviceEnrollInvitedList: enrollInvitedList,
        })
      }

      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type == "unEnroll"){
        this.setState({
          modal:false,
        })
        this.props.serviceEnrollListAction()
        NotificationManager.success(nextProps.serviceData.services.message);
      }

      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type==="list"){
        this.setState({
          serviceList:nextProps.serviceData.services.results ? nextProps.serviceData.services.results :[],
        })
      } 
    }

    editDetails =(data,e)=>{
      this.props.history.push({
        pathname: "/edit-service",
        state: {data : data?data:{}, redirect: 'myService'}
      });
    }

    hideModals=(data, type)=> {
      this.setState({
        formData:data?data:{},
        modal: !this.state.modal,
        modelType: type
      })
    }

    handleChange(type, e) {
      let data = this.state.formData;
      if (type === "ext") {
          data[type] = e;
      } else {
          data[type] = e.target.value;
      }
      this.setState(data);
    }

    unEnrolService=(serviceId)=> {
      this.props.serviceUnEnrollAction(serviceId)
    }
    
    IsAccepted = (cell,row)=>{
      if(row.isAccepted===false || row.isAccepted==="False"){
        return (<span>Unapproved</span>)
      }else if(row.isAccepted===true || row.isAccepted==="True"){
        return (<span>Approved</span>)
      }else{
        return (<span>Pending</span>)
      }
    }      

    IsAction = (cell,row)=>{
      return (<Link to="#" onClick={(e)=>this.hideModals(row, 2)}> View </Link>)
    }      

    IsDate = (cell,row)=>{
      var dateTimeValue = moment(row.timestamp).format('MMM, DD YYYY');
      return (<span>{dateTimeValue}</span>)
    } 

    render() {
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
         responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
      };

      const {serviceList, serviceEnrollInvitedList, serviceEnrolledList, formData} = this.state;

      var enrollInvitedArr = serviceEnrollInvitedList?serviceEnrollInvitedList:[];
      if(enrollInvitedArr && serviceList){
        for (var i = 0; i < enrollInvitedArr.length; i++) {
          for (var j = 0; j < serviceList.length; j++) {
            if(serviceList[j].id===enrollInvitedArr[i].serviceId){
              enrollInvitedArr[i]['poster'] = serviceList[j].poster;
              enrollInvitedArr[i]['title'] = serviceList[j].title;
              enrollInvitedArr[i]['description'] = serviceList[j].description;
              enrollInvitedArr[i]['serviceEmail'] = serviceList[j].email;
              enrollInvitedArr[i]['providerName'] = serviceList[j].providerName;
              enrollInvitedArr[i]['streetAddress'] = serviceList[j].streetAddress;
              enrollInvitedArr[i]['city'] = serviceList[j].city;
              enrollInvitedArr[i]['state'] = serviceList[j].state;
              enrollInvitedArr[i]['zip'] = serviceList[j].zip;
              //Object.assign(enrollInvitedArr, enrollInvitedArr[i], serviceList[j]);
            }
          }
        }
      }
      
      return (
        <div className="container-fluid">
          <div className="row">
              <div className="col-md-12">
                <div className="under-ssc invitedyou">
                  <div className="heading-text">Services Enroll By You </div>
                  <div className="col-md-12">
                    {serviceEnrolledList && serviceEnrolledList.length > 0&&
                      <Slider {...settings}>
                        {serviceEnrolledList.map((item, index) => {
                          let serviceStatus = 'Pending';
                          let serviceClass = ' btn-warning';
                          if(item.serviceStatus===false || item.serviceStatus==="False"){
                            serviceStatus = 'Declined';
                            serviceClass = ' btn-danger';
                          }else if(item.serviceStatus===true || item.serviceStatus==="True"){
                            serviceStatus = 'Accepted';
                            serviceClass = ' btn-success';
                          }
                          return(
                            <div className="col-md-7-grid" data-index={index} key={index}>
                              <div className="service-secc">
                                <img src={item.poster?item.poster:defaultImg} alt={item.title}/>
                                <h3>{item.title}</h3>
                                <p>{item.providerName}</p>
                              </div>
                              <div className='row'>
                                <div className="col-md-10">
                                  <label className={"labelMyService "+serviceClass}>{serviceStatus}</label>
                                </div>
                                <div className='col-md-2'>
                                  <button onClick={(e)=>this.hideModals(item, 1)} className="invite-service-MyService"><i className="fa fa-info-circle fa-3x" aria-hidden="true"></i></button>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Slider>
                    } 
                  </div>
                </div>

                <div className="under-ssc invitedyou">
                  <div className="heading-text">Services Invited By You </div>
                  <div className="col-md-12">
                    <BootstrapTable data={ enrollInvitedArr } pagination search={false}>
                      <TableHeaderColumn tdAttr={ { 'data-attr': 'ID' }} dataField='id' isKey hidden={true}>Id</TableHeaderColumn>
                      <TableHeaderColumn width="200" tdAttr={ { 'data-attr': 'Provider Name' }} dataField='email'>Consumer Email</TableHeaderColumn>
                      <TableHeaderColumn width="200" tdAttr={{ 'data-attr':'Service Name'}} dataField='title' >Service</TableHeaderColumn>
                      <TableHeaderColumn width="200" dataFormat={this.IsDate.bind(this)} tdAttr={{ 'data-attr':'Invited Date'}} dataField='InvitedAt' >Invited Data</TableHeaderColumn>
                      <TableHeaderColumn width="100" tdAttr={{ 'data-attr':'Status'}} dataFormat={this.IsAccepted.bind(this)} >Status</TableHeaderColumn>
                      <TableHeaderColumn width='100' tdAttr={{ 'data-attr':'Action'}} dataFormat={this.IsAction.bind(this)} dataSort={true} searchable={false}>Action</TableHeaderColumn>
                    </BootstrapTable><br/>
                    {/*<Slider {...settings}>
                      {enrollInvitedArr.map((item, index) => {
                        let serviceStatus = 'Pending';
                        let serviceClass = ' btn-warning';
                        if(item.isAccepted===false || item.isAccepted==="False"){
                          serviceStatus = 'Declined';
                          serviceClass = ' btn-danger';
                        }else if(item.isAccepted===true || item.isAccepted==="True"){
                          serviceStatus = 'Accepted';
                          serviceClass = ' btn-success';
                        }
                        return(
                          <div className="col-md-7-grid" data-index={index} key={index}>
                            <div className="service-secc">
                              <img src={item.poster?item.poster:defaultImg} alt={item.title}/>
                              <h3>{item.title}</h3>
                              <p>{item.providerName}</p>
                            </div>
                            <div className='row'>
                              <div className="col-md-10">
                                <label className={"labelMyService "+serviceClass}>{serviceStatus}</label>
                              </div>
                              <div className='col-md-2'>
                                <button onClick={(e)=>this.hideModals(item, 2)} className="invite-service-MyService"><i className="fa fa-info-circle fa-2x" aria-hidden="true"></i></button>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>*/}
                  </div>
                </div>
              </div>

              <Modal show={this.state.modal} onHide={(e)=>this.hideModals({})} enforceFocus={false}>
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.modelType===1?'Enroll Service':'Invited Service'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="serviceEnrollModal">
                  <div className="">
                    <div className="" >
                        <img src={formData.poster?formData.poster:defaultImg} height="300px" alt={formData && formData.title}/><br/>
                        <p className="head-lab">Service Name : {formData && formData.title}</p>
                        <p className="head-lab">Provide By -{formData && formData.providerName}</p>
                        <p className="head-lab">Description -{formData && formData.description}</p>
                        <p className="head-lab">Street Address -{formData && formData.streetAddress}</p>
                        <p className="head-lab">City -{formData && formData.city}</p>
                        <p className="head-lab">State -{formData && formData.state}</p>
                        <p className="head-lab">Zip -{formData && formData.zip}</p>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
          </div>
        </div>
      )
    }
}


const mapStateToProps = state => {
    return {
      error: state.serviceReducer.signupError,
      success: state.serviceReducer.success,
      serviceData: state.serviceReducer,
    }
}

const mapDispatchToProps = {
    serviceListAction: serviceListAction,
    serviceUnEnrollAction: serviceUnEnrollAction,
    serviceEnrollListAction: serviceEnrollListAction,
    serviceEnrollinvitedAction: serviceEnrollinvitedAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(MyService);
