import React from "react";
import { connect } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { Modal, FormLabel, Form, Col, Row } from "react-bootstrap";
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
  RecurrenceEditorComponent,
} from "@syncfusion/ej2-react-schedule";
import { isBrowser, isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import { getUserListingAction } from "../redux/actions/UserActions";
import {
  getMyAppoinmentListAction,
  deleteAppoinmentByProviderAction,
  createAppointmentActionByProvider,
  createAppointmentAction,
  deleteAppoinmentAction,
} from "../redux/actions/AppointmentActions";
import { getScheduleMyAppoinmentAction } from "../redux/actions/ServiceActions";
import $ from "jquery";
import image4 from "../assets/images/image4.png";
import image2 from "../assets/images/image2.png";
import image1 from "../assets/images/image1.png";
import image8 from "../assets/images/image8.png";
import image9 from "../assets/images/image9.png";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
class MyAppoinment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      error: "",
      success: "",
      imagePreviewUrl: "",
      imagePosterUrl: "",
      imageLogoUrl: "",
      loading: false,
      updateStatus: false,
      formData: {},
      updateFormData: {},
      userListing: [],
      enrollNotificationList: [],
      modal: false,
      modalData: {},
      serviceObj: null,
      userObj: {},
      appoinmentObj: {},
      scheduleData: null,
      appointmentData: [],
      slotsNotAvailableModal: false,
      createStatus: false,
      appoinmentDataAvailable: false,
      showScheduleObj: {},
      showSchedule: false,
      deleteConfirmaProcessRequestModel: false,
      appoinmentId: "",
      calenderLoadStatus: true,
      scheduleData: []
    };
    this.validator = new SimpleReactValidator();
    // this.dataManager = new DataManager({
    //   url: `https://www.kunneckt.com/api/api/appointment-enroll/`,
    //   crossDomain: true,
    //   headers: [{ Authorization: `Bearer ${localStorage.getItem("token")}` }],
    // });
  }

  componentDidMount() {
    document.title = "Kunneckt | My Appointments";
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/login");
    }
    this.props.getMyAppoinmentListAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceData && nextProps.serviceData.services) {
      let myServiceArray = nextProps.serviceData.services.results;
      let serviceObj = {};
      if (myServiceArray && myServiceArray.length) {
        myServiceArray.forEach((item) => {
          serviceObj[item.id] = item;
        });
      }
      this.setState({
        serviceObj: serviceObj,
      });
    }

    if (
      nextProps.userDetail &&
      nextProps.userDetail.users &&
      nextProps.userDetail.type === "userList"
    ) {
      if (
        nextProps.userDetail &&
        nextProps.userDetail.users &&
        nextProps.userDetail.type === "userList"
      ) {
        let userObj = {};
        if (nextProps.userDetail.users && nextProps.userDetail.users.length) {
          nextProps.userDetail.users.forEach((item) => {
            userObj[item.id] = item;
          });
          this.setState({
            userObj: userObj,
          });
        }
      }

      if (
        nextProps.myAppointmentData &&
        nextProps.myAppointmentData.appoinment
      ) {
        let appointmentData = nextProps.myAppointmentData.appoinment;
        this.setState({ appointmentData });
        this.reset_appointment(appointmentData);
      }

      if (
        nextProps.appointmentData &&
        nextProps.appointmentData &&
        this.state.createStatus
      ) {
        this.setState({
          createStatus: false,
        });
      }

      if (
        nextProps.appointmentData &&
        nextProps.appointmentData &&
        this.state.deleteStatus
      ) {
        this.setState({
          deleteStatus: false,
        });
      }
    }
  }

  eventTemplate(args) {
    const serviceObj = this.state.serviceObj[args?.service_id];
    const userObj = this.state.userObj[(args?.user_id || args?.userId)];
    if (serviceObj) {
      let logo = "";
      let name = "";
      let { providerApproved, service_id } = args;
      let title = serviceObj.title;
      if ((args?.user_id || args?.userId) == localStorage.getItem("userId")) {
        logo = serviceObj.logo;
        name = serviceObj.providerName;
      } else if (userObj) {
        logo = userObj.profile;
        name = userObj.firstName + " " + userObj.lastName;
      }
      if (providerApproved === true || (serviceObj.id === service_id && serviceObj.provider === localStorage.getItem("userId"))) {
        providerApproved = true;
      }
      return (
        <div className={(!providerApproved) ? 'providerApprovedChild' : ''}>
          <Col lg="3" >
            <div className="d-flex-outer">
              <div className={`outer-image ${this.scheduleObj.getCurrentViewIndex() == 0 || this.scheduleObj.getCurrentViewIndex() == 1 ? "day-week-img" : ""}`}>{logo && <img style={{ width: "40px" }} src={logo} />}</div>
              <div className="outer-text">
                <div className='service-nam'><strong className="">{title}</strong></div>
                <div className='user-name' style={{ fontWeight: "normal" }}>{name}</div>
              </div>
            </div>
          </Col>
        </div>
      );
    } else {
      return false;
    }
  }
  hideModals(args) {
    this.setState({ slotsNotAvailableModal: false });
  }


  schedulePage = (data, startTime, appoinmentDetails) => {
    data["section"] = "appointment";
    data["appointmentDate"] = appoinmentDetails.start_date;
    this.props.history.push({
      pathname: `/view-service/${data.id}/${appoinmentDetails.id}`,
      state: { data: data, appoinmentDetails },
    });
  };

  mapUrl = (data) => {
    return window.encodeURI(
      "https://www.google.com/maps/place/" +
      data.street_address +
      " " +
      data.state
    );
  };


  contentTemplate(args) {
    if (args.Id) {
      const serviceObj = this.state.serviceObj[args.service_id];
      const appoinmentId = args.id;
      const appoinmentObj = this.state.appoinmentObj[appoinmentId];
      const userObj = this.state.userObj[(args?.user_id || args?.userId)];
      let email = "";
      let phone = "";
      let title = "";
      let logo = "";
      let address = {
        street_address: serviceObj.streetAddress,
        state: serviceObj.state,
      };
      let name = "";

      if (serviceObj) {
        if ((args?.user_id || args?.userId) == localStorage.getItem("userId")) {
          name = serviceObj.providerName
          email = serviceObj.email;
          phone = serviceObj.phone;
          logo = serviceObj.logo;
        } else {
          name = userObj.firstName + " " + userObj.lastName;
          email = userObj.email;
          phone = userObj.phone;
          logo = userObj.profile;
        }
        title = serviceObj.title;
      }
      return (
        <Col className="appoinment-popup-footer">
          <ReactTooltip />
          <Row className="titleUserName" style={{ display: "none" }}>
            <div className="d-flex-outer">
              <div className="outer-image">
                {logo && (
                  <Col lg="3">
                    <img style={{ width: "40px" }} src={logo} />
                  </Col>
                )}
              </div>
              <div className="outer-text">
                <div>
                  {title}
                  <span className="user-name">{name}</span>
                </div>
              </div>
            </div>
          </Row>
          <Row className="iconPopup">
            <Col>
              <a href={"mailto:" + email}>
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </a>
            </Col>
            {isMobile && (
              <Col>
                <a href={"tel:" + phone}>
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </a>
              </Col>
            )}
            {isBrowser && (
              <Col>
                <a
                  href="javascript:void(0);"
                  onClick={this.phoneClick}
                  data-tip={
                    "Please try connecting with a mobile device. Contact - " +
                    phone
                  }
                >
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </a>
              </Col>
            )}
            <Col>
              <a
                href="javascript:void(0);"
                onClick={(e) => this.schedulePage(serviceObj, args.startTime, args)}
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </a>
            </Col>
            <Col>
              <a href={this.mapUrl(address)} target="_blank">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </a>
            </Col>
          </Row>
        </Col>
      );
    } else {
      return false;
    }
  }

  onRenderCell(args) {
    // args.element.classList.remove("e-work-cells");
    // args.element.classList.add("no-work-cells");
    args.cancel = true;
  }
  onPopupOpen(args) {
    $(".e-event-popup").addClass("customHeader");
    $(".e-header-icon-wrapper .e-close").hide();
    $(".e-title-location-row").hide();
    $(".e-description-row").hide();
    $(".e-footer-content .e-event-delete").hide();
    $(".e-popup-header .e-subject.e-text-ellipsis").html(
      $(".titleUserName").html()
    );
    $(".titleUserName").remove();
    $(".e-header-icon-wrapper").hide();
    if (args.type === "QuickInfo") {
      if (args.target.classList.contains("e-work-cells")) {
        let dialogObj = args.element.ej2_instances[0];
        dialogObj.hide();
        let currentAction = args.target.classList.contains("e-work-cells")
          ? "Add"
          : "Save";
        this.scheduleObj.openEditor(args.data, currentAction);
      }

      let html = $(".e-popup-content .e-date-time").html();
      $(".customTimeData").remove();
      $("#QuickDialog").append(`<span class='customTimeData'>${html}</span>`);
      $(".customTimeData").hide();
      let formElement = args.element.querySelector(".e-schedule-form");
      if (formElement?.firstChild || 0) {
        $(".e-subject").hide();
        this.addFieldDropDown(args);
      }
    }
  }

  onEventRendered(args) {
    setTimeout(() => {
      $('.providerApprovedChild').parents('.e-appointment').addClass('providerApproved');
    }, 1000);
  }

  reset_appointment(appointmentData) {
    let appoinmentObj = {};
    let appointmentScheduleData = [];
    if (appointmentData.length > 0) {
      for (let i in appointmentData) {
        let startdate = appointmentData[i].start_date;
        let enddate = appointmentData[i].end_date;
        let full_name = '';
        for (const i in this.state.enrolled_users) {
          if (this.state.enrolled_users[i].id === appointmentData[i]?.userId) {
            full_name =
              this.state.enrolled_users[i].firstName +
              " " +
              this.state.enrolled_users[i].lastName;
          }
        }

        let data = {
          Id: appointmentData[i].id,
          id: appointmentData[i].id,
          Subject: appointmentData[i].subject ? appointmentData[i].subject : full_name,
          RecurrenceRule: appointmentData[i].recurrence_rule ? appointmentData[i].recurrence_rule : '',
          StartTime: new Date(startdate),
          EndTime: new Date(enddate),
          userId: appointmentData[i].userId,
          ...appointmentData[i],
          providerApproved: true
        }
        appointmentScheduleData.push(data);
        appoinmentObj[appointmentData[i].id] = appointmentData[i];
      }
    }
    this.setState({
      scheduleData: [...appointmentScheduleData],
      appoinmentObj: appoinmentObj,
    });
    this.show_schedule("appoinment");
    if (document.querySelector(".e-schedule")) {
      let scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
      scheduleObj.eventSettings.dataSource = this.state.scheduleData;
    }

  }

  show_schedule(key) {
    let showScheduleObj = this.state.showScheduleObj;
    showScheduleObj[key] = true;
    this.setState({ showScheduleObj });

    this.setState({ showSchedule: false });

    let _this = this;
    _this.setState({ showSchedule: true });
  }

  render() {

    return (
      <React.Fragment>
        {this.state.serviceObj && this.state.userObj &&
          <div className="my-appointment-section">
            <ScheduleComponent
              readonly={true}
              renderCell={this.onRenderCell.bind(this)}
              popupOpen={this.onPopupOpen.bind(this)}
              eventRendered={this.onEventRendered.bind(this)}
              ref={(schedule) => (this.scheduleObj = schedule)}
              showTimeIndicator={true}
              eventSettings={{
                dataSource: (this.state.scheduleData.length) ? this.state.scheduleData : [],
                fields: JSON.parse(
                  JSON.stringify({
                    Id: { title: "Id", name: "Id" },
                    subject: { title: "Subject", name: "subject" },
                    StartTime: { title: "StartTime", name: "startTime" },
                    EndTime: { title: "EndTime", name: "endTime" },
                    user_id: { title: 'User Id', name: 'user_id', default: localStorage.getItem("userId") },
                    service_id: {
                      title: "service",
                      name: "service_id",
                      default: this.state.serviceId,
                    },
                  })
                ),
                template: this.eventTemplate.bind(this)
              }}
              quickInfoTemplates={{
                footer: this.contentTemplate.bind(this),
              }}
            >
              <ViewsDirective>
                <ViewDirective option="Day" />
                <ViewDirective option="Week" />
                <ViewDirective option="Month" />
              </ViewsDirective>
              <Inject services={[Day, Week, Month]} />
            </ScheduleComponent>
          </div>
        }

        <div className="content-wrapper recurrence-editor-wrap">
          <div style={{ paddingBottom: "15px" }}>
            <label></label>
            <div className="rule-output-container">
              <div id="rule-output"></div>
            </div>
          </div>
          <div className="RecurrenceEditor" style={{ display: "none" }}>
            <RecurrenceEditorComponent
              id="RecurrenceEditor"
              ref={(t) => (this.recObject = t)}
            ></RecurrenceEditorComponent>
          </div>
        </div>

        <Modal
          show={this.state.slotsNotAvailableModal}
          onHide={(e) => this.hideModals({})}
          enforceFocus={false}
          className="count-modal"
        >
          <Modal.Header closeButton>
            <div className="header-modal">
              <span className="header-cal">
                <img src={image4} alt="" />
              </span>
              <span className="header-details">
                <div className="header-details-cont">
                  <h4>Slot Collision Report</h4>
                  <p>
                    <small>Initiated by:</small>{" "}
                    {localStorage.getItem("firstName")}{" "}
                    {localStorage.getItem("lastName")}
                  </p>
                </div>
                <div className="header-details-img">
                  <img src={image2} alt="" />
                </div>
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="body-modal">
              <span>
                <p>
                  <strong>Please Review: </strong>Your appointment(s) cannot be
                  completed due to the conflicts listed below{" "}
                </p>
              </span>
              <span>
                <img src={image1} alt="" />
              </span>
            </div>
            <div className="body-content-repeat">
              {this.state.dataArray &&
                this.state.dataArray.map(function (item) {
                  return (
                    <div className="content-repeat-row">
                      <span className="cal-ic">
                        {item.recurrenceType != "No" ? (
                          <img src={image8} alt="" width="50px" />
                        ) : (
                          <img src={image9} alt="" width="50px" />
                        )}
                      </span>
                      <span className="start-end-time">
                        <p>
                          <strong>Start Time:</strong>{" "}
                          {item.cancel_date.toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </p>
                        <p>
                          <strong>End Time:</strong>{" "}
                          {item.end_date.toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </p>
                      </span>
                      <span className="recurrence">
                        <p>
                          <strong>Recurrence:</strong> {item.recurrenceType}
                        </p>
                        <p>
                          <strong>Till:</strong>{" "}
                          {item.recurrenceType != "No"
                            ? item.end_date.toDateString()
                            : "N/A"}
                        </p>
                      </span>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <a href="#">Service Schedule Page</a> */}
            <a href="#" onClick={(e) => this.hideModals({})}>
              Dismiss
            </a>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteConfirmaProcessRequestModel}
          onHide={this.hideDeleteConfirmaProcessRequestModel}
          enforceFocus={false}
        >
          <Modal.Header closeButton>Delete Confirmation</Modal.Header>
          <Modal.Body className="notificationModal">Are you sure?</Modal.Body>
          <Modal.Footer>
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                this.deleteConfirm("yes");
              }}
            >
              Yes
            </a>
            &nbsp;
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                this.deleteConfirm("no");
              }}
            >
              No
            </a>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    userDetail: state.userReducer,
    myAppointmentData: state.appointmentReducer,
    appointmentData: state.appointmentReducer,
  };
};

const mapDispatchToProps = {
  getUserListingAction: getUserListingAction,
  getMyAppoinmentListAction: getMyAppoinmentListAction,
  deleteAppoinmentByProviderAction: deleteAppoinmentByProviderAction,
  createAppointmentActionByProvider: createAppointmentActionByProvider,
  createAppointmentAction: createAppointmentAction,
  deleteAppoinmentAction: deleteAppoinmentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyAppoinment);
