import React, { Component } from 'react'
import '../assets/css/login.css'
import Logo from '../assets/images/logo-bottomline.png'
import { Link } from "react-router-dom";
import { verifyEmail } from '../services/authServices'

class VerifyEmail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            success: false,
            loading: true,
            title:'Verifying your account please wait...'

        }
    }


    componentDidMount() {
        document.title = 'Verifying Email'
        let key = new URLSearchParams(this.props.location.search).get("key")
        verifyEmail(key).then(res => {
            this.setState({
                success: true,
                loading: false,
                title:'Account verification completed.'
            })
        }).catch(err => {
            this.setState({
                success: false,
                loading: false,
                title:'Account verification failed.'

            })
        })
       

    }

    render() {

        return (
            <div className="login_page">
                <table className="highlight-line"></table>
                <table className="logo-center text-center">
                    <tbody>
                        <tr><td><img src={Logo} alt="" height="200px" /></td></tr>
                    </tbody>
                </table>
                <div id="rcornersbox">
        <div className="login-prompt-text">{this.state.title}</div>

                    {this.state.loading && <div className="text-center"><i className="fa fa-spinner fa-pulse fa-4x fa-fw"></i></div>}
                    
                  
                    {!this.state.loading ? this.state.success ? <div>
                        <div className="text-center"><i className="fa fa-check  fa-4x fa-fw"></i></div>

                        <div className="prompt-text alert alert-success">
                            Account has been confirmed . Please <Link to="/login">Login here.</Link>

                        </div>
                    </div> : <div><div className="text-center"><i className="fa fa-times  fa-4x fa-fw"></i></div><div className="prompt-text alert alert-danger">
                           Please try after some time.</div></div>:''}
                </div>
            <table className="highlight-line"></table>
            </div >
        )
    }
}

export default VerifyEmail;