import React from 'react';


class NotFoundPage extends React.Component{
    render(){
        return <div className="justify-content-center text-center">
            <img src='https://www.admecindia.co.in/sites/default/files/404.gif' alt="" />
          </div>;
    }
}
export default NotFoundPage;