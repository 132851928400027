import { combineReducers } from 'redux'
import { authReducer } from './AuthReducer'
import { userReducer } from './UserReducer'
import { serviceReducer } from './ServiceReducer'
import { appointmentReducer } from './AppointmentReducer'
import { serviceEnrollReducer } from './ServiceEnrollReducer'
import { countReducer } from './CountReducer'
import { serviceIdReducer } from './ServiceIdReducer'


export default combineReducers({
    authReducer,
    userReducer,
    serviceEnrollReducer,
    serviceReducer,
    appointmentReducer,
    countReducer,
    serviceIdReducer
})

