import React from 'react'
import { connect } from 'react-redux';
import i18n from '../i18n';
import { withTranslation } from 'react-i18next'
import Api from '../Api'
import logo from '../assets/images/logo.png'
import user from '../assets/images/user.jpg'
import { Link } from "react-router-dom";
import { logout } from '../services/authServices'


class Header extends React.Component {
	languageSelector = (lang) => {
		i18n.changeLanguage(lang)
		Api.defaults.headers.common['lang'] = lang === 'en' ? 1 : 2;
	}

	handleLogout = () => {
		logout().then(res => {
			localStorage.clear(); 
			window.location.href = '/'
		}).catch()

	}
	componentDidMount() {
		//this.props.userData();
		//this.props.lookupData();
	}

	render() {

		const { t } = this.props

		return (
			<header className="header-layout">
				<div className="logo-sec"><i className="icon-menu icon" id="barIcon" onClick={() => this.props.handletoggle()}></i><img src={logo} alt='' /></div>
				{/*<div className="header-search">*/}
					{/*<div className="form-group mb-0">*/}
						{/*<i className="icon-magnifier icons"></i>*/}
						{/*<input type="search" name="" placeholder="Search in app..." />*/}
					{/*</div>*/}
				{/*</div> */}
				<div className="header-right">
					{/*<div className="header-icons dropdown mr-5">
						<span className="language-btn dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img src={i18n.language === 'en' ? English : Chinese} className="mr-1" alt='' /> <span style={{ cursor: 'pointer' }}>{i18n.language === 'en' ? 'English' : 'ไทย'}</span>
						</span>

						<ul className="dropdown-menu language" aria-labelledby="navbarDropdown">
							<span className="dropdown-item" href="#" style={{ cursor: 'pointer' }} onClick={() => this.languageSelector('en')}>
								<img src={English} className="mr-1" alt='' /> English
							</span>
							<div className="dropdown-divider"></div>
							<span className="dropdown-item" href="#" style={{ cursor: 'pointer' }} onClick={() => this.languageSelector('th')}>
								<img src={Chinese} className="mr-1" alt='' /> ไทย
							</span>

						</ul>
					</div>*/}
					<div className="header-icons dropdown ">
						<span className="profile-btn dropdown-toggle" id="profile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img src={user} className="mr-1" alt='' />
							<span style={{ cursor: 'pointer' }}>{this.props.user !== undefined && this.props.user.fullName}</span>
						</span>
						<ul className="dropdown-menu language" aria-labelledby="profile">
							{/*<span className="dropdown-item" style={{ cursor: 'pointer' }} href="#">{t('MyProfile')}</span>
							<div className="dropdown-divider"></div>*/}
							<Link className="dropdown-item" to='#' onClick={this.handleLogout}> {t('Logout')}</Link>
						</ul>
					</div>
				</div>
			</header>
		)
	}
}
const mapStateToProps = state => {

	return {
		user: state.authReducer.userProfile,
	}
}

const mapDispatchToProps = {
	userData: {},
	lookupData: {}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));
