import * as actionTypes from '../constant';

import { getServices, createService,updateService, deleteService, getInviteServices, createInviteService,
 getInviteNotifications, acceptInvitationApi, 
 getEnrollNotifications,getAllNotifications,readAllNotifications, getServicesEnroll, serviceUnEnrollApi, getServiceEnrollinvitedApi, 
 getServiceScheduleApi, updateServiceScheduleApi,deleteSchedule, readNotification} from '../../services/Services';

export const serviceListAction = (type) => {
    return dispatch => {
        getServices(type).then(res => {
            if (res.data !== undefined) {
                dispatch(getServiceList(res.data));
                dispatch(clearError());
            }
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

export const serviceMyListAction = (type) => {
    return dispatch => {
        getServices().then(res => {
            if (res.data !== undefined) {
                dispatch(getMyServiceList(res.data));
                dispatch(clearError());
            }
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

export const serviceEnrollListAction = (type) => {
    return dispatch => {
        getServicesEnroll(type).then(res => {
            if (res.data !== undefined) {
                dispatch(getEnrollServiceList(res.data));
                dispatch(clearError());
            }
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

export const serviceGetAction = (type) => {
    return dispatch => {
        getServices(type).then(res => {
            if (res.data !== undefined) {
                dispatch(getServiceList(res.data));
                dispatch(clearError());
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getMyServiceList = (res) => {
    return {
        type: actionTypes.SERVICE_MY_LIST,
        value: res
    }
}

const getEnrollServiceList = (res) => {
    return {
        type: actionTypes.SERVICE_MY_ENROLL_LIST,
        value: res
    }
}

const getServiceList = (res) => {
    return {
        type: actionTypes.SERVICE_LIST,
        value: res
    }
}

export const createServiceAction = (service) => {
    return dispatch => {
        createService(service).then(res => {
            if (res.data !== undefined) {
                dispatch(createServicePost(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const createServicePost = (res) => {
    return {
        type: actionTypes.SERVICE_CREATE,
        value: res
    }
}

export const updateServiceAction = (serviceId, serviceData) => {
    return dispatch => {
        updateService(serviceId, serviceData).then(res => {
            if (res.data !== undefined) {
                dispatch(updateServicePut(res.data.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateServicePut = (res) => {
    return {
        type: actionTypes.SERVICE_UPDATE,
        value: res
    }
}


export const deleteServiceAction = (serviceId, data) => {
    return dispatch => {
        deleteService(serviceId, data).then(res => {
            if (res.data !== undefined) {
                dispatch(deleteServicePut(res.data));
            }

        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const deleteServicePut = (res) => {
    return {
        type: actionTypes.SERVICE_DELETE,
        value: res
    }
}

export const deleteScheduleAction = (serviceId, description) => {
    return dispatch => {
        deleteSchedule(serviceId, description).then(res => {
            if (res.data !== undefined) {
                dispatch(deleteSchedulePut(res.data));
            }

        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const deleteSchedulePut = (res) => {
    return {
        type: actionTypes.SCHEDULE_DELETE,
        value: res
    }
}

export const createInviteServiceAction = (service) => {
    return dispatch => {
        createInviteService(service).then(res => {
            if (res.data !== undefined) {
                dispatch(createInviteServicePost(res.data.data));
            } 

        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            if(err?.data?.errors?.email === "'email' is a required property") {
                dispatch(invalidError())
            } else dispatch(error(errormsg));
        });
    }
}

const invalidError = (err) => {
    return {
        type: actionTypes.INVALID_EMAIL_ERROR,
    }
}

const createInviteServicePost = (res) => {
    return {
        type: actionTypes.SERVICE_CREATE_INVITE,
        value: res
    }
}

export const InviteServiceGetAction = (type) => {
    return dispatch => {
        getInviteServices(type).then(res => {
            if (res.data !== undefined) {
                dispatch(getInviteServiceList(res.data));
                dispatch(clearError());
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getInviteServiceList = (res) => {
    return {
        type: actionTypes.SERVICE_LIST_INVITE,
        value: res
    }
}

//--
export const InviteNotificationGetAction = (type) => {
    return dispatch => {
        getInviteNotifications(type).then(res => {
            if (res.data !== undefined) {
                dispatch(getInviteNotificationList(res.data));
            }
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getInviteNotificationList = (res) => {
    return {
        type: actionTypes.INVITE_NOTIFICATION,
        value: res
    }
}

export const EnrollNotificationGetAction = (type) => {
    return dispatch => {
        getEnrollNotifications(type).then(res => {
            if (res.data !== undefined) {
                dispatch(EnrollNotificationFunc(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const EnrollNotificationFunc = (res) => {
    return {
        type: actionTypes.INVITE_NOTIFICATION,
        value: res
    }
}


export const getAllNotificationAction = (type) => {
    return dispatch => {
        getAllNotifications(type).then(res => {
            if (res.data !== undefined) {
                dispatch(allNotificationFunc(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const allNotificationFunc = (res) => {

    return {
        type: actionTypes.ALL_NOTIFICATION,
        value: res
    }
}

export const readAllNotification = (type) => {
    return dispatch => {
        readAllNotifications(type).then(res => {
            if (res.data !== undefined) {
                dispatch(readNotificationFunc(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const readNotificationFunc = (res) => {
    return {
        type: actionTypes.READ_NOTIFICATION,
        value: res
    }
}

export const readNotificationAction = (id, notificationTypeId) => {
    readNotification(id, notificationTypeId).then().catch();
}


export const acceptInvitationAction = (serviceId, serviceData) => {
    return dispatch => {
        acceptInvitationApi(serviceId, serviceData).then(res => {
            if (res.data !== undefined) {
                dispatch(acceptInvitationPut(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const acceptInvitationPut = (res) => {
    return {
        type: actionTypes.ACCCEPT_INVITATION,
        value: res
    }
}

export const serviceUnEnrollAction = (serviceId) => {
    return dispatch => {
        serviceUnEnrollApi(serviceId).then(res => {
            if (res.data !== undefined) {
                dispatch(serviceUnEnrollFunc(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const serviceUnEnrollFunc = (res) => {
    return {
        type: actionTypes.SERVICE_UN_ENROLL,
        value: res
    }
}

export const serviceEnrollinvitedAction = (serviceData) => {
    return dispatch => {
        getServiceEnrollinvitedApi(serviceData).then(res => {
            if (res.data !== undefined) {
                dispatch(serviceEnrollinvitedFunc(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const serviceEnrollinvitedFunc = (res) => {
    return {
        type: actionTypes.SERVICE_ENROLL_INVITED,
        value: res
    }
}


//-----------Service Schedule 
export const getServiceScheduleAction = (serviceData) => {
    return dispatch => {
        getServiceScheduleApi(serviceData).then(res => {
            if (res.data !== undefined) {
                dispatch(getServiceScheduleFunc(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}
const getServiceScheduleFunc = (res) => {
    return {
        type: actionTypes.SERVICE_SCHEDULE,
        value: res
    }
}

export const getScheduleMyAppoinmentAction = async(service) => {
    return await getServiceScheduleApi(service).then().catch();
}

export const updateServiceScheduleAction = (serviceData) => {
    return dispatch => {
        updateServiceScheduleApi(serviceData).then(res => {
            if (res.data !== undefined) {
                dispatch(updateServiceScheduleFunc(res.data));
            }
        }).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateServiceScheduleFunc = (res) => {
    return {
        type: actionTypes.UPDATE_SCHEDULE,
        value: res
    }
}



const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
