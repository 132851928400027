import React from 'react';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./schedule.css"
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import {Container, Row, FormLabel, Card, Modal, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
var dayCode = ['Daily','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

class CalendarPage extends React.Component {
  constructor(props){
    super(props)
    this.state={
      error:'',
      success:'',
      loading:false,
      bookDateArr:[]
    }
  }

  /*setBookedSlotFunction = (resData)=>{
    let bookedDays = [];
    if(resData && resData.start_date_time && resData.end_date_time && resData.recurrence_list && dayCode){
      let stDate = resData.start_date_time?new Date(moment(resData.start_date_time)):'';
      let edDate = resData.end_date_time?new Date(moment(resData.end_date_time)):'';
      let recurrenceArr = resData.recurrence_list;
      let recRule = resData.recurrence_rule;

      for (let day = stDate; day <= edDate; day.setDate(day.getDate() + 1)) {
        if(recRule && recRule==='Weekly' && day){
          for (let code=1; code<=(dayCode.length-1); code++) {//--weak day Array 
            if(code===day.getDay() && recurrenceArr && recurrenceArr[dayCode[code]][0] && recurrenceArr[dayCode[code]][0] && recurrenceArr[dayCode[code]][0].start_time && recurrenceArr[dayCode[code]][0].end_time){
              bookedDays.push(moment(day).format("DD-MM-YYYY"));
            }
          }
        }else if(recRule && recRule==='Daily' && day){
          bookedDays.push(moment(day).format("DD-MM-YYYY"));
        }
      }
    }
    this.setState({ bookDateArr: bookedDays })
  }*/


  onToggleTooltip = () => {
    const { isTooltipOpen } = this.state;
    this.setState({ isTooltipOpen: !isTooltipOpen });
  };

  hideModals=(data)=> {
    this.setState({
      modal: false
    })
  }

  getDayValue= (date, dateId)=>{
    this.setState({
      modal: true
    })
  }

  renderDataToDays=(date)=>{ 
    if(this.state.bookDateArr.find(x=>x===moment(date).format("DD-MM-YYYY"))){
      let dayIndex = moment(date).format("DD_MM_YYYY");
      return (
        <div title="I am the tooltip text">One---</div>

        /*<div>
          <p><button id={'index_'+dayIndex}>Controlled Tooltip</button></p>
          <Tooltip delay={{ show: 50, hide: 50 }}
            isOpen={true} placement="right"
            target={'index_'+dayIndex} toggle={this.onToggleTooltip}
            // onClick={() => {}}
          >
            Discard draft
          </Tooltip>
        </div>*/
        )
    }
  }

  render() {
    const {bookDateArr} = this.state

    return (
      <div>
        <Calendar
          className="react-calendar"
          calendarType={"US"}         
          selectRange={true}
          tileClassName={({ date, view }) => {
            if(bookDateArr.find(x=>x===moment(date).format("DD-MM-YYYY"))){
              return 'highlight'
            }
          }} 
          tileContent={({ date, view }) => {
            if(bookDateArr.find((x)=>x===moment(date).format("DD-MM-YYYY"))){
              let html = `<div><div>5:00 - 6:00</div></div>`
              return (<>
                <p data-tip={html} data-html={true} data-border-color='white'>View</p>
                <ReactTooltip/></>
              )
            }
          }}
          /*onClickDay={(date)=>{
            if(bookDateArr.find(x=>x===moment(date).format("DD-MM-YYYY"))){
              this.getDayValue(date) 
            }
          }}
          tileContent={({ date, view }) => {
            if(bookDateArr.find((x)=>x===moment(date).format("DD-MM-YYYY"))){
              let dayIdx = moment(date).format("DD_MM_YYYY");
              return (<p onClick={(date)=>this.getDayValue(date, dayIdx)} id={dayIdx} title={dayIdx}>View</p>)
            }else{return null}
          }} */                         
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    
  }
}
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);