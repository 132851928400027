import Home from '../pages/Home'
import Videos from '../pages/Videos'
import Contact from '../pages/Contact'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import Profile from '../pages/Profile'
import VerifyEmail from '../pages/VerifyEmail'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import Service from '../pages/service/Service'
import AddService from '../pages/service/AddService'
import EditService from '../pages/service/EditService'
import ViewService from '../pages/service/ViewService'
import BlankService from '../pages/service/BlankService'
import EnrollService from '../pages/service/EnrollService'
import Invite from '../pages/notification/Invite'
import Notification from '../pages/notification/Notification'
import EnrollNotification from '../pages/notification/EnrollNotification'
import MyService from '../pages/service/MyService'
import SchedulePage from '../pages/schedule/SchedulePage'
import MyScheduleList from '../pages/schedule/MyScheduleList'
import AppointmentPage from '../pages/appointment/index'
import BookAppointment from '../pages/bookappointment/BookAppointment'

const Roles={
    'Common':[],
    'Guest':[
        {'url':'/','component':Home,'title':'Home','iconClassName':''}, 
        {'url':'/videos','component':Videos,'title':'Videos','iconClassName':''}, 
        {'url':'/contact','component':Contact,'title':'Contact','iconClassName':''}, 
        {'url':'/service','component':Service,'title':'Contact','iconClassName':''}, 
        {'url':'/my-appointment','component':Service,'title':'Contact','iconClassName':''}, 
        {'url':'/profile','component':Service,'title':'Contact','iconClassName':''}, 
        {'url':'/add-service','component':AddService,'title':'Contact','iconClassName':''}, 
        {'url':'/edit-service','component':EditService,'title':'Contact','iconClassName':''}, 
        {'url':'/view-service/:serviceId','component':ViewService,'title':'Contact','iconClassName':''},
        {'url':'/view-service/:serviceId/:appointmentId','component':ViewService,'title':'Contact','iconClassName':''},
        {'url':'/blank-service','component':BlankService,'title':'Contact','iconClassName':''},
        {'url':'/enroll-service','component':EnrollService,'title':'Contact','iconClassName':''}, 
        {'url':'/login','component':Login,'title':'Login','iconClassName':''}, 
        {'url':'/register','component':Signup,'title':'Register','iconClassName':''}, 
        {'url':'/forgot-password','component':ForgotPassword,'title':'ForgotPassword','iconClassName':''}, 
        {'url':'/reset-password','component':ResetPassword,'title':'ForgotPassword','iconClassName':''}, 
        {'url':'/verify-email','component':VerifyEmail,'title':'VerifyEmail','iconClassName':''}, 
        // {'url':'/profile','component':Profile,'title':'Profile','iconClassName':'fa fa-user'}, 
        {'url':'/invite-notification','component':Notification,'title':'Notification','iconClassName':'fa fa-user'}, 
        {'url':'/enroll-notification','component':EnrollNotification,'title':'Enroll Notification','iconClassName':'fa fa-user'}, 
        {'url':'/invite','component':Invite,'title':'Invite','iconClassName':''}, 
        {'url':'/my-service','component':MyService,'title':'Contact','iconClassName':''}, 
        {'url':'/schedule','component':SchedulePage,'title':'Schedule','iconClassName':''}, 
        {'url':'/my-schedule','component':MyScheduleList,'title':'My Schedules','iconClassName':''}, 
        {'url':'/appointment','component':AppointmentPage,'title':'Appointment','iconClassName':''}, 
        {'url':'/book-appointment','component':BookAppointment,'title':'Contact','iconClassName':''},
    ],
    'Admin':[
       // {'url':'/main/users','component':Users,'title':'Users','iconClassName':'fa fa-users'}, 
      
    ]
    
} 
export default Roles