import Api from '../Api'

export const createAppointment = (data) => {
    return Api.post('appointment-enroll/', data);
}

export const createAppointmentByProvider = async (data) => {
    return  Api.post('appointment-enroll/provider', data);
}

export const getAppoinmentServicesList = (id) => {
    return Api.get('appointment-enroll/' + id);
};

export const getMyAppoinmentListApi = () => {
    return Api.get('appointment-enroll/');
};

export const deleteAppoinment = (Id, data) => {
    return Api.post('appointment-enroll/delete/' + Id, data);
};

export const deleteAppoinmentByProvider = async (Id, data) => {
    return Api.post('appointment-enroll/provider/delete/' + Id, data);
};

export const deleteConfirmAppoinment = (data) => {
    return Api.post('appointment-action/delete-confirm', data);
};

export const addAppoinmentConfirm = (data) => {
    return Api.post('appointment-action/add-confirm', data);
}

export const suggetAlternative = (data) => {
    return Api.post('appointment-action/sugget-alternative', data);
}