import * as actionTypes from '../constant';
const INITIAL_STATE = {};

export const serviceReducer = (state = INITIAL_STATE, action) => {   
    switch (action.type) {
        case actionTypes.SERVICE_SCHEDULE: //--Service Schedule
            return {
                ...state,
                type: "response",
                scheduleRes: action.value,
                success: ''
            };
        case actionTypes.UPDATE_SCHEDULE:
            return {
                ...state,
                type: "update",
                scheduleRes: action.value,
                success: ''
            };
        case actionTypes.SCHEDULE_DELETE:
            return {
                ...state,
                success: 'Schedule deleted successfully'
            };
        case actionTypes.SERVICE_ENROLL_INVITED: //--Enroll Notification
            return {
                ...state,
                type: "enrollInvited",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_UN_ENROLL:
            return {
                ...state,
                type: "unEnroll",
                services: action.value,
                success: 'Un-enrolled service successfully'
            };
        case actionTypes.INVITE_NOTIFICATION:
            return {
                ...state,
                type: "notification",
                services: action.value,
                success: ''
            };
            case actionTypes.ALL_NOTIFICATION:
                return {
                    ...state,
                    type: "allnotification",
                    notificationData: action.value,
                    success: ''
                };
                case actionTypes.READ_NOTIFICATION:
                return {
                    ...state,
                    type: "readnotification",
                    readNotification: action.value,
                    success: ''
                };
        case actionTypes.ENROLL_NOTIFICATION:
            return {
                ...state,
                type: "notification",
                services: action.value,
                success: ''
            };
        case actionTypes.ACCCEPT_INVITATION:
            return {
                ...state,
                type: "acceptInvite",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_LIST:
            return {
                ...state,
                type: "list",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_MY_LIST:
            return {
                ...state,
                type: "myList",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_MY_ENROLL_LIST:
            return {
                ...state,
                type: "myEnrollList",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_CREATE:
            return {
                ...state,
                type: "create",
                service: action.value,
                success: 'Service created successfully'
            };
        case actionTypes.SERVICE_LIST_INVITE:
            return {
                ...state,
                type: "listInvite",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_CREATE_INVITE:
            return {
                ...state,
                type: "createInvite",                
                service: action.value,
                success: 'Service Invited successfully'
            };
        case actionTypes.SERVICE_UPDATE:
            return {
                ...state,
                type: "serviceUpdate",
                success: 'Service updated successfully'
            };
        case actionTypes.SERVICE_DELETE:
            return {
                ...state,
                success: 'Service deleted successfully'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                type: "",
                error: action.value,
                success: ''
            };
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                type: "",
                error: '',
                success: ''
            };
        case actionTypes.INVALID_EMAIL_ERROR:
            return {
                ...state,
                type: "",
                error: "Invalid User Name or Email",
                success: ""
            }
        default:
            return { ...state, error: '', success: '', type: '' };
    }
}
