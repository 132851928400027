import * as actionTypes from '../constant';

import { getUsers, createUser,updateUserByAdmin, getUserListingApi } from '../../services/UserServices';

export const userListAction = (type) => {
    return dispatch => {
        getUsers().then(res => {
            if (res.data !== undefined) {
                dispatch(getUserList(res.data.users));
                dispatch(clearError());
            }
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getUserList = (res) => {
    return {
        type: actionTypes.USER_LIST,
        value: res
    }
}

export const createUserAction = (user) => {
    return dispatch => {
        createUser(user).then(res => {
            if (res.data !== undefined) {
                dispatch(createUserPost(res.data.data));
            } 
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const createUserPost = (res) => {
    return {
        type: actionTypes.USER_CREATE,
        value: res
    }
}

export const updateUserAction = (userId, user) => {
    return dispatch => {
        updateUserByAdmin(userId, user).then(res => {
            if (res.data !== undefined) {
                dispatch(updateUserPut(res.data.data));
            }
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateUserPut = (res) => {
    return {
        type: actionTypes.USER_UPDATE,
        value: res
    }
}

//--
export const getUserListingAction = (type) => {
    return dispatch => {
        getUserListingApi().then(res => {
            if (res.data !== undefined) {
                dispatch(getUserListingFun(res.data));
            }
        }).catch(err => {            
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getUserListingFun = (res) => {
    return {
        type: actionTypes.USER_LISTING,
        value: res
    }
}


const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
