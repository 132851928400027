import * as actionTypes from '../constant';
const INITIAL_STATE = {};

export const serviceEnrollReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case actionTypes.SERVICE_ENROLL_LIST:
            return {
                ...state,
                type:"list",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_ENROLL_MY_LIST:
            return {
                ...state,
                type:"myList",
                services: action.value,
                success: ''
            };
        case actionTypes.SERVICE_ENROLL_CREATE:
            return {
                ...state,
                type:"create",
                service: action.value,
                success: 'Service created successfully'
            };
        case actionTypes.SERVICE_ENROLL_UPDATE:
            return {
                ...state,
                success: 'Service updated successfully'
            };
        case actionTypes.SERVICE_ENROLL_DELETE:
            return {
                ...state,
                success: 'Service updated successfully'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                type:"",
                error: action.value,
                success: ''
            };
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                type:"",
                error: '',
                success: ''
            };
        default:
            return { ...state, error: '', success: '', type: '' };
    }
}
