import React, { Component } from 'react'
import '../assets/css/login.css'
import Logo from '../assets/images/logo-bottomline.png'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {contactUsAction} from '../redux/actions/ContactUsActions';
import {NotificationContainer, NotificationManager} from 'react-notifications';

class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            success: '',
            loading: false,
            title: 'Contact'
        }
    }

    handleSubmit = (values, actions) => {
        contactUsAction(values);
		
        actions.setSubmitting(false);
        actions.resetForm();

        NotificationManager.success("Contact Form Submitted Successfully");
	}

    componentDidMount() {
        document.title = 'Kunneckt | Contact'
        this.setState({ title: 'Contact' })
    }
    

    render() {

        return (
            <div className="login_page">
                <table className="highlight-line"></table>
                <table className="logo-center text-center">
                    <tbody>
                        <tr><td><img src={Logo} alt="" height="200px" /></td></tr>
                    </tbody>
                </table>
                <div id="rcornersbox">
                    <div className="login-prompt-text">Contact Us</div>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            message: '',
                        }}
                        
                        validate={values => {
                            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                            const errors = {};
                                if (!values.name) { errors.name = 'Name Required' }
                                if (!values.email || !emailRegex.test(values.email)) { errors.email = 'Valid Email Required' }
                                if (!values.message) { errors.message = 'Message Required' }
                            return errors;
                        }}

                        onSubmit={this.handleSubmit}
                    >
                        {() => (
                            <Form name="contact">
                                <div className="input-container" htmlFor="name" ></div>
                                <div className="d-flex">
                                    <i className="fa fa-user icon"></i>
                                    <Field className="input-field" placeholder='Name' name="name" />
                                </div>
                                <div className="text-danger">
                                    <ErrorMessage name="name" className='help-block errors' class="text-danger"/>
                                </div>
                                    

                                <div className="input-container" htmlFor="email" ></div>
                                <div className="d-flex">
                                    <i className="fa fa-envelope icon"></i>
                                    <Field className="input-field" placeholder='Email' name="email" />
                                </div>
                                <div className="text-danger">
                                    <ErrorMessage name="email" />
                                </div>

                                <div className="input-container" htmlFor="message" ></div>
                                <div className="d-flex">
                                    <i className="fa fa-comment icon"></i>
                                    <Field className="input-field" placeholder='Message' name="message" component="textarea" style={{ "height": "110px" }} />
                                </div>
                                <div className="text-danger">
                                    <ErrorMessage name="message" />
                                </div>

                                <div className="input-container"></div>
                                <button type="submit" style={{color: '#212529', backgroundColor: '#0E76BC'}} className="btn">Submit</button>

                            </Form>

                        )}
                    </Formik>
                </div>

            </div >
        )
    }
}

export default Contact