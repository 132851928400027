import React from 'react';
import {getTimezoneAction} from '../../redux/actions/AuthActions'
import { connect } from 'react-redux';

class CommonContainerPage extends React.Component {
  constructor(props){
    super(props)
    this.state={
    }
  }

  componentDidMount() {
    this.props.getTimezoneAction();
  }

  render() {
  	return (<div></div>)
  }
}

const mapStateToProps = state => {
  return {
    error: state.authReducer.error,
    success: state.authReducer.success,
    authReducer: state.authReducer,
  }
}
const mapDispatchToProps = {
  getTimezoneAction: getTimezoneAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(CommonContainerPage);
