import { EventEmitter } from 'events';

class LoaderManager extends EventEmitter {
  constructor() {
    super();
    this.type = false;
  }

  show() {
    this.type=true;
    this.emit('loaderHideShow', this.type);
  }
  hide() {
    this.type=false;
    this.emit('loaderHideShow', this.type);
  }

  addChangeListener(callback) {
    this.addListener('loaderHideShow', callback);
  }

}

export default new LoaderManager();
