import React from 'react';
import '../assets/css/main.css'
import hardWorkImage from '../assets/images/img-hard-work.jpg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const  Home=()=>{
    document.title='Kunneckt'
    let Imges=['1','2',3,4,5,6,7,8,9,10,11,12]
    /*const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            variableWidth: true,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            lazyLoad: 'ondemand',
            cssEase: 'ease'
      };*/

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 2,
        //slidesToScroll: 2,
        initialSlide: 0,
        variableWidth: true,
        autoplay: true,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }],
    };

    return (
   <React.Fragment>
        <div id="carousel_table" className="table1">
        <Slider {...settings}>
            {Imges.map(img=>{
                return(
                    <div key={img}><img src={require(`../assets/images/animate/${img}.jpg`)} alt="slider"/></div>
                )
            })}
        </Slider>
    </div>
        
  
    <table className="highlight-line"></table>
     <table className="table2">
      <tbody>
        <tr>
            <td>
            <br/>
            <span className="intro-text-1">Kunneckt is a customer management app specifically built for small service providers.</span><br/><br/>
            <span className="intro-text-2">Manage your services, know your customers, communicate with them and handle your appointments, all from within a simple and easy-to-use application.<br/>
            </span><br/><br/>
        </td></tr>
        </tbody>
    </table>
    
   
    <table className="table1">
    <tbody>
        <tr>
            <td align="Left" width="500px"><img className="img-homeper-trainer" src={hardWorkImage} alt="Personal Trainer"/></td>
            <td align="Left" bgcolor="#FFFFFF" className="intro-text-3">
                At Kunneckt, we understand that running a small services business is hard work.
                Allow us to help you run it successfully. Leave the time-consuming management tasks
                to Kunneckt and use your precious time to make the most out of your business.</td>
        </tr>
        </tbody>
    </table>
    
  
    
    <table className="table2">
    <tbody>
        <tr><td>
            <br/>
            <span className="intro-text-1">How can Kunneckt help?</span>
            <br/><br/>
        </td></tr>
        </tbody>
    </table>
    
  
    
    <table className="table1">
    <tbody>
        <tr><td align="Left" bgcolor="#FFFFFF" className="intro-text-3">
                    <br/>
                    Track all your customers and their contacts in one place<br/><br/>
                    Communicate with them seamlessly from one app<br/><br/>
                    Manage your appointments, cancellations and reschedules without stress<br/><br/>
                    Handle multiple services and schedules without mixing them up<br/><br/>
                    Get analytics reports and customized insights to grow your business<br/><br/>
        </td></tr></tbody>
    </table>
    
   
    
    <table className="table3">
    <tbody>
        <tr><td>
            <br/>
            <span className="intro-text-1">... and so much more!</span>
            <br/><br/>
        </td></tr>
        </tbody>
    </table>

    
    <table className="highlight-line">
        
    </table>
   </React.Fragment>
    )  
}

export default Home