import React from 'react';


class BlankService extends React.Component {
    constructor(props){
        super(props)
    }
    componentDidMount() {
        let data = this.props.location.state.data;
        this.props.history.push({
            pathname: "/view-service",
            state: {data : data}
        });
    }

    render() {
        return (<> </>)
    }
}

export default (BlankService);