import * as actionTypes from '../constant';
import { login,userForgotPassword,sendNewPassword,saveUser, updateProfile, 
	addressValidate, getTimezoneApi } from '../../services/authServices';
import {getAllNotifications} from '../../services/Services';
import { appConstants } from "../../_Constants/app.constants"
export const loginUser = (values) => {
	return dispatch => {
		login(values).then(res => {
			if(res.data!==undefined && res.data.Authorization!==undefined){
				if(res.data && res.data.profile){
					localStorage.setItem('firstName', res.data.profile.firstName);
					localStorage.setItem('lastName', res.data.profile.lastName);
					localStorage.setItem('username', res.data.profile.username);
					localStorage.setItem('userId', res.data.profile.id);
					localStorage.setItem('profile', JSON.stringify(res.data.profile));
				}
				localStorage.setItem('token', res.data.Authorization);
				if (localStorage.getItem('userId')) {
					let userId = localStorage.getItem('userId')
					var send_data = { "user_id": userId };
					setTimeout(function () {
					  appConstants.socket.emit('login', send_data);
					}, 1000);
			  
				  }
				dispatch(getAllNotificationAction(res.data.profile.id));
				dispatch(success(res.data));
			}else{
				dispatch(error("Please try again."));
			}
		}).catch(err => {
			if(err && err.data){
				dispatch(error(err.data.message));
			}else{
				dispatch(error(err));
			}
		});
	}
}

export const signup=(values)=>{
	return dispatch => {
		saveUser(values).then(res => {
				dispatch(signupSuccess(true))
		}).catch(err => {
			if(err.status!==undefined && err.status===404){
				dispatch(signupError(err.statusText));
			}else if(err.status===409){
				dispatch(signupError(err.data.message));
			}
		});
	}
}

export const updateProfileUser=(values)=>{
	return dispatch => {
		updateProfile(values).then(res => {
			dispatch(updateSuccess(res.data))
		}).catch(err => {
			if(err.status!==undefined && err.status===404){
				dispatch(signupError(err.statusText));
			}else if(err.status===409){
				dispatch(signupError(err.data.message));
			}
		});
	}
}

export const valideAddressAction=(values)=>{
	return dispatch => {
		addressValidate(values).then(res => {
			dispatch(addressSuccess(res.data))
		}).catch(err => {
			if(err &&err.status!==undefined && err.status===404){
				dispatch(addressError(err.statusText));
			}else if(err.status===409){
				dispatch(addressError(err.data.message));
			}
		});
	}
}


export const forgotPassword = (values) => {
	return dispatch => {
		userForgotPassword(values).then(res => {
			dispatch({
				type: actionTypes.FORGOT_PASSWORD,
				value: values.email
			})
		}).catch(err => {
			dispatch(error(err.message));
		});
	}
}

export const setNewPassword = (values) => {
	return dispatch => {
		sendNewPassword(values).then(res => {
			dispatch({
				type: actionTypes.SET_PASSWORD,
				value: true
			})
		}).catch(err => {
			dispatch(error(err.message));
		});
	}
}

const success = (res) => {
	return {
		type: actionTypes.LOGIN,
		value: res.message,
		data : res.profile
	}
}

const error = (err) => {
	return {
		type: actionTypes.LOG_ERROR,
		value: err
	}
}

const signupError = (err) => {
	return {
		type: actionTypes.SIGNUP_ERROR,
		value: err
	}
}
const signupSuccess = (res) => {
	return {
		type: actionTypes.SIGNUP_SUCCESS,
		value: res
	}
}

const updateSuccess = (res) => {
	return {
		type: actionTypes.UPDATE_SUCCESS,
		value: res.data
	}
}

const addressSuccess = (res) => {
	return {
		type: actionTypes.ADDRESS_ERROR,
		value: res
	}
}
const addressError = (err) => {
	return {
		type: actionTypes.ADDRESS_SUCCESS,
		value: err
	}
}

export const resetStatus=(res)=>{
	return {
		type: actionTypes.RESET_STATUS,
		value: res
	}
}



export const getTimezoneAction=(values)=>{
	return dispatch => {
		getTimezoneApi(values).then(res => {
			dispatch(getTimezoneFunct(res.data))
		}).catch(err => {
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
		});
	}
}
const getTimezoneFunct = (res) => {
    return {
        type: actionTypes.GET_TIMEZONE,
        value: res
	}
	

}

export const getAllNotificationAction = (type) => {
	return dispatch => {
		getAllNotifications(type).then(res => {
			if (res.data !== undefined) {
				let count = 0
				for(let i in res.data ){
				  if(res.data[i].read_status == 'unread'){
					count = count + 1;
				  }
				}
				localStorage.setItem('badgeCount', count);
				dispatch(allNotificationFunc(res.data));
				//dispatch(clearError());
			}
		}).catch(err => {
			let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
			dispatch(error(errormsg));
		});
	}
}
const allNotificationFunc = (res) => {

	return {
		type: actionTypes.ALL_NOTIFICATION,
		value: res
	}
}