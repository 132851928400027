import React from "react";
import { connect } from "react-redux";
import { getServiceScheduleAction } from "../../redux/actions/ServiceActions";

import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
  RecurrenceEditorComponent,
} from "@syncfusion/ej2-react-schedule";
import {
  extend,
  createElement,
  closest,
  removeClass,
} from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import { isBrowser, isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import defaultImg from "../../assets/images/default.png";
import {
  getAppointmentList,
  createAppointmentActionByProvider,
  deleteAppoinmentByProviderAction,
} from "../../redux/actions/AppointmentActions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  FormLabel,
  InputGroup,
  FormControl,
  Form,
  Button,
} from "react-bootstrap";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import $ from "jquery";
import image4 from "../../assets/images/image4.png";
import image2 from "../../assets/images/image2.png";
import image1 from "../../assets/images/image1.png";
import image8 from "../../assets/images/image8.png";
import image9 from "../../assets/images/image9.png";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import { ChildCountAction, ParentCountAction } from "../../redux/actions/CountActions";
import { getAllNotificationAction } from "../../redux/actions/AuthActions";
import { serviceIdReducer } from "../../redux/reducers/ServiceIdReducer";
import { ServiceIdAction } from "../../redux/actions/ServiceIdActions";

class CommomAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      loading: false,
      serviceDetail: {},
      timeSloat: ["5 min", "30 min", "1 hrs"],
      selectedTimeSloat: 1,
      selectedInterval: 60,
      timeSloats: "1 hrs",
      selectedDate: null,
      slotsNotAvailableModal: false,
      createStatus: false,
      deleteStatus: false,
      scheduleData: [],
      other_users_time: [],
      appoinmentObj: {},
      userObj: {},
      enrolled_users: [...this.props.enrolled_users],
      showScheduleObj: {},
      showSchedule: true,
      deleteConfirmaProcessRequestModel: false,
      appoinmentId: "",
      serviceScheduleData: [],
      serviceScheduleDataLoading: true,
      serviceId: this.props?.match?.params?.serviceId || "",
      selectOption: "",
      dataArray: [],
      myState: 0,
      slotColModalHeading: 'Service not available'
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/login");
    } else {
      this.setState({ serviceDetail: this.props.location.state.data });
      let duration = 60;
      if (this.props.location.state.data.duration) {
        duration = this.props.location.state.data.duration;
      }
      this.setState({ selectedInterval: duration });
      //this.setState({ enrolled_users: this.props.enrolled_users });
      this.props.getServiceScheduleAction(
        `?service_id=${this.state.serviceId}`
      );

      if (this.props.selectedDate) {
        this.setState({
          selectedDate: this.props.selectedDate,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { state } = this.props?.location;
    if(state?.data && state?.data.id !== this.props.serviceId.id) this.props.ServiceIdAction(state?.data?.id)
    const data = state?.data || this.props.serviceId;
    if (
      nextProps.userDetail &&
      nextProps.userDetail.users &&
      nextProps.userDetail.type === "userList"
    ) {
      let userObj = {};
      nextProps.userDetail.users.forEach((item) => {
        userObj[item.id] = item;
      });
      const user_profile = JSON.parse(localStorage.getItem("profile"));
      userObj[user_profile.id] = user_profile;
      this.setState({
        userObj: userObj,
      });
      this.show_schedule("user");
    }

    if (
      nextProps.serviceData &&
      nextProps.serviceData.scheduleRes &&
      nextProps.serviceData.type == "response"
    ) {
      let schedule = nextProps.serviceData.scheduleRes.data;
      for (let i in schedule) {
        if (schedule[i].recurrence_rule == "None") {
          schedule[i].recurrence_rule = "Daily";
        }
      }
      
      this.setState({ serviceScheduleData: schedule });

      if (data?.id || "") this.props.getAppointmentList(data.id);

      this.show_schedule("schedule");
      let _this = this
      setTimeout(() => {
        _this.setState({ serviceScheduleDataLoading: false });
      }, 500)
    } 

    if (nextProps.appointmentData && nextProps.appointmentData.appoinment) {
      this.setState({ last_appoinment: nextProps.appointmentData.appoinment });
      this.reset_appointment(nextProps.appointmentData.appoinment);
    }

    if (nextProps.appointmentData && this.state.deleteStatus) {
      this.setState({
        deleteStatus: false,
      });

      if (data?.id || "") this.props.getAppointmentList(data.id);
    }

    if (
      nextProps.appointmentData &&
      nextProps.appointmentData.service &&
      this.state.createStatus
    ) {
      this.setState({
        createStatus: false,
      });
      if (
        nextProps.appointmentData.service.message ===
        "Appointment enrolled Successfully." &&
        nextProps.appointmentData.service.myreccc === true
      ) {
        NotificationManager.success("Appointment Series Created");
        this.props.getAppointmentList(data.id);
      } else if (
        nextProps.appointmentData.service.message ===
        "Appointment enrolled Successfully."
      ) {
        NotificationManager.success("Appointment Created");
        this.props.getAppointmentList(data.id);
        this.props.getAllNotificationAction()
        //Appointment Rescheduled
      } else if (
        nextProps.appointmentData.service.message ===
        "Appointment Updated Successfully."
      ) {
        NotificationManager.success("Appointment Rescheduled");
        this.props.getAppointmentList(data.id);
      } else if (nextProps.appointmentData.service.message === "Unsuccess") {
        NotificationManager.error("Appointment Slot Already Booked");
        this.props.getAppointmentList(data.id);
      } else {
        NotificationManager.error("Appointment Slot not available");
      }
    }

    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  show_schedule(key) {
    let showScheduleObj = this.state.showScheduleObj;
    showScheduleObj[key] = true;
    this.setState({ showScheduleObj });

    this.setState({ showSchedule: false });

    let _this = this;
    _this.setState({ showSchedule: true });
  }

  reset_appointment(appointmentData) {
    let appoinmentObj = {};
    let appointmentScheduleData = [];
    if (appointmentData.length > 0) {
      for (let i in appointmentData) {
        let startdate = appointmentData[i].start_date;
        let enddate = appointmentData[i].end_date;
        let full_name = "";
        for (const i in this.state.enrolled_users) {
          if (this.state.enrolled_users[i].id === appointmentData[i]?.userId) {
            full_name =
              this.state.enrolled_users[i].firstName +
              " " +
              this.props.enrolled_users[i].lastName;
          }
        }

        let data = {
          Id: appointmentData[i].id,
          id: appointmentData[i].id,
          Subject: appointmentData[i].subject
            ? appointmentData[i].subject
            : full_name,
          RecurrenceRule: appointmentData[i].recurrence_rule
            ? appointmentData[i].recurrence_rule
            : "",
          StartTime: new Date(startdate),
          EndTime: new Date(enddate),
          userId: appointmentData[i].userId,
          ...appointmentData[i],
          // providerApproved: true,
        };
        appointmentScheduleData.push(data);
        appoinmentObj[appointmentData[i].id] = appointmentData[i];
      }
    }
    this.setState({
      scheduleData: [...appointmentScheduleData],
      appoinmentObj: appoinmentObj,
    });
    this.show_schedule("appoinment");
    if (document.querySelector(".e-schedule")) {
      let scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
      scheduleObj.eventSettings.dataSource = this.state.scheduleData;
    }
  }

  handleChange = (e) => {
    let time = e.target.value;
    let interval = 60;
    let timeslot = 1;
    if (time == "5 min") {
      timeslot = 12;
      interval = 5;
    } else if (time == "30 min") {
      timeslot = 2;
      interval = 30;
    } else {
      timeslot = 1;
      interval = 60;
    }

    this.setState({
      selectedTimeSloat: timeslot,
      timeSloats: time,
      selectedInterval: interval,
    });
  };

  check_date_exist(startDate, RecurrenceRule, recurrenceID = "", args) {
    var isNotExist = true;
    if (new Date(startDate) <= new Date()) {
      args.cancel = true;
      isNotExist = false;
      this.setState({
        slotsNotAvailableModal: true,
        message: "Your appointment(s) cannot be completed due to past date.",
        dataArray: [],
      });
      return false;
    }
    let dates = this.recObject.getRecurrenceDates(startDate, RecurrenceRule);
    let allScheduleIds = [];
    var cancel_type = "";
    var cancel_date = null;
    var dateArray = {};
    let data = args.data instanceof Array ? args.data[0] : args.data;
    let scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    delete data["FollowingID"];
    delete data["Guid"];
    delete data["RecurrenceException"];
    //delete data['RecurrenceID'];
    if (
      RecurrenceRule ||
      (!RecurrenceRule && !scheduleObj.isSlotAvailable(data))
    ) {
      
      for (var k in dates) {
        var startDate1 = new Date(dates[k]);
        var endDate1 = new Date(dates[k]);
        endDate1.setMinutes(
          endDate1.getMinutes() + parseInt(this.state.selectedInterval)
        );

        var startDate2 = new Date(dates[k]);
        startDate2.setMinutes(0);
        var endDate2 = new Date(dates[k]);
        endDate2.setMinutes(60);
        const availablity1 = scheduleObj.getOccurrencesByRange(
          startDate1,
          endDate1
        );
        
        if (availablity1.length) {
          if (recurrenceID && availablity1[0].RecurrenceID == recurrenceID) {
            continue;
          }
          // if(new Date(availablity1[0].start_time) == new Date(startDate1)){
          var recurrenceTypes =
            availablity1[0].RecurrenceRule.match(/(FREQ=)(.*?)[;]/g);
          var recurrenceType = recurrenceTypes[0]
            .replace("FREQ=", "")
            .replace(";", "");
          isNotExist = false;
          cancel_type = "booked_slot";
          cancel_date = startDate1;
          dateArray[availablity1[0].RecurrenceID] = {
            cancel_type: "booked_slot",
            cancel_date: startDate1,
            end_date: endDate1,
            recurrenceType: recurrenceType,
          };
          // }
        } else {
          var [scheduleExist, scheduleIds] =
            this.checkScheduleExist(startDate1);
          if (!scheduleExist) {
            isNotExist = false;
            cancel_type = "unavailable_slot";
            cancel_date = startDate1;
            dateArray["unavailable_slot"] = {
              cancel_type: "unavailable_slot",
              cancel_date: startDate1,
              end_date: new Date(this.state.serviceScheduleData[0].end_date_time),
              recurrenceType: "No",
            };
            break;
          } else {
            allScheduleIds = allScheduleIds.concat(scheduleIds);
          }
        }
      }
    }
    else {
      if (!scheduleObj.isSlotAvailable(data)) {
        isNotExist = false;
      } else {
        [isNotExist, scheduleIds] = this.checkScheduleExist(startDate);
      }
    }
    allScheduleIds = [...new Set(allScheduleIds)];
    if (!isNotExist && startDate > new Date() && !RecurrenceRule) {
      this.setState({
        slotsNotAvailableModal: true,
        message: `Your appointment cannot be created as no slot exist on ${moment(
          startDate
        ).format("MMM Do YYYY")}.`,
        dataArray: [],
        slotColModalHeading: "Service not available"
      });
    } else if (!isNotExist) {
      this.setState({
        slotsNotAvailableModal: true,
        // message:
        //   "Your appointment(s) cannot be completed due to the conflicts listed below",
        message: `Your appointment(s) cannot be created as no slot exists on ${moment(dateArray.unavailable_slot.cancel_date).format("MMM Do YYYY")}.`,
        dataArray: Object.values(dateArray),
        slotColModalHeading: 'Service not available'
      });
    }

    let last_date = dates.pop();
    last_date = new Date(last_date);

    return [isNotExist, allScheduleIds, last_date, dates.length];
  }

  onActionBegin1 = (args) => {
    let scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.showSpinner();
    let startDate = "";
    let recurrenceRule = "";
    if (args?.data && args.data.length) {
      if (args.data.length) {
        args.data[0].service_id = this.state.serviceId;
      } else {
        args.data.service_id = this.state.serviceId;
      }
    }
    switch (args.requestType) {
      case "eventChange":
        startDate = args.changedRecords[0].StartTime;
        recurrenceRule = args.changedRecords[0].RecurrenceRule;
        // console.log("Event Change", startDate);
        args.data.providerApproved = false;
        args.changedRecords[0]['providerApproved'] = false;
        break;
      case "eventCreate":
        startDate = args.addedRecords[0].StartTime;
        recurrenceRule = args.data[0]?.RecurrenceRule;
        // console.log("Event Create", args.addedRecords, startDate);
        break;
    }
    if (startDate) {

      var [isNotExist, scheduleIds, lastDate, scheduleCount] =
        this.check_date_exist(
          startDate,
          recurrenceRule ? recurrenceRule : "",
          "",
          args
        );
      if (!isNotExist) {
        args.cancel = true;
      }
    }

    switch (args.requestType) {
      case "eventChange":
        if (args.changedRecords[0].userId === null) {
          return false;
        }
        if (args.cancel) {
          return false;
        }
        args.data.providerApproved = false;
        args.changedRecords[0]["providerApproved"] = false;
        startDate = args.changedRecords[0].StartTime;
        recurrenceRule = args.changedRecords[0].RecurrenceRule;
        this.editAppointment(args);

        break;
      case "eventCreate":
        if (args.cancel) {
          return false;
        }
        this.createAppointment(args);
        startDate = args.addedRecords[0].StartTime;
        recurrenceRule = args.addedRecords[0].RecurrenceRule;
        break;
      case "eventRemove":
        this.deleteAppointment(args);
        break;
    }
  };

  checkScheduleExist(arg_date) {
    var scheduleExist;
    var scheduleData = this.state.serviceScheduleData;
    var scheduleIds = [];
    if (scheduleData && scheduleData.length > 0) {
      for (let i in scheduleData) {
        var startDate = scheduleData[i].start_date_time;
        var endDate = scheduleData[i].end_date_time;
        startDate = moment(startDate).format("YYYY-MM-DD");
        endDate = moment(endDate).format("YYYY-MM-DD");
        var cellDate = moment(arg_date).format("YYYY-MM-DD");
        var checkDateIsExist = moment(cellDate).isBetween(
          startDate,
          endDate,
          undefined,
          "[]"
        );
        if (checkDateIsExist) {
          // if (scheduleData[i].recurrence_rule == 'Daily') {
          var startTime = moment(
            scheduleData[i].recurrence_list.start_time,
            "h:mma"
          );
          var endTime = moment(
            scheduleData[i].recurrence_list.end_time,
            "h:mma"
          );
          var cellTime = moment(arg_date, "h:mma");
          if (arg_date) {
            var start_time_h = startTime.format("HH");
            var start_time_m = startTime.format("mm");

            var endTime_h = endTime.format("HH");
            var endTime_m = endTime.format("mm");

            startTime = moment(cellDate).set({
              hour: start_time_h,
              minute: start_time_m,
            });
            endTime = moment(cellDate).set({
              hour: endTime_h,
              minute: endTime_m,
            });
          }
          var checkTimeIsExist = cellTime.isBetween(
            startTime,
            endTime,
            null,
            "[]"
          );
          if (
            scheduleData[i].recurrence_rule == "Daily" ||
            scheduleData[i].recurrence_rule == "None"
          ) {
            if (checkTimeIsExist) {
              scheduleExist = true;
              scheduleIds.push(scheduleData[i].id);
            }
          }
          if (scheduleData[i].recurrence_rule == "Weekly") {
            var cellDay = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDay = cellDay.format("dddd");
            if (
              scheduleData[i].recurrence_list &&
              scheduleData[i].recurrence_list.data &&
              scheduleData[i].recurrence_list.data.length > 0
            ) {
              var scheduleDayArray = scheduleData[i].recurrence_list.data;
              for (let j in scheduleDayArray) {
                if (scheduleDayArray[j] === cellDay) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                    scheduleIds.push(scheduleData[i].id);
                  }
                }
              }
            }
          }

          if (scheduleData[i].recurrence_rule == "Monthly") {
            var cellDate = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDate = cellDate.format("DD");
            if (
              scheduleData[i].recurrence_list &&
              scheduleData[i].recurrence_list.data &&
              scheduleData[i].recurrence_list.data.length > 0
            ) {
              var scheduleDateArray = scheduleData[i].recurrence_list.data;
              for (let k in scheduleDateArray) {
                if (scheduleDateArray[k] == 1) {
                  scheduleDateArray[k] = "01";
                }
                if (scheduleDateArray[k] == 2) {
                  scheduleDateArray[k] = "02";
                }
                if (scheduleDateArray[k] == 3) {
                  scheduleDateArray[k] = "03";
                }
                if (scheduleDateArray[k] == 4) {
                  scheduleDateArray[k] = "04";
                }
                if (scheduleDateArray[k] == 5) {
                  scheduleDateArray[k] = "05";
                }
                if (scheduleDateArray[k] == 6) {
                  scheduleDateArray[k] = "06";
                }
                if (scheduleDateArray[k] == 7) {
                  scheduleDateArray[k] = "07";
                }
                if (scheduleDateArray[k] == 8) {
                  scheduleDateArray[k] = "08";
                }
                if (scheduleDateArray[k] == 9) {
                  scheduleDateArray[k] = "09";
                }
                if (scheduleDateArray[k] === cellDate) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                    scheduleIds.push(scheduleData[i].id);
                  }
                }
              }
            }
          }
        }
      }
    }
    return [scheduleExist, scheduleIds];
  }
  onDataBound(e) {
    let event = this.scheduleObj.getCurrentViewEvents();
    event = event.filter((data) => new Date(data.StartTime) >= Date.now())
    if (this.props.count !== event.length) this.props.ParentCountAction(event.length);
    this.state.myState = 0;
  }
  onRenderCell = async (args) => {
    let scheduleExist = false;
    let scheduleIds = [];
    if (args.date >= new Date()) {
      [scheduleExist, scheduleIds] = this.checkScheduleExist(args.date);
      if (scheduleExist && !args.element.classList.contains("e-time-cells") && !args.element.classList.contains("e-all-day-cells") && !args.element.classList.contains("e-header-cells") && !args.element.classList.contains("e-all-day-appointment-wrapper")) {
        this.state.myState++
        this.props.myState1Fun(this.state.myState)
      }
      if (!scheduleExist) {
        args.element.classList.remove("e-work-cells");
        args.element.classList.add("no-work-cells");
        args.cancel = true;
      }
    }

    if (args.date <= new Date()) {
      [scheduleExist, scheduleIds] = this.checkScheduleExist(args.date);
      if (scheduleExist) {
        args.element.classList.remove("e-work-cells");
      }
      args.element.classList.add("no-work-cells");
      args.cancel = false;
    }
  };

  onEventRendered(args) {
    setTimeout(() => {
      $('.providerApprovedChild').parents('.e-appointment').addClass('providerApproved');
    }, 1000);
    const appointmentId = this.props?.match?.params?.appointmentId || 0;
    if (appointmentId) {
      const final1String = `Appointment_${appointmentId}_${moment(
        this?.props?.location?.state?.appoinmentDetails.startTime
      ).unix()}`;
      const final2String = `Appointment_${args?.data?.id}_${moment(
        args?.data?.startTime
      ).unix()}`;
      if (final1String === final2String && this.state.selectOption === "") {
        setTimeout(() => {
          $(`div[data-appoinment="${final1String}"]`).trigger("click");
        }, 1000);
      }
    }
  }

  hideModals(args) {
    this.setState({ slotsNotAvailableModal: false, slotColModalHeading: 'Slot Collision Report'  });
  }

  showCalendars() {
    this.setState({ hideShowCalendars: true });
    scroller.scrollTo("appointment_module", {
      duration: 1500,
      delay: 100,
      smooth: true,
    });
  }

  onDragStop(args) {
    let scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];

    scheduleObj.showSpinner();
  }

  onPopupOpen(args) {
    let scheduleExist = false;
    let scheduleIds = [];
    [scheduleExist, scheduleIds] = this.checkScheduleExist(args.data.StartTime);
    if (args.data.EndTime <= new Date() || !scheduleExist) {
      args.element.getElementsByClassName(
        "e-header-icon-wrapper"
      )[0].style.display = "none";
    }

    if (!args.data.EndTime <= new Date()) {
      if (moment(args.data.startTime).unix() < moment().unix()) {
        $(".e-header-icon-wrapper").remove();
        if (args.type === "Editor") {
          args.data.IsDone = true;
          args.cancel = true;
        }
      }
      $(".e-event-popup").addClass("customHeader");
      $(".e-header-icon-wrapper .e-close").hide();
      $(".e-title-location-row").hide();
      $(".e-description-row").hide();
      $(".e-footer-content .e-event-delete").hide();
      $(".e-popup-header .e-subject.e-text-ellipsis").html(
        $(".titleUserName").html()
      );
      $(".titleUserName").hide();

      if (args.type === "QuickInfo") {
        if (args.target.classList.contains("e-work-cells")) {
          let dialogObj = args.element.ej2_instances[0];
          dialogObj.hide();
          let currentAction = args.target.classList.contains("e-work-cells")
            ? "Add"
            : "Save";
          this.scheduleObj.openEditor(args.data, currentAction);
        }

        let html = $(".e-popup-content .e-date-time").html();
        $(".customTimeData").remove();
        $("#QuickDialog").append(`<span class='customTimeData'>${html}</span>`);
        $(".customTimeData").hide();
        let formElement = args.element.querySelector(".e-schedule-form");

        if (formElement?.firstChild || 0) {
          $(".e-subject").hide();
          this.addFieldDropDown(args);
        }
      } else if (args.type === "DeleteAlert") {
        if (args?.data?.recurrence) {
          $(".e-quick-alertcancel")
            .removeClass("e-quick-dialog-cancel")
            .addClass("e-quick-dialog-series-event");
          $(".e-quick-dialog-series-event")
            .text("Delete Series")
            .show()
            .attr("disabled", true);
        }
        $(".e-quick-dialog-series-event")
          .attr("disabled", true)
          .removeClass("e-primary");
        $(".e-quick-alertok")
          .attr("disabled", true)
          .removeClass("e-primary")
          .show();
        $(".e-quick-dialog-cancel").hide();
        $(".delete-field-row").show();
        $("#QuickDialog_dialog-content").text(
          "Are you sure you want to delete?"
        );
        $("input[name='service_id']").val(this.state.serviceId);
        $("input[name='userId']").val(localStorage.getItem("userId"));

        this.editorDeletePopup(args);
      } else if (args.type === "Editor") {
        if (args?.data?.appointment_id) {
          $(".e-recurrenceeditor").hide();
        } else {
          $(".e-recurrenceeditor").show();
        }
        $(".e-event-cancel").hide();
        $(".delete-field-row").hide();
        $("#QuickDialog_dialog-content").text(
          "How would you like to change the appointment in the series?"
        );

        args.data = JSON.parse(JSON.stringify(args.data));
        this.addFieldDropDown(args);
      } else if (args.type === "RecurrenceValidationAlert") {
        let textData =
          "Do you want to cancel the changes made to specific instances of this series and match it to the whole series again?";
        if (
          $(".e-quick-alertcancel").length &&
          $("#QuickDialog_dialog-content").text() === textData
        ) {
          $(".e-quick-alertcancel").trigger("click");
          args.data.IsDone = true;
          args.cancel = true;
        }
      }
    }
  }

  editorDeletePopup(args) {
    const userObj = this.state.userObj[args?.data?.userId || args?.data?.userId];
    if (args.element.querySelector(".user_image")) {
      args.element.querySelector(".user_image").remove();
    }
    if (userObj.profile) {
      let doctor_logo = userObj?.profile.replace(/ /g, '%20')
      $(".e-dlg-header").text('')
      $(".e-dlg-header").append(
        `<img src=${doctor_logo} class='user_image' />`
      );
      $(".e-dlg-header").append(
        `<div class="e-title-text">Delete Event</div>`
      );
    }
    $("#QuickDialog_dialog-content").text("Are you sure you want to delete?");
    $("#QuickDialog_dialog-content").prepend(
      '<div><input class="deletePopUpInput" id="deleteDescription" maxlength="100" placeholder="Reason For Deletion" type="text" name="" /><p class="max_char">Maximum 100 characters</p></div>'
    );
    $(".delete-field-row").remove();
    let row = createElement("div", { className: "delete-field-row" });
    let formElement = args.element.querySelector(".e-dlg-content");
    formElement.parentElement.insertBefore(row, formElement);
    let container = createElement("div", {
      className: "custom-field-container",
    });

    if (!args?.data?.recurrence) {
      $(".e-quick-dialog-cancel").hide();
      $(".e-quick-alertcancel").hide();
    }

    if (args?.data?.recurrence) {
      $(".e-quick-alertcancel")
        .removeClass("e-quick-dialog-cancel")
        .addClass("e-quick-dialog-series-event");
      $(".e-quick-dialog-series-event")
        .text("delete Series")
        .show()
        .attr("disabled", true);
    }
    let checkboxEle = createElement("input", {
      className: "e-field",
      attrs: {
        id: "editSeries",
        name: "editSeries",
        value: "true",
        type: "checkbox",
      },
    });
    checkboxEle.type = "checkbox";
    $("#QuickDialog_dialog-content").append(checkboxEle);
    checkboxEle.setAttribute("name", "editSeries");
    checkboxEle.addEventListener("click", function handleClick(event) {
      if (event.target.checked) {
        $("#EditForm").attr("data-id", args.RecurrenceID);
        $(".e-quick-dialog-series-event")
          .attr("disabled", false)
          .addClass("e-primary")
          .text("Delete Series")
          .show();
        $(".e-quick-alertok")
          .attr("disabled", false)
          .addClass("e-primary")
          .show();
      } else {
        $("#EditForm").attr("data-id", args.Id);
        $(".e-quick-dialog-series-event")
          .attr("disabled", true)
          .removeClass("e-primary");
        $(".e-quick-alertok")
          .attr("disabled", true)
          .removeClass("e-primary")
          .show();
      }
    });
    $(".e-quick-dialog-cancel").removeClass("e-disable").text("Cancel");

    let inputEleService = createElement("input", {
      className: "e-field",
      attrs: {
        name: "service_id",
        type: "hidden",
        value: this.state.serviceId || 0,
      },
    });
    container.appendChild(inputEleService);

    row.appendChild(container);
    let divEle = createElement("div", {
      className: "e-field",
      attrs: { name: "userId" },
    });
    let labelEle = createElement("label", {
      className: "e-label",
    });
    labelEle.innerHTML = "Name";
    divEle.appendChild(labelEle);

    let spanEle = createElement("span", {
      className: "e-label",
    });

    let full_name = "";
    for (const i in this.props.enrolled_users) {
      if (
        this.props.enrolled_users[i].is_approve === true ||
        this.props.enrolled_users[i].is_approve === "True"
      ) {
        if (
          (args?.data?.userId || args?.data?.userId) ===
          this.props.enrolled_users[i].id
        ) {
          full_name =
            this.props.enrolled_users[i].firstName +
            " " +
            this.props.enrolled_users[i].lastName;
        }
      }
    }

    spanEle.innerHTML = full_name;
    divEle.appendChild(spanEle);
    container.appendChild(divEle);

    let divSEDEle = createElement("div", {
      className: "e-start-date",
    });
    divSEDEle.innerHTML = $(".customTimeData").html();
    container.appendChild(divSEDEle);
    row.appendChild(container);
    $(".e-title-location-row").hide();
    $(".e-description-row").hide();
  }

  addFieldDropDown = (args) => {
    if (args?.data?.userId) {
      const userObj = this.state.userObj[args?.data?.userId || args?.data?.userId];
      if (args.element.querySelector(".user_image")) {
        args.element.querySelector(".user_image").remove();
      }
      if (userObj?.profile) {
        let doctor_logo = userObj?.profile.replace(/ /g, '%20')
        $(".e-title-text").before(
          `<img src=${doctor_logo} class='user_image' />`
        );
      }
    } else {
      const serviceObj = this.state.serviceDetail;
      $(".user_image").remove()
      if (serviceObj?.logo) {
        let doctor_logo = serviceObj?.logo.replace(/ /g, '%20')
        $(".e-title-text").before(
          `<div class="user_image_wrapper"><img class="user_image" src=${doctor_logo} /></div>`
        );
      }
    }
    if (args.element.querySelector(".custom-field-row")) {
      args.element.querySelector(".custom-field-row").remove();
    }
    if (args.element.querySelector(".edit_input_box")) {
      args.element.querySelector(".edit_input_box").remove();
    }
    let row = createElement("div", { className: "custom-field-row" });
    let formElement = args.element.querySelector(".e-schedule-form");
    formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
    let container = createElement("div", {
      className: "custom-field-container",
    });

    let inputEle = createElement("input", {
      className: "e-field",
      attrs: { name: "userId", id: "userId" },
    });
    container.appendChild(inputEle);
    row.appendChild(container);
    let userListDropdown = [];
    for (const i in this.props.enrolled_users) {
      if (
        this.props.enrolled_users[i].is_approve === true ||
        this.props.enrolled_users[i].is_approve === "True"
      ) {
        const full_name =
          this.props.enrolled_users[i].firstName +
          " " +
          this.props.enrolled_users[i].lastName;
        userListDropdown.push({
          text: full_name,
          value: this.props.enrolled_users[i].id,
        });
      }
    }
    const { value } = userListDropdown[0];
    if (args.data?.userId === undefined) {
      args["data"]["userId"] = value;
    }

    let drowDownList = new DropDownList({
      dataSource: userListDropdown,
      // required:true,
      //requires:true,
      // validationMessage: "Please select an option",
      fields: { text: "text", value: "value" },
      value: args.data?.userId || args.data?.userId,
      floatLabelType: "Always",
      placeholder: "Select User",
      readonly: $(".e-title-text").text() === "Edit Event",
    });
    drowDownList.appendTo(inputEle);
    inputEle.setAttribute("name", "userId");
    if ($(".e-title-text").text() === "Edit Event" || $(".e-title-text").text() === "Edit EventDelete Event") {
      $(".e-start-end-row").after(
        '<div class="edit_input_box"><input class="deletePopUpInput form-control" id="editDescription" maxlength="100" placeholder="Reason For Edit" type="text" name="" /><p class="max_char">Maximum 100 characters</p></div>'
      );
      $(".e-ddl-icon").remove();
    }
  };

  deleteAppoinment = async (id) => {
    let deleteDescription = $("#deleteDescription").val();
    const appoinmentObj = this.state.appoinmentObj[id];

    if (appoinmentObj.userId == localStorage.getItem("userId")) {
      this.setState({
        deleteStatus: true,
      });
      this.props.deleteAppoinmentAction(id, { description: deleteDescription });
      NotificationManager.success("Appointment Cancel Requested");
    } else {
      this.setState({
        deleteStatus: true,
      });
      // await deleteAppoinmentByProviderAction(id, {
      //   description: deleteDescription,
      // });
      NotificationManager.success("Appointment Canceled");
    }

    this.setState({ showSchedule: false });
    this.props.getAppointmentList();
  };

  deleteConfirmaProcessRequest = (appoinmentId) => {
    this.setState({
      deleteConfirmaProcessRequestModel: true,
      appoinmentId,
    });
  };

  deleteConfirm = (status, appoinmentId) => {
    if (status === "yes") {
      this.deleteAppoinment(this.state.appoinmentId);
    }
    this.setState({
      deleteConfirmaProcessRequestModel: false,
    });
  };

  hideDeleteConfirmaProcessRequestModel = () => {
    this.setState({ deleteConfirmaProcessRequestModel: false });
  };

  eventTemplate(args) {
    const appoinmentId = args.id;
    const appoinmentObj = args;
    if (appoinmentObj) {
      const serviceObj = this.state.serviceDetail;
      const userObj = this.state.userObj[args?.userId || args?.userId];
      let logo = "";
      if (userObj && serviceObj) {
        let title = userObj.firstName + " " + userObj.lastName;
        logo = userObj.profile;

        if (serviceObj.logo) {
          logo = userObj.profile;
        }
        let { providerApproved } = args;
        return (
          <Row className={(!providerApproved) ? 'providerApprovedChild' : ''}>
            <Col lg="3" className="">
              <div className="d-flex-outer">
                <div className="outer-image">
                  {logo && <img style={{ width: "40px" }} src={logo} />}
                </div>
                <div className="outer-text">
                  <div
                    className="service-name"
                    data-appoinment={`Appointment_${appoinmentId}_${moment(
                      args.startTime
                    ).unix()}`}
                  >
                    {title}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      }
    }
    //}
    return <Row> </Row>;
  }

  mapUrl = (data) => {
    return (
      "https://www.google.com/maps/place/" +
      data.street_address +
      "+" +
      data.state
    );
  };

  editButtonClick = () => {
    this.setState({ selectOption: "Editor" });
    $(".e-popup-footer .e-event-edit").trigger("click");
  };

  deleteButtonClick = (args) => {
    let html = $(".e-popup-content .e-date-time").html();
    setTimeout(() => {
      $(".custom-field-container .e-start-date").html(html);
    }, 100);

    this.setState({ selectOption: "DeleteAlert" });
    //$("#QuickDialog").css('opacity',1)
    // $(".e-control.e-dialog.e-lib.e-quick-dialog.e-dlg-modal.e-popup").addClass('iconClick');
    $(".e-popup-footer .e-event-delete").trigger("click");
  };

  /*deleteButtonClick = () => {
    $(".e-popup-footer .e-event-delete").trigger("click");
  };*/

  footerTemplate(args) {
    if (args.id) {
      const appoinmentId = args.id;
      const appoinmentObj = this.state.appoinmentObj[appoinmentId];
      const userObj = this.state.userObj[args?.userId || args?.userId];
      let email = userObj?.email;
      let phone = userObj?.phone;
      let title = userObj?.firstName + " " + userObj?.lastName;
      let logo = userObj?.profile;
      return (
        <Col className="appoinment-popup-footer">
          <ReactTooltip />
          <Row className="titleUserName" style={{ display: "none" }}>
            <div className="d-flex-outer">
              <div className="outer-image">
                {logo && (
                  <Col lg="3">
                    <img style={{ width: "40px" }} src={logo} />
                  </Col>
                )}
              </div>
              <div className="outer-text">
                <div>{title}</div>
              </div>
            </div>
          </Row>
          <Row className="iconPopup">
            <Col>
              <a href={"mailto:" + email}>
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </a>
            </Col>
            {isMobile && (
              <Col>
                <a href={"tel:" + phone}>
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </a>
              </Col>
            )}
            {isBrowser && (
              <Col>
                <a
                  href="javascript:void(0);"
                  onClick={this.phoneClick}
                  data-tip="Please try connecting with a mobile device."
                >
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </a>
              </Col>
            )}
            <Col>
              <a href={this.mapUrl(userObj)} target="_blank">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </a>
            </Col>
          </Row>
        </Col>
      );
    } else {
      return false;
    }
  }

  createAppointment = async (scheduleData) => {
    let datesArr = [];
    let recurrence = false;
    if (scheduleData.addedRecords[0].RecurrenceRule) {
      let startDate = scheduleData.addedRecords[0].StartTime;
      let recurrenceRule = scheduleData.addedRecords[0].RecurrenceRule;
      let dates = this.recObject.getRecurrenceDates(startDate, recurrenceRule);
      for (var k in dates) {
        datesArr.push(new Date(dates[k]));
      }
      recurrence = true;
    } else {
      datesArr.push(scheduleData.addedRecords[0].StartTime);
    }
    this.setState({ createStatus: true });
    let createRes = await createAppointmentActionByProvider({
      userId: scheduleData.addedRecords[0].userId,
      serviceId: this.state.serviceId,
      dataRange: datesArr,
      description: "",
      recurrence,
    });
    
    if(createRes.data.status == "success"){
      NotificationManager.success(createRes.data.message);
      this.props.getAppointmentList(this.state.serviceId);
    }
    else{
      let message = `Your appointment(s) cannot be created as no slot exists on ${moment(createRes.data.collision[0].cancel_date).format("MMM Do YYYY")}.`
      
      let collision_dates = createRes.data.collision.map(collision_date => {
        return {
          "cancel_date": new Date(collision_date.cancel_date),
          "end_date": new Date(collision_date.end_date)
        }
      });

      let slotColModalHeading = "Service not available";
      
      if(createRes.data.collision_type == "consumer"){
        slotColModalHeading = "Slot is busy";
      }

      this.setState({
        slotsNotAvailableModal: true,
        message: message,
        dataArray: collision_dates,
        slotColModalHeading: slotColModalHeading
      });
    }
    
    this.props.getAppointmentList(this.state.serviceId);
  };

  editAppointment = async (scheduleData) => {
    let editDescription = $("#editDescription").val();
    let datesArr = [];
    let recurrence = false;
    let { changedRecords } = scheduleData;
    let { StartTime, RecurrenceRule, id, userId } = changedRecords[0];
    if (RecurrenceRule) {
      let dates = this.recObject.getRecurrenceDates(StartTime, RecurrenceRule);
      for (var k in dates) {
        datesArr.push(new Date(dates[k]));
      }
      recurrence = true;
    } else {
      datesArr.push(StartTime);
    }
    this.setState({ createStatus: true });
    let createRes = await createAppointmentActionByProvider({
      userId: userId,
      serviceId: this.state.serviceId,
      dataRange: datesArr,
      recurrence,
      id,
      description: editDescription
    });
    
    if(createRes.data.status == "success"){
      NotificationManager.success(createRes.data.message);
      this.props.getAppointmentList(this.state.serviceId);
    }
    else{
      let message = `Your appointment(s) cannot be created as no slot exists on ${moment(createRes.data.collision[0].cancel_date).format("MMM Do YYYY")}.`
      
      let collision_dates = createRes.data.collision.map(collision_date => {
        return {
          "cancel_date": new Date(collision_date.cancel_date),
          "end_date": new Date(collision_date.end_date)
        }
      });

      let slotColModalHeading = "Service not available";
      
      if(createRes.data.collision_type == "consumer"){
        slotColModalHeading = "Slot is busy";
      }

      this.setState({
        slotsNotAvailableModal: true,
        message: message,
        dataArray: collision_dates,
        slotColModalHeading: slotColModalHeading
      });
    }

    this.props.getAppointmentList(this.state.serviceId);
  };

  deleteAppointment = async (scheduleData) => {
    let { changedRecords, deletedRecords } = scheduleData;
    let deleteDescriptions = $("#deleteDescription").val();
    let data = {};
    if (changedRecords.length) {
      let { StartTime, id, appointment_id, userId } = changedRecords[0];
      data = {
        userId: userId,
        serviceId: this.state.serviceId,
        date: StartTime,
        recurrence: true,
        id,
        appointment_id,
        description: deleteDescriptions,
      };
    } else {
      let { StartTime, id, userId } = deletedRecords[0];
      data = {
        userId: userId,
        serviceId: this.state.serviceId,
        date: StartTime,
        recurrence: false,
        id,
        description: deleteDescriptions,
      };
    }
    this.setState({ deleteStatus: true });
    this.props.deleteAppoinmentByProviderAction(data.id, data);
    if (data.recurrence === true) {
      NotificationManager.success("Appointment Series Deleted");
      this.props.getAppointmentList(data.id)
    } else if (data.recurrence === false) {
      NotificationManager.success("Appointment Deleted");
      this.props.getAppointmentList(data.id)
    }
  };

  render() {
    // console.log("PROVIDER APPOINTMENT PAGE");
    const { slotsNotAvailableModal, showSchedule, serviceScheduleData } =
      this.state;
    var selectedDate = new Date();
    if (this.state.selectedDate) {
      selectedDate = new Date(this.state.selectedDate);
    }
    return (
      <React.Fragment>
        {!slotsNotAvailableModal &&
          showSchedule &&
          !this.state.serviceScheduleDataLoading && (
            <ScheduleComponent
              dragStop={this.onDragStop.bind(this)}
              popupOpen={this.onPopupOpen.bind(this)}
              IsAllDay={false}
              DatePickerComponent={false}
              showTimeIndicator={false}
              startHour='00:00'
              endHour='23:59'
              ref={(schedule) => (this.scheduleObj = schedule)}
              dataBound={this.onDataBound.bind(this)}
              renderCell={this.onRenderCell.bind(this)}
              eventRendered={this.onEventRendered.bind(this)}
              selectedDate={selectedDate}
              cssClass="schadule-table"
              actionBegin={this.onActionBegin1.bind(this)}
              timeScale={{
                enable: true,
                interval: this.state.selectedInterval,
                slotCount: 1,
              }}
              eventSettings={{
                //dataSource: this.dataManager,
                dataSource: this.state.scheduleData,
                fields: JSON.parse(
                  JSON.stringify({
                    subject: { title: "Subject", name: "subject" },
                    userId: {
                      title: "User Id",
                      name: "userId",
                      default: localStorage.getItem("userId"),
                    },
                  })
                ),
                template: this.eventTemplate.bind(this),
              }}
              quickInfoTemplates={{
                //footer: this.footerTemplate.bind(this),
                footer: this.footerTemplate.bind(this),
                //content: this.footerTemplate.bind(this),
              }}
            >
              <ViewsDirective>
                <ViewDirective option="Day" />
                <ViewDirective option="Week" />
              </ViewsDirective>
              <Inject services={[Day, Week]} />
            </ScheduleComponent>
          )}
        <Modal
          show={this.state.slotsNotAvailableModal}
          onHide={(e) => this.hideModals({})}
          enforceFocus={false}
          className="count-modal"
        >
          <Modal.Header>
            <div className="header-modal">
              <span className="header-cal">
                <img src={image4} alt="" />
              </span>
              <span className="header-details">
                <div className="header-details-cont">
                  <h4>{this.state.slotColModalHeading}</h4>
                  <p>
                    <small>Initiated by:</small>{" "}
                    {localStorage.getItem("firstName")}{" "}
                    {localStorage.getItem("lastName")}
                  </p>
                </div>
                <div className="header-details-img">
                  <img src={image2} alt="" />
                </div>
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="body-modal">
              <span>
                <p>
                  <strong>Please Review: </strong> {this.state.message}
                </p>
              </span>
              {/*<span>
                <img src={image1} alt="" />
            </span>*/}
            </div>
            <div className="body-content-repeat">
              {this.state.dataArray &&
                this.state.dataArray.map(function (item) {
                  return (
                    <div className="content-repeat-row">
                      <span className="cal-ic">
                        {item.recurrenceType != "No" ? (
                          <img src={image8} alt="" width="50px" />
                        ) : (
                          <img src={image9} alt="" width="50px" />
                        )}
                      </span>
                      <span className="start-end-time">
                        <p>
                          <strong>Start Time:</strong>{" "}
                          {item.cancel_date.toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </p>
                        <p>
                          <strong>End Time:</strong>{" "}
                          {item.end_date.toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </p>
                      </span>
                      <span className="recurrence">
                        <p>
                          <strong>Recurrence:</strong> {item.recurrenceType}
                        </p>
                        <p>
                          <strong>Till:</strong> {item.end_date.toDateString()}
                        </p>
                      </span>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                this.hideModals({});
              }}
            >
              Dismiss
            </a>
          </Modal.Footer>
        </Modal>

        <div className="content-wrapper recurrence-editor-wrap">
          <div style={{ paddingBottom: "15px" }}>
            <label></label>
            <div className="rule-output-container">
              <div id="rule-output"></div>
            </div>
          </div>
          <div className="RecurrenceEditor" style={{ display: "none" }}>
            <RecurrenceEditorComponent
              id="RecurrenceEditor"
              ref={(t) => (this.recObject = t)}
            ></RecurrenceEditorComponent>
          </div>
        </div>

        <Modal
          show={this.state.deleteConfirmaProcessRequestModel}
          onHide={this.hideDeleteConfirmaProcessRequestModel}
          enforceFocus={false}
        >
          <Modal.Header closeButton>Delete Confirmation</Modal.Header>
          <Modal.Body className="notificationModal">Are you sure?</Modal.Body>
          <Modal.Footer>
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                this.deleteConfirm("yes");
              }}
            >
              Yes
            </a>
            &nbsp;
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                this.deleteConfirm("no");
              }}
            >
              No
            </a>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    appointmentData: state.appointmentReducer,
    count: state.countReducer.parentCounter,
    childCount: state.countReducer.childCounter,
    serviceId: state.serviceIdReducer.data
  };
};
const mapDispatchToProps = {
  getServiceScheduleAction: getServiceScheduleAction,
  createAppointmentActionByProvider: createAppointmentActionByProvider,
  getAppointmentList: getAppointmentList,
  deleteAppoinmentByProviderAction: deleteAppoinmentByProviderAction,
  ParentCountAction: ParentCountAction,
  ChildCountAction: ChildCountAction,
  getAllNotificationAction: getAllNotificationAction,
  ServiceIdAction: ServiceIdAction
};
export default connect(mapStateToProps, mapDispatchToProps)(CommomAppointment);