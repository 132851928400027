import * as actionTypes from '../constant';


const INITIAL_STATE = {};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        error:'',
        addressData:"",
        address:"",
        success:true,
        userProfile :action.value,
        userProfileData :action.data
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,

      };
    case actionTypes.LOG_ERROR:
      return {
        ...state,
        success: false,
        error: action.value
      };
    case actionTypes.SIGNUP_ERROR:
      return {
        ...state,
        loggedIn: false,
        signupError:action.value,
        success: ''
      };
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        signupError:'',
        signupSuccess: true
      };
    case actionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        success:'success',
        isUpdated:true,
        userProfileData:action.value
      };    
    case actionTypes.ADDRESS_ERROR:
      return {
        ...state,
        error: true,
        addressData:action.value,
        address:false
      };
    case actionTypes.ADDRESS_SUCCESS:
      return {
        ...state,
        success:'success',
        addressData:action.value,
        address:true
      };
    case actionTypes.FORGOT_PASSWORD:
      return {
        ...state,
        loggedIn: false,
        setPasswordForm:true,
        email:action.value,
        loginError:'',
      };
    case actionTypes.SET_PASSWORD:
      return {
        ...state,
        loggedIn: false,
        setPasswordForm: false,
        loginError:'',
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        loginError: null
      };
    case actionTypes.GET_TIMEZONE: //--get timezone detail
      return {
        ...state,
        timezoneData:action.value,
        success:'success',
        //address:true
      };
    case actionTypes.RESET_STATUS:
      return {
        ...state,
        ...action.value
      };

    default:
      //return { state, error: '', success: '', addressData:"", address:""};
      return state; 
  }
}
