export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';

export const REDIRECT_TO = 'REDIRECT_TO';
export const LOG_ERROR = 'LOG_ERROR';
export const INVALID_EMAIL_ERROR = 'INVALID_EMAIL_ERROR';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const USER_PROFILE = 'USER_PROFILE';
export const USER_LIST = 'USER_LIST';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const RESET_STATUS='RESET_STATUS';

export const SERVICE_MY_LIST = "SERVICE_MY_LIST";
export const SERVICE_LIST = "SERVICE_LIST";
export const SERVICE_CREATE = "SERVICE_CREATE";
export const SERVICE_UPDATE = "SERVICE_UPDATE";
export const SERVICE_DELETE = "SERVICE_DELETE";

export const SERVICE_MY_ENROLL_LIST = "SERVICE_MY_ENROLL_LIST";
export const SERVICE_ENROLL_MY_LIST = "SERVICE_ENROLL_MY_LIST";
export const SERVICE_ENROLL_LIST = "SERVICE_ENROLL_LIST";
export const SERVICE_ENROLL_CREATE = "SERVICE_ENROLL_CREATE";
export const SERVICE_ENROLL_UPDATE = "SERVICE_ENROLL_UPDATE";
export const SERVICE_ENROLL_DELETE = "SERVICE_ENROLL_DELETE";

export const SERVICE_CREATE_INVITE = "SERVICE_CREATE_INVITE";
export const SERVICE_LIST_INVITE = "SERVICE_LIST_INVITE";

export const APPOINTMENT_CREATE = "APPOINTMENT_CREATE";
export const APPOINTMENT_LIST = "APPOINTMENT_LIST";
export const MY_APPOINTMENT_LIST = "MY_APPOINTMENT_LIST";
export const MY_APPOINTMENT_DELETE = "MY_APPOINTMENT_DELETE";

export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const ADDRESS_ERROR = "ADDRESS_ERROR";

//---Enroll Notification
export const ENROLL_NOTIFICATION = "ENROLL_NOTIFICATION";
export const INVITE_NOTIFICATION = "INVITE_NOTIFICATION";
export const ALL_NOTIFICATION = "ALL_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const ACCCEPT_INVITATION = "ACCCEPT_INVITATION";
export const USER_LISTING = "USER_LISTING";
export const SERVICE_UN_ENROLL = "SERVICE_UN_ENROLL";
export const SERVICE_ENROLL_INVITED = "SERVICE_ENROLL_INVITED";

//-----------Service Schedule 
export const GET_TIMEZONE = "GET_TIMEZONE";

export const SERVICE_SCHEDULE = "SERVICE_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const SCHEDULE_DELETE = "SCHEDULE_DELETE";

export const INCREMENT_PARENT = "INCREMENT_PARENT";
export const INCREMENT_CHILD = "INCREMENT_CHILD";

export const SERVICE_ID = "SERVICE_ID";