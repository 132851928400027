import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default (callback,data,text="Are you sure?") =>{
    confirmAlert({
        title: text,
        buttons: [
            {
                label: 'Yes',
                onClick: () => callback(data)
            },
            {
                label: 'No',
            }
        ]
    });
}