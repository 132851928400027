import Api from '../Api'

export const getServices = (type) => {
    return Api.get( type ? 'service/'+type :'service/');
};

export const createService = (data) => {
    return Api.post('service/', data);
}

export const updateService = (serviceId, data) => {
    return Api.put('service/' + serviceId, data);
};

export const deleteService = (serviceId, data) => {
    return Api.post('service/delete/' + serviceId, data);
};

export const getInviteServices = (type) => {
    return Api.get( type ? 'invite/'+type :'invite/');
};

export const createInviteService = (data) => {
    return Api.post('invite/', data);
}
export const getServicesEnroll = (type) => {
    return Api.get( type ? 'service-enroll/'+type :'service-enroll/');
};

export const createServiceEnroll = (data) => {
    return Api.post('service-enroll/', data);
}

export const updateServiceEnroll = (serviceId, data) => {
    return Api.put('service-enroll/' + serviceId, data);
}

export const deleteServiceEnroll = (serviceId) => {
    return Api.delete('service-enroll/' + serviceId);
}

//---
export const getInviteNotifications = (type) => {
    return Api.get( type ? 'invite/'+type :'invite/');
};
export const getEnrollNotifications = (type) => {
    return Api.get( type ? 'service-consumer/'+type :'service-consumer/');
};

export const acceptInvitationApi = (serviceId, data) => {
    if(data.consumerId && data.consumerId!==''){
        return Api.put('service-enroll/', data);
    }else{
        return Api.put('invite/' + serviceId, data);
    }
}
export const serviceUnEnrollApi = (serviceId) => {
    return Api.delete('service-enroll/'+serviceId);
}

export const getServiceEnrollinvitedApi = (type) => {
    return Api.get( type ? 'service-consumer/invited'+type :'service-consumer/invited');
};


//-----------Service Schedule 
export const getServiceScheduleApi = (type) => {
    return Api.get( type?'service-schedule/'+type :'service-schedule/');
};
export const updateServiceScheduleApi = (data) => {
    return Api.post('service-schedule/', data);
}

export const deleteSchedule = (serviceId, description) => {
    return Api.post('service-schedule/delete/' + serviceId, {description});
};

export const getAllNotifications = (type) => {
    return Api.get('service-consumer/notifications');
};

export const readAllNotifications = (data) => {
    return Api.post('service-consumer/notifications');
}

export const readNotification = (id, notificationTypeId) => {
    return Api.get('service-consumer/notifications-read/'+id+"/"+notificationTypeId);
}