import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {appConstants} from "../../_Constants/app.constants"
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from 'jquery';
import {getUserListingAction} from '../../redux/actions/UserActions'
import {EnrollNotificationGetAction, acceptInvitationAction} from '../../redux/actions/ServiceActions'
import {Container, Row, Col, Card, Modal, FormLabel, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import Notification1 from "./NI-1.png"
import Notification2 from "./NI-2.png"
import "./notification.css"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import moment from 'moment';

class Notification extends React.Component {
    constructor(props){
      super(props)
      this.state={
          file:'',
          error:'',
          success:'',
          imagePreviewUrl:'',
          loading:false,
          modal:false,
          notificationList:[],
          otherServiceList:[],
          userListing:[],
          formData:{
            message:"",
            providerName:""
          },
      }
      this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      document.title = "Kunneckt | Enroll Notification"
      this.props.getUserListingAction()
      this.props.EnrollNotificationGetAction()
      if (localStorage.getItem('token') === null) {
        this.props.history.push('/login')
      }
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.userDetail && nextProps.userDetail.users && nextProps.userDetail.type === "userList"){
        this.setState({
          userListing : nextProps.userDetail.users,
        })
      }
      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "notification"){
        let notificationData = nextProps.serviceData.services?nextProps.serviceData.services:[];
        notificationData.sort(function(a,b){
          return new Date(b.requested_at) - new Date(a.requested_at)
        })
        if(notificationData){            
          notificationData = notificationData.filter(function(row){
            return (row.is_approve==='' || row.is_approve===null)
          })
        }
        this.setState({
          notificationList:notificationData,
        })
      }
      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "acceptInvite"){
        this.setState({
          modal:false,
        })
        NotificationManager.success(nextProps.serviceData.services.message);
        this.props.EnrollNotificationGetAction()
      }
    }

    handleChange(type, e) {
      let data = this.state.formData;
      if (type === "ext") {
          data[type] = e;
      } else {
          data[type] = e.target.value;
      }
      this.setState(data);
    }

    hideModals=(data)=> {
      this.setState({
        formData: data?data:{},
        modal: !this.state.modal
      })
    }

    IsAccepted = (cell,row)=>{
      if(row.is_approve===false || row.is_approve==="False"){
        return (<span>Unapproved</span>)
      }else if(row.is_approve===true || row.is_approve==="True"){
        return (<span>Approved</span>)
      }else{
          return (<span>Pending</span>)
      }
    }      

    IsAction = (cell,row)=>{
      return (<Link to="#" onClick={e=>{this.hideModals(row,e)}}> View </Link>)
    }      

    IsDate = (cell,row)=>{
      var dateTimeValue = moment(row.requested_at).format('MMM, DD YYYY');
      return (<span>{dateTimeValue}</span>)
    }    

    async acceptInvitation(data, type) {
      let newData = {};
      newData['serviceId'] = data.serviceId;
      newData['consumerId'] = data.userId;
      newData['isApprove'] = type;
      this.props.acceptInvitationAction(data.serviceId, newData)
    }
    
    render() {
      const {formData, notificationList, userListing} = this.state ;
      var notifyArr = notificationList?notificationList:[];
      if(notifyArr && userListing){
        for (var i = 0; i < notifyArr.length; i++) {
          for (var j = 0; j < userListing.length; j++) {
            if(userListing[j].id===notifyArr[i].userId){
              notifyArr[i]['email'] = userListing[j].email;
              notifyArr[i]['username'] = userListing[j].username;
              notifyArr[i]['firstName'] = userListing[j].firstName;
              notifyArr[i]['lastName'] = userListing[j].lastName;
              notifyArr[i]['phone'] = userListing[j].phone;
            }
          }
        }
      }

      return (
        <div className="container-fluid main-contain">
          <div className="row">
            <div className="col-md-12">
              <BootstrapTable data={ notifyArr } pagination search={false}>
                <TableHeaderColumn tdAttr={ { 'data-attr': 'ID' }} dataField='id' isKey hidden={true}>Id</TableHeaderColumn>
                <TableHeaderColumn width="200" tdAttr={{ 'data-attr': 'Provider Name' }} dataField='username'>Enrolled By</TableHeaderColumn>
                <TableHeaderColumn width="200" tdAttr={{ 'data-attr':'Service Name'}} dataField='serviceName' >Service</TableHeaderColumn>
                <TableHeaderColumn width="200" tdAttr={{ 'data-attr':'Enroll Date'}} dataFormat={this.IsDate.bind(this)} dataField='requested_at' >Enroll Data</TableHeaderColumn>
                <TableHeaderColumn width="100" tdAttr={{ 'data-attr':'Status'}} dataFormat={this.IsAccepted.bind(this)} >Status</TableHeaderColumn>
                <TableHeaderColumn width='100' tdAttr={{ 'data-attr':'Action'}} dataFormat={this.IsAction.bind(this)} dataSort={true} searchable={false}>Action</TableHeaderColumn>
              </BootstrapTable><br/>
            </div>
                
            <Modal show={this.state.modal} onHide={e=>this.hideModals({})} enforceFocus={false}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <img src={Notification1} height="50px" alt="" /> &nbsp; Enrolled Service
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormLabel>
                    Enroll By : <span className="notificationTitle">{formData.username?formData.username:'-'}</span>
                  </FormLabel><br/>
                  <div className="row">
                    <div className="col-md-6">
                      <FormLabel>
                        Service : <span className="notificationTitle">{formData.serviceName?formData.serviceName:'-'}</span>
                      </FormLabel>
                    </div>
                  </div>
                  <br/>
                  {formData.is_approve==='' && 
                  <div className="row">
                    <InputGroup className="mb-3">
                      <br/><br/>
                      <div className="col-md-6"></div>
                      <div className="col-md-6 p-r0">
                        <Button className="inviteServiceBtn" onClick={e=>{this.acceptInvitation(formData,false)}}>Reject</Button>&nbsp;&nbsp;
                        <Button className="inviteServiceBtn" onClick={e=>{this.acceptInvitation(formData,true)}}>Approve</Button>
                      </div>
                    </InputGroup>
                    </div>
                  }
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )
    }
}

const mapStateToProps = state => {
  return {
    error: state.serviceReducer.signupError,
    success: state.serviceReducer.success,
    serviceData: state.serviceReducer,
    userDetail: state.userReducer,
  }
}

const mapDispatchToProps = {
  getUserListingAction: getUserListingAction,
  acceptInvitationAction: acceptInvitationAction,
  EnrollNotificationGetAction: EnrollNotificationGetAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
