import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { appConstants } from "../_Constants/app.constants";
import {
  updateProfileUser,
  resetStatus,
  valideAddressAction,
} from "../redux/actions/AuthActions";
import { addressValidate } from "../services/authServices";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      error: "",
      success: "",
      imagePreviewUrl: "",
      loading: false,
      formData: {},
      timezoneArr: [],
    };
  }
  componentDidMount() {
    document.title = "Kunneckt | Profile";
    // let userData =JSON.parse(localStorage.getItem('persist:auth'));
    //     userData =JSON.parse(userData.authReducer)
    // if(userData.userProfileData!==undefined){
    //     this.setState({
    //         formData:userData.userProfileData
    //     })
    // }
    this.props.resetStatus({ signupError: "", success: "" });
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/login");
    }
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let FILE_SIZE = 1498400;
    let SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
    ];
    if (file === undefined) {
      return false;
    }
    if (!SUPPORTED_FORMATS.includes(file.type)) {
      alert("File not supported.");
      return false;
    }
    if (file.size > FILE_SIZE) {
      alert("Image size has been exceeded.");
      return false;
    }

    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      //this.props.setFieldValue(this.props.field.name, file);
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    let formData = this.state.formData;
    formData[e.target.name] = e.target.value;
    if (e.target.name == "zip" && e.target.value.length > 4) {
      // this.props.valideAddressAction("?address="+formData.street_address+"&city=>"+formData.city+"&state="+formData.state)
    }
    this.setState({
      formData: formData,
    });
  };

  handleSubmit = (formData, addressCheck) => {
    formData.user_timezone =
      formData && formData.user_timezone
        ? formData.user_timezone
        : "America/New_York";
    var dt = new Date();
    let form_data = new FormData();
    for (var key in formData) {
      if (key !== "profile") {
        form_data.append(key, formData[key]);
      }
    }
    if (this.state.file !== "") {
      form_data.append(
        "profile",
        this.state.file,
        dt.getTime() + this.state.file.name
      );
    } else {
      form_data.append(
        "profile",
        this.state.file,
      )
    }
    this.props.resetStatus({ signupError: "", success: "" });
    this.setState(
      {
        loading: true,
        success: "",
      },
      () => {
        let addressUrl =
          "?address=" +
          formData.street_address +
          "&city=" +
          formData.city +
          "&state=" +
          formData.state;
        addressValidate(addressUrl)
          .then((res) => {
            let address = res.data.ZipCodeLookupResponse.Address;
            if (
              !formData.street_address &&
              !formData.city &&
              !formData.state &&
              !formData.zip
            ) {
              localStorage.setItem("firstName", formData.firstName);
              localStorage.setItem("lastName", formData.lastName);
              localStorage.setItem("username", formData.username);

              this.props.updateUser(form_data);
            } else if (
              address.Zip4 == formData.zip ||
              address.Zip5 == formData.zip
            ) {
              localStorage.setItem("firstName", formData.firstName);
              localStorage.setItem("lastName", formData.lastName);
              localStorage.setItem("username", formData.username);

              this.props.updateUser(form_data);
            } else {
              this.setState({
                loading: false,
                error: "Invalid Address.",
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: "Invalid Address.",
            });
          });
      }
    );
  };

  imageUpload = () => {
    document.getElementById("profile").click();
  };

  removeImage = (e) => {
    e.preventDefault();
    this.setState({file: "", imagePreviewUrl: null})
  }

  static getDerivedStateFromProps(nextProps, state) {
    let nextResult = {};
    if (nextProps.authReducer && nextProps.authReducer.timezoneData) {
      nextResult.timezoneArr = nextProps.authReducer.timezoneData;
    }
    if (nextProps.userProfileData !== undefined) {
      let profileData = nextProps.userProfileData;
      let value = {
        email:
          profileData.email !== undefined
            ? profileData.email
            : state.formData.email,
        user_timezone:
          profileData.user_timezone !== null ? profileData.user_timezone : "",
        state: profileData.state !== null ? profileData.state : "",
        city: profileData.city !== null ? profileData.city : "",
        zip:
          profileData.zip !== null && profileData.zip !== "None"
            ? profileData.zip
            : "",
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        street_address:
          profileData.street_address !== null ? profileData.street_address : "",
        phone: profileData.phone,
        username: profileData.username,
        profile:
          profileData.avtar !== undefined
            ? profileData.avtar
            : state.formData.avtar,
        autoLogin: profileData.autoLogin,
      };

      localStorage.setItem("username", value.username);
      localStorage.setItem("autoLogin", value.autoLogin);
      if (value.autoLogin != 1) {
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        localStorage.removeItem("autoLogin");
      }
      nextResult.formData = value;
      nextResult.imagePreviewUrl =
        profileData.avtar !== undefined
          ? profileData.avtar
          : state.formData.avtar;
      if (
        state.imagePreviewUrl !== "" &&
        state.imagePreviewUrl !== nextProps.userProfileData.avtar
      ) {
        nextResult.imagePreviewUrl = state.imagePreviewUrl;
      }
    }
    if (
      nextProps.authReducer.isUpdated !== undefined &&
      nextProps.authReducer.isUpdated
    ) {
      nextResult.loading = false;
      nextResult.error = "";
      nextResult.success = "Profile updated!.";
      let authInfo = nextProps.authReducer;
      delete authInfo.isUpdated;
      nextProps.resetStatus({ authReducer: authInfo });
      //NotificationManager.success('Profile updated!.');
    }
    if (nextProps.error !== undefined && nextProps.error !== "") {
      nextResult.error = nextProps.error;
      nextResult.loading = false;
    }
    return { ...nextResult };
  }

  render() {
    const { formData, timezoneArr } = this.state;
    const phoneRegExp =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const passwordRegex = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;
    // /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/;


    return (
      <React.Fragment>
        <div className="profile-form my-pro-desi">
          <Formik
            enableReinitialize={true}
            initialValues={{ ...formData }}
            onSubmit={(userData) => {
              this.handleSubmit(userData, false);
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("FirstName is required"),
              lastName: Yup.string().required("LastName is required"),
              zip: Yup.string(),
              city: Yup.string().when("zip", {
                is: (value) => value && value.length > 0,
                then: Yup.string().required("Required."),
              }),
              state: Yup.string().when("zip", {
                is: (value) => value && value.length > 0,
                then: Yup.string().required("Required."),
              }),
              phone: Yup.string()
                .min(10)
                .test("len", "Phone number is not valid", (val) => {
                  const val_length_without_dashes = val.replace(/[^0-9]/g, "").length;
                  return val_length_without_dashes === 10;
                })
                .matches(phoneRegExp, "Phone number is not valid")
                .required("Phone is required"),
              email: Yup.string().email().required("Email is required"),
              street_address: Yup.string().when("zip", {
                is: (value) => value && value.length > 0,
                then: Yup.string().required("Required."),
              }),
              //profile: Yup.mixed().required("A file is required").test("fileSize","File too large",value => value && value.size <= FILE_SIZE).test("fileFormat","Unsupported Format",value => value && SUPPORTED_FORMATS.includes(value.type))
            })}
          >
            {(props) => {
              const { errors, touched, values, handleSubmit, handleChange } =
                props;

              return (
                <form className="form form-vertical " onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-3 offset-md-0">
                        <div className="kv-avatar">
                          <div className="file-loading">
                            <input
                              id="profile"
                              name="profile"
                              type="file"
                              style={{ display: "none" }}
                              onChange={this.handleImageChange}
                              accept=".jpg,.jpeg,.png"
                            />
                            {touched.profile && (
                              <p className="text text-danger capitalize">
                                {errors.profile}{" "}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="kv-avatar-hint">
                          <small>Select file 1500 KB</small>
                        </div>
                        <br />
                        <div>
                          {this.state.imagePreviewUrl !== null &&
                            this.state.imagePreviewUrl !== "" ? (
                            <>
                              <img
                                src={this.state.imagePreviewUrl}
                                alt="preview"
                                className="img-fluid"
                              />
                              <br />{" "}
                              <i
                                onClick={this.imageUpload}
                                className="fa fa-pencil"
                              ></i>{" "}
                              <i
                                onClick={this.removeImage}
                                class="fa fa-trash"
                              ></i>{" "}
                            </>
                          ) : (
                            <div
                              className="profileDiv"
                              onClick={this.imageUpload}
                            >
                              Photo <br /> (Click to update)
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-9">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="firstName">
                                First Name<span className="req">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                defaultValue={values.firstName}
                                onChange={handleChange}
                              />
                              {touched.firstName && (
                                <p className="text text-danger-bold capitalize">
                                  {errors.firstName}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="lastName">
                                Last Name<span className="req">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                defaultValue={values.lastName}
                                onChange={handleChange}
                              />
                              {touched.lastName && (
                                <p className="text text-danger-bold capitalize">
                                  {errors.lastName}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="phone">
                                Phone Number<span className="req">*</span>
                              </label>
                              {/* <input type="text" className="form-control" name="phone" defaultValue={values.phone}  onChange={handleChange}/> */}
                              <InputMask
                                {...this.props}
                                mask="(999)-999-9999"
                                className="form-control"
                                name="phone"
                                onChange={handleChange}
                                placeholder="Phone Number"
                                alwaysShowMask
                                defaultValue={values.phone}
                              />
                              {touched.phone && (
                                <p className="text text-danger-bold capitalize">
                                  {errors.phone}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">
                                Email Address<span className="req">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                defaultValue={values.email}
                                onChange={handleChange}
                              />
                              {touched.email && (
                                <p className="text text-danger-bold capitalize">
                                  {errors.email}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="street_address">Street Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="street_address"
                            defaultValue={values.street_address}
                            onChange={handleChange}
                          />
                          {touched.street_address && (
                            <p className="text text-danger-bold capitalize">
                              {errors.street_address}{" "}
                            </p>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="city">City</label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                defaultValue={values.city}
                                onChange={handleChange}
                              />
                              {touched.city && (
                                <p className="text text-danger-bold capitalize">
                                  {errors.city}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="state">State</label>
                              <select
                                className="form-control"
                                name="state"
                                defaultValue={values.state}
                                onChange={handleChange}
                                style={{ display: "block" }}
                              >
                                <option value="0" label="Select State" />
                                {appConstants.stateOptions.map((data, idx) => (
                                  <option
                                    value={data.value}
                                    label={data.label}
                                    key={idx}
                                  />
                                ))}
                              </select>
                              {/*<input type="text" className="form-control" name="state" value={formData.state}  onChange={this.handleChange}/>*/}
                              {touched.state && (
                                <p className="text text-danger-bold capitalize">
                                  {errors.state}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="zip">Zip</label>
                              <input
                                type="text"
                                className="form-control"
                                name="zip"
                                onChange={handleChange}
                                defaultValue={values.zip}
                              />
                              {touched.zip && (
                                <p className="text text-danger-bold capitalize">
                                  {errors.zip}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="zip"> Timezone </label>
                              <select
                                className="form-control"
                                name="user_timezone"
                                defaultValue={values.user_timezone}
                                onChange={handleChange}
                              >
                                <option value="" label="Select Timezone" />
                                {timezoneArr &&
                                  timezoneArr.map((itm, idx) => (
                                    <option
                                      value={itm.text}
                                      label={itm}
                                      key={idx}
                                    />
                                  ))}
                                {console.log("timezoneArr", timezoneArr)}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Auto Login &nbsp;</label>
                          <label className="radio-inline">
                            <input
                              type="radio"
                              name="autoLogin"
                              value="1"
                              checked={values.autoLogin == 1}
                              onChange={handleChange}
                            />
                            Enable &nbsp;&nbsp;&nbsp;
                          </label>
                          <label className="radio-inline">
                            <input
                              type="radio"
                              name="autoLogin"
                              value="0"
                              onChange={handleChange}
                              checked={values.autoLogin == 0}
                            />
                            Disable
                          </label>
                        </div>
                        <div className="form-group">
                          <hr />
                          {this.state.error !== "" && (
                            <div className="alert alert-danger">
                              {this.state.error}
                            </div>
                          )}
                          {this.state.success !== "" && (
                            <div className="alert alert-success">
                              {this.state.success}
                            </div>
                          )}
                          <div className="text-right">
                            {this.state.loading ? (
                              <button className="btn text-center" disabled>
                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-light">
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    authReducer: state.authReducer,
    userProfileData: state.authReducer.userProfileData,
  };
};
const mapDispatchToProps = {
  updateUser: updateProfileUser,
  valideAddressAction: valideAddressAction,
  resetStatus: resetStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
