import React from 'react';
import { connect } from 'react-redux';
import { getServiceScheduleAction } from '../../redux/actions/ServiceActions'
import { createAppointmentAction, getAppointmentList } from '../../redux/actions/AppointmentActions'
import { ScheduleComponent, Day, Week, Month, Inject, ViewsDirective, ViewDirective, RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CalendarComponent, RenderDayCellEventArgs } from '@syncfusion/ej2-react-calendars';
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
import moment from 'moment';
import defaultImg from '../../assets/images/default.png';
import { deleteAppoinmentAction } from '../../redux/actions/AppointmentActions';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Container, Row, Col, Card, Modal, FormLabel, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import $ from "jquery";
import image4 from '../../assets/images/image4.png';
import image2 from '../../assets/images/image2.png';
import image1 from '../../assets/images/image1.png';
import image8 from '../../assets/images/image8.png';
import image9 from '../../assets/images/image9.png';

class AppointmentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      success: '',
      loading: false,
      serviceDetail: {},
      timeSloat: ['5 min', '30 min', '1 hrs'],
      selectedTimeSloat: 1,
      selectedInterval: 60,
      timeSloats: "1 hrs",
      selectedDate: null,
      slotsNotAvailableModal: false,
      createStatus: false,
      deleteStatus: false,
      scheduleData: null,
      other_users_time: []
    }
  }



  componentDidMount() {
    document.title = "Kunneckt | Add Schedule"
    if (localStorage.getItem('token') === null) {
      this.props.history.push('/login')
    } else if (!this.props.location.state || !this.props.location.state.data) {
      this.props.history.push('/service');
    }
    else{
      this.setState({ serviceDetail: this.props.location.state.data })
      this.props.getServiceScheduleAction(`?service_id=${this.props.location.state.data.id}`)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceData && nextProps.serviceData.scheduleRes && nextProps.serviceData.type == "response") {

      let schedule = nextProps.serviceData.scheduleRes.data;
      for (let i in schedule) {
        if (schedule[i].recurrence_rule == "None") {
          schedule[i].recurrence_rule = "Daily";
        }
      }
      this.setState({ serviceScheduleData: schedule })

      this.props.getAppointmentList(this.props.location.state.data.id)
    }
    if (nextProps.appointmentData && nextProps.appointmentData.appoinment) {
      this.setState({ last_appoinment: nextProps.appointmentData.appoinment });
      this.reset_appointment(nextProps.appointmentData.appoinment);
    }



    if (nextProps.appointmentData && this.state.deleteStatus) {
      this.setState({
        deleteStatus: false
      })

      this.props.getAppointmentList(this.props.location.state.data.id);
    }

    if (nextProps.appointmentData && nextProps.appointmentData.service && this.state.createStatus) {
      this.setState({
        createStatus: false
      })

      this.props.getAppointmentList(this.props.location.state.data.id);
    }
    if (this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
  }

  reset_appointment(appointmentData) {
    let appointmentScheduleData = [];
    if (appointmentData.length > 0) {
      for (let i in appointmentData) {
        let startdate = appointmentData[i].start_date;
        let enddate = appointmentData[i].end_date;
        let data = {
          Id: appointmentData[i].id,
          Subject: appointmentData[i].subject ? appointmentData[i].subject : '',
          RecurrenceRule: appointmentData[i].recurrence_rule ? appointmentData[i].recurrence_rule : '',
          StartTime: new Date(startdate),
          EndTime: new Date(enddate),
        }
        let userId = localStorage.getItem('userId');
        if (appointmentData[i].userId != userId || !appointmentData[i].providerApproved || appointmentData[i].delete_request) {
          data.IsReadonly = true;
        }

        appointmentScheduleData.push(data);
      }
    }

    this.setState({
      scheduleData: appointmentScheduleData,
    })
  }

  handleChange = (e) => {
    let time = e.target.value;
    let interval = 60;
    let timeslot = 1;
    if (time == '5 min') {
      timeslot = 12;
      interval = 5;
    } else if (time == '30 min') {
      timeslot = 2;
      interval = 30;
    } else {
      timeslot = 1;
      interval = 60;
    }

    this.setState({
      selectedTimeSloat: timeslot, timeSloats: time, selectedInterval: interval
    })
  }

  check_date_exist(startDate, recurrenceRule, recurrenceID="") {
    let dates = this.recObject.getRecurrenceDates(startDate, recurrenceRule);
    let allScheduleIds = [];
    var isNotExist = true;
    var cancel_type = "";
    var cancel_date = null;
    var dateArray = {};
    for (var k in dates) {

      var startDate1 = new Date(dates[k]);
      var endDate1 = new Date(dates[k]);
      endDate1.setHours(endDate1.getHours() + 1);
      const availablity1 = this.scheduleObj.getOccurrencesByRange(startDate1, endDate1);
      if (availablity1.length) {
        if(recurrenceID && availablity1[0].RecurrenceID == recurrenceID){
          continue;
        }

        var recurrenceTypes = availablity1[0].RecurrenceRule.match(/(FREQ=)(.*?)[;]/g);
        var recurrenceType = recurrenceTypes[0].replace('FREQ=','').replace(';','');
        
        isNotExist = false;
        cancel_type = "booked_slot";
        cancel_date = startDate1;
        dateArray[availablity1[0].RecurrenceID] = {'cancel_type':"booked_slot",'cancel_date':startDate1,'end_date':endDate1, 'recurrenceType': recurrenceType};
      }
      else {
        var [scheduleExist, scheduleIds] = this.checkScheduleExist(startDate1);
        if (!scheduleExist) {
          isNotExist = false;
          cancel_type = "unavailable_slot";
          cancel_date = startDate1;
          dateArray["unavailable_slot"] = {'cancel_type':"unavailable_slot",'cancel_date':startDate1,'end_date':endDate1, 'recurrenceType': 'No'};
        }
        else{
          allScheduleIds = allScheduleIds.concat(scheduleIds);
        }
      }
    }
    allScheduleIds = [...new Set(allScheduleIds)];
    

    if (!isNotExist) {
      // this.cancelSchedule(cancel_type,cancel_date, dateArray);
      //this.cancelSchedule(dateArray);

      this.setState({
        slotsNotAvailableModal: true,
        message:'Your appointment(s) cannot be completed due to the conflicts listed below',
        dataArray: Object.values(dateArray)
      });
    }

    let last_date = dates.pop();
    last_date = new Date(last_date);

    return [isNotExist, allScheduleIds, last_date];
  }

  onActionBegin(args) {
   
    if (args && args.addedRecords && args.addedRecords.length > 0) {

      let startDate = args.addedRecords[0].StartTime;

      if (!args.addedRecords[0].RecurrenceRule) {
        args.addedRecords[0].RecurrenceRule = "FREQ=DAILY;INTERVAL=1;COUNT=1;";
      }

      var [isNotExist, scheduleIds, lastDate] = this.check_date_exist(startDate, args.addedRecords[0].RecurrenceRule);

      if (isNotExist) {
        this.setState({
          createStatus: true,
          selectedDate: args.addedRecords[0].StartTime,
        });
        let scheduleData = {
          "service_id": this.state.serviceDetail.id,
          "end_date": args.addedRecords[0].EndTime ? args.addedRecords[0].EndTime : "",
          "start_date": args.addedRecords[0].StartTime ? args.addedRecords[0].StartTime : "",
          "start_time": args.addedRecords[0].StartTime ? args.addedRecords[0].StartTime : "",
          "end_time": args.addedRecords[0].EndTime ? args.addedRecords[0].EndTime : "",
          "subject": args.addedRecords[0].Subject ? args.addedRecords[0].Subject : "",
          "recurrence_rule": args.addedRecords[0].RecurrenceRule ? args.addedRecords[0].RecurrenceRule : "",
          "appointment_id": "",
          "description": args.addedRecords[0].Description ? args.addedRecords[0].Description : "",
          "scheduleIds": scheduleIds,
          "lastDate": lastDate
        }
        
        this.props.createAppointmentAction(scheduleData);
        NotificationManager.success('New Appointment Requested');

      }
      else {
        args.cancel = true;
      }
    }

    if (args && args.changedRecords && args.changedRecords.length > 0 && args.requestType == "eventChange") {
      let startDate = args.changedRecords[0].StartTime;
      var [isNotExist, scheduleIds, lastDate] = this.check_date_exist(startDate, args.changedRecords[0].RecurrenceRule, args.changedRecords[0].RecurrenceID);

      if (isNotExist) {
        this.setState({
          createStatus: true,
          selectedDate: args.changedRecords[0].StartTime,
        });
        let scheduleData = {
          "service_id": this.state.serviceDetail.id,
          "appointment_id": args.changedRecords[0].Id ? args.changedRecords[0].Id : "",
          "description": args.changedRecords[0].Description ? args.changedRecords[0].Description : "",
          "end_date": args.changedRecords[0].EndTime ? args.changedRecords[0].EndTime : "",
          "start_date": args.changedRecords[0].StartTime ? args.changedRecords[0].StartTime : "",
          "start_time": args.changedRecords[0].StartTime ? args.changedRecords[0].StartTime : "",
          "end_time": args.changedRecords[0].EndTime ? args.changedRecords[0].EndTime : "",
          "subject": args.changedRecords[0].Subject ? args.changedRecords[0].Subject : "",
          "recurrence_rule": args.changedRecords[0].RecurrenceRule ? args.changedRecords[0].RecurrenceRule : "",
          "scheduleIds": scheduleIds,
          "lastDate": lastDate,
          "update_schedule": {
            "end_date": args.changedRecords[0].EndTime ? args.changedRecords[0].EndTime : "",
            "start_date": args.changedRecords[0].StartTime ? args.changedRecords[0].StartTime : "",
            "start_time": args.changedRecords[0].StartTime ? args.changedRecords[0].StartTime : "",
            "end_time": args.changedRecords[0].EndTime ? args.changedRecords[0].EndTime : "",
            "subject": args.changedRecords[0].Subject ? args.changedRecords[0].Subject : "",
            "recurrence_rule": args.changedRecords[0].RecurrenceRule ? args.changedRecords[0].RecurrenceRule : "",
            "scheduleIds": scheduleIds,
            "lastDate": lastDate
          },
        }
        
        this.props.createAppointmentAction(scheduleData);

        NotificationManager.success('Appointment Reschedule Requested');
      }
      else {
        args.cancel = true;
      }
    }

    if (args && args.deletedRecords && args.deletedRecords.length) {

      this.setState({
        deleteStatus: true
      })
      let deleteDescription = $("#deleteDescription").val();
      this.props.deleteAppoinmentAction(args.deletedRecords[0].Id, {"description":deleteDescription})
      NotificationManager.success('Appointment Cancel Requested');
    }

    if (args && args.changedRecords && args.changedRecords.length > 0 && args.requestType == "eventRemove") {
      this.setState({
        deleteStatus: true
      })
      let deleteDescription = $("#deleteDescription").val();
      this.props.deleteAppoinmentAction(args.changedRecords[0].Id, {"description":deleteDescription})
      NotificationManager.success('Appointment Cancel Requested');
    }
  }

  checkScheduleExist(arg_date) {
    var scheduleExist;
    var scheduleData = this.state.serviceScheduleData;
    var scheduleIds = [];
    if (scheduleData && scheduleData.length > 0) {
      for (let i in scheduleData) {

        var startDate = scheduleData[i].start_date_time;
        var endDate = scheduleData[i].end_date_time;
        startDate = moment(startDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');
        var cellDate = moment(arg_date).format('YYYY-MM-DD');
        var checkDateIsExist = moment(cellDate).isBetween(startDate, endDate, undefined, '[]');

        if (checkDateIsExist) {
          // if (scheduleData[i].recurrence_rule == 'Daily') {
          var startTime = moment(scheduleData[i].recurrence_list.start_time, 'h:mma');
          var endTime = moment(scheduleData[i].recurrence_list.end_time, 'h:mma');
          var cellTime = moment(arg_date, 'h:mma');
          if (arg_date) {
            var start_time_h = startTime.format("HH")
            var start_time_m = startTime.format("mm")

            var endTime_h = endTime.format("HH")
            var endTime_m = endTime.format("mm")

            startTime = moment(cellDate).set({ 'hour': start_time_h, 'minute': start_time_m });
            endTime = moment(cellDate).set({ 'hour': endTime_h, 'minute': endTime_m });
            }
          var checkTimeIsExist = cellTime.isBetween(startTime, endTime, null, '[]');

          if (scheduleData[i].recurrence_rule == 'Daily' || scheduleData[i].recurrence_rule == 'None') {
            if (checkTimeIsExist) {
              scheduleExist = true;
              scheduleIds.push(scheduleData[i].id);
            }
          }
          if (scheduleData[i].recurrence_rule == 'Weekly') {
            var cellDay = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDay = cellDay.format('dddd');
            if (scheduleData[i].recurrence_list && scheduleData[i].recurrence_list.data && scheduleData[i].recurrence_list.data.length > 0) {
              var scheduleDayArray = scheduleData[i].recurrence_list.data;
              for (let j in scheduleDayArray) {
                if (scheduleDayArray[j] === cellDay) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                    scheduleIds.push(scheduleData[i].id);
                  }
                }
              }
            }
          }

          if (scheduleData[i].recurrence_rule == 'Monthly') {
            var cellDate = moment(arg_date, "YYYY-MM-DD HH:mm:ss");
            cellDate = cellDate.format('DD');
            if (scheduleData[i].recurrence_list && scheduleData[i].recurrence_list.data && scheduleData[i].recurrence_list.data.length > 0) {
              var scheduleDateArray = scheduleData[i].recurrence_list.data;
              for (let k in scheduleDateArray) {
                if (scheduleDateArray[k] == 1) {
                  scheduleDateArray[k] = '01';
                }
                if (scheduleDateArray[k] == 2) {
                  scheduleDateArray[k] = '02';
                }
                if (scheduleDateArray[k] == 3) {
                  scheduleDateArray[k] = '03';
                }
                if (scheduleDateArray[k] == 4) {
                  scheduleDateArray[k] = '04';
                }
                if (scheduleDateArray[k] == 5) {
                  scheduleDateArray[k] = '05';
                }
                if (scheduleDateArray[k] == 6) {
                  scheduleDateArray[k] = '06';
                }
                if (scheduleDateArray[k] == 7) {
                  scheduleDateArray[k] = '07';
                }
                if (scheduleDateArray[k] == 8) {
                  scheduleDateArray[k] = '08';
                }
                if (scheduleDateArray[k] == 9) {
                  scheduleDateArray[k] = '09';
                }
                if (scheduleDateArray[k] === cellDate) {
                  if (checkTimeIsExist) {
                    scheduleExist = true;
                    scheduleIds.push(scheduleData[i].id);
                  }
                }
              }
            }

          }
        }
      }
    }

    return [scheduleExist, scheduleIds];
  }

  onRenderCell(args) {
    var scheduleExist = false;
    var scheduleIds = [];
    if (args.date >= new Date()) {
      [scheduleExist, scheduleIds] = this.checkScheduleExist(args.date);
    }

    if (!scheduleExist) {
      args.element.classList.remove("e-work-cells");
      args.element.classList.add("no-work-cells");
      args.cancel = true;
    }
  }

  onEventRendered(args) {
    if(args['data'].StartTime < new Date()) {
      args.cancel = true;
    }
  }

  hideModals(args) {
    this.setState({ slotsNotAvailableModal: false });
  }

  showCalendars() {
    this.setState({ hideShowCalendars: true });
    scroller.scrollTo('appointment_module', {
        duration: 1500,
        delay: 100,
        smooth: true,
      }
    );
    // if(this.state.hideShowCalendars){
    //   this.setState({ hideShowCalendars: false });
    // } else {
    //   this.setState({ hideShowCalendars: true });
    // }
    
  }

  onPopupOpen(args) {
    if(args.type=="RecurrenceAlert"){
      if($("#QuickDialog_title").text() == "Delete Event"){
        if(!$("#deleteDescription").length){
          let deleteDescription = "<div><textarea id='deleteDescription'></textarea></div>";
          $(args.element).find(".e-dlg-content").after(deleteDescription);
        }

        $("#QuickDialog_dialog-content").text("How would you like to delete the appointment in the series?");
        $(".e-quick-dialog-series-event").text("Delete Event");
      }
      else{
        $("#QuickDialog_dialog-content").text("How would you like to change the appointment in the series?");
        $(".e-quick-dialog-series-event").text("Edit Event");
        $("#deleteDescription").remove();
      }
    }
  }

  render() {

    const { serviceDetail } = this.state;
    var selectedDate = new Date();
    if (this.state.selectedDate) {
      selectedDate = new Date(this.state.selectedDate);
    }

    return (

      <React.Fragment>
        <div className="schedule">
          <div className="container-fluid">
            <div className="content-setion ">
              <div className="section_title m-25">
                <h3 className="page-title"> Appointment </h3>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                  <div className="col-md-6"><img src={serviceDetail.poster ?serviceDetail.poster:defaultImg} alt={serviceDetail.title}/></div>
                  <div className="col-md-6">
                    <div className="col-md-6">Service Name:- {serviceDetail.title}</div>
                    <div className="col-md-6">Provider Name:- {serviceDetail.providerName}</div>
                    <div className="col-md-6">Description:- {serviceDetail.description}</div>
                    {!this.state.hideShowCalendars &&
                      <div className='col-md-6'> 
                      <button onClick={(e)=>this.showCalendars()} className="btn btn-success">Book Appointment</button>
                    </div>
                    }
                    
                    </div>
                   
                  </div>
                </div>
              </div>
              <Element name="appointment_module" className="element" />
{this.state.hideShowCalendars &&
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="state">Time Slot<span className="req">*</span></label>
                    <select
                      className="form-control"
                      name="state"
                      value={this.state.timeSloats}
                      onChange={this.handleChange}
                      style={{ display: 'block' }}>
                      <option value="" label="Select Time Slot" />
                      {this.state.timeSloat.map((data, idx) => (
                        <option value={data} label={data} />
                      ))}
                          </select>
                  </div>
                </div>
              </div>
  }
            
              {!this.state.slotsNotAvailableModal && this.state.hideShowCalendars &&
                <ScheduleComponent popupOpen={this.onPopupOpen.bind(this)} IsAllDay={false} DatePickerComponent={false} showTimeIndicator={false} ref={schedule => this.scheduleObj = schedule} renderCell={this.onRenderCell.bind(this)}  eventRendered={this.onEventRendered.bind(this)} selectedDate={selectedDate} cssClass='schadule-table' actionBegin={this.onActionBegin.bind(this)} timeScale={{ enable: true, interval: this.state.selectedInterval, slotCount: 1 }} eventSettings={{


                  dataSource: this.state.scheduleData,
                  // query: new Query().from("Events").addParams('readOnly', 'true'),
                  fields: {
                    id: 'Id',
                    subject: { name: 'Subject' },
                    startTime: { name: 'StartTime' },
                    endTime: { name: 'EndTime' }
                  },

                }}>

                  <ViewsDirective>
                    <ViewDirective option='Day' />
                    <ViewDirective option='Week' />
                  </ViewsDirective>
                  <Inject services={[Day, Week]} />

                </ScheduleComponent>
              } 
              <Modal show={this.state.slotsNotAvailableModal} onHide={(e) => this.hideModals({})} enforceFocus={false} className="count-modal">
                <Modal.Header closeButton>
                <div className="header-modal">
                    <span className="header-cal">
                      <img src={image4} alt="" />
                    </span>
                    <span className="header-details">
                      <div className="header-details-cont">
                        <h4>Slot Collision Report</h4>
                        <p><small>Initiated by:</small> {localStorage.getItem("firstName")} {localStorage.getItem("lastName")}</p>
                      </div>
                      <div className="header-details-img">
                      <img src={image2} alt="" />
                      </div>
                    </span>
                  </div>
                </Modal.Header>
                <Modal.Body >
                  
                  <div className="body-modal">
                    <span>
                      <p><strong>Please Review: </strong>Your appointment(s) cannot be completed due to the conflicts listed below </p>
                    </span>
                    <span>
                      <img src={image1} alt="" />
                    </span>
                  </div>
                  <div className="body-content-repeat">
                  {this.state.dataArray && this.state.dataArray.map(function (item) {
                    return (<div className="content-repeat-row">
                      <span className="cal-ic">
                      {item.recurrenceType !="No" ?
                        (<img src={image8} alt="" width="50px" />)
                        :(<img src={image9} alt="" width="50px" />)
                      }
                      </span>
                      <span className="start-end-time">
                        <p><strong>Start Time:</strong> {item.cancel_date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
                        <p><strong>End Time:</strong> {item.end_date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
                      </span>
                      <span className="recurrence">
                        <p><strong>Recurrence:</strong> {item.recurrenceType}</p>
                        <p><strong>Till:</strong> {item.recurrenceType !="No"?item.end_date.toDateString():"N/A"}</p>
                      </span>
                    </div>
                    )
                  })}
                    
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {/* <a href="#">Service Schedule Page</a> */}
                  <a href="#" onClick={(e) => this.hideModals({})}>Dismiss</a>
                </Modal.Footer>
              </Modal>

                
              <div className='content-wrapper recurrence-editor-wrap'>
                <div style={{ paddingBottom: '15px' }}>
                  <label></label>
                  <div className='rule-output-container'>
                    <div id='rule-output'></div>
                  </div>
                </div>
                <div className='RecurrenceEditor' style={{ display: "none" }}>
                  <RecurrenceEditorComponent id='RecurrenceEditor' ref={t => this.recObject = t}></RecurrenceEditorComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>


    )


  }
}


const mapStateToProps = state => {
  return {
    error: state.authReducer.signupError,
    success: state.authReducer.success,
    serviceData: state.serviceReducer,
    appointmentData: state.appointmentReducer,
  }
}
const mapDispatchToProps = {
  getServiceScheduleAction: getServiceScheduleAction,
  createAppointmentAction: createAppointmentAction,
  getAppointmentList: getAppointmentList,
  deleteAppoinmentAction: deleteAppoinmentAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPage);