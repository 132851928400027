import React, { Component } from "react";
import { appConstants } from "../_Constants/app.constants";
import { NotificationManager } from "react-notifications";

class Socketpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: JSON.parse(localStorage.getItem("userData")),
      userType: "Admin",
      logOutRequest: false,
      popupStatus: false,
      noficationModal: false,
      notificationData: [],
      badgeCount: localStorage.getItem("badgeCount")
        ? localStorage.getItem("badgeCount")
        : 0,
    };

    if (localStorage.getItem("userId")) {
      let userId = localStorage.getItem("userId");
      var send_data = { user_id: userId };
      setTimeout(function () {
        appConstants.socket.emit("login", send_data);
      }, 1000);
    }

    var elem = this;
    appConstants.socket.on("response", function (data) {
      if (data && data.type) {
        // NotificationManager.info(data.message, data.type);
        elem.updateState();
      }
    });
  }

  updateState() {
    var badge = this.state.badgeCount;
    badge = badge + 1;
    this.setState({
      badgeCount: badge,
    });
  }

  render() {
    return <div>{this.state.badgeCount}</div>;
  }
}

export default Socketpage;
