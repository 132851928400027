import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {appConstants} from "../../_Constants/app.constants"
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import $ from 'jquery';
import {updateProfileUser,resetStatus} from '../../redux/actions/AuthActions'
import {InviteNotificationGetAction, acceptInvitationAction} from '../../redux/actions/ServiceActions'
import {Container, Row, Col, Card, Modal, FormLabel, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import Notification1 from "./NI-1.png"
import Notification2 from "./NI-2.png"
import "./notification.css"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import moment from 'moment';
import Confirm from '../../components/Confirm';

class Notification extends React.Component {
    constructor(props){
      super(props)
      this.state={
        file:'',
        error:'',
        success:'',
        imagePreviewUrl:'',
        loading:false,
        modal:false,
        notificationList:[],
        formData:{
          message:"",
          providerName:""
        },
      }
      this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      // document.title = "Kunneckt | Invite Notification"
      if (localStorage.getItem('token') === null) {
        // this.props.history.push('/login')
      }else{
        this.props.InviteNotificationGetAction()
      }   
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "notification"){
        let myInviteArray = nextProps.serviceData.services;
        if(myInviteArray){            
          myInviteArray = myInviteArray.filter(function(row){
            return (row.isAccepted!=="0" && row.isAccepted!==false)
          })
        }
        this.setState({
          notificationList:myInviteArray?myInviteArray:[],
        })
      }      
      if(nextProps.serviceData && nextProps.serviceData.services && nextProps.serviceData.type === "acceptInvite"){
        this.setState({
          modal:false,
        })
        NotificationManager.success(nextProps.serviceData.services);
        this.props.InviteNotificationGetAction()
      }
    }

    handleChange(type, e) {
      let data = this.state.formData;
      if (type === "ext") {
          data[type] = e;
      } else {
          data[type] = e.target.value;
      }
      this.setState(data);
    }
    
    readMoreOption = (type, e) => {
      if (type === "right") {
        $('#text').css('display', 'inline');
        $('#down').css('display', 'block');
        $('#dots').css('display', 'none');
        $('#right').css('display', 'none');
      }
      if (type === "down") {
        $('#dots').css('display', 'inline');
        $('#text').css('display', 'none');
        $('#down').css('display', 'none');
        $('#right').css('display', 'inline-block');
      }
    }

    hideModals=(data)=> {
      this.setState({
        formData: data?data:{},
        modal: !this.state.modal
      })
    }

    IsAccepted = (cell,row)=>{
      if(row.isAccepted===true  || row.isAccepted==="1"){
        return (<span>Accept</span>)
      }else if(row.isAccepted===false || row.isAccepted==="False" || row.isAccepted==="0"){
        return (<span>Declined</span>)
      }else{
        return (<span>Pending</span>)
      }
    } 

    IsAction = (cell,row)=>{
      return (<Link to="#" onClick={e=>{this.hideModals(row,e)}}> View </Link>)
    }  

    IsDate = (cell,row)=>{
      var dateTimeValue = moment(row.invitedAt).format('MMM, DD YYYY');
      return (<span>{dateTimeValue}</span>)
    }     

    acceptInvitation=(type)=>{
      let newData = {};
      newData['isAccepted'] = type;
      this.props.acceptInvitationAction(this.state.formData.id, newData)
    }
    
    render() {
      const {formData} = this.state ;
      var text = formData.description;
      var text1 = "";
      var text2 = "";
      var summaryLength = (text && text.length?text.length:0);
      if (summaryLength > 280) {
        text1 = text.substr(0, 280);
        if ((summaryLength - 280) > 20) {
            text2 = text.substr(280, summaryLength);
        } else {
            text1 = text;
        }
      }else{
        text1 = text;
      }

      return (
        <div className="container-fluid main-contain">
          <div className="row">
            <div className="col-md-12">
              <BootstrapTable data={ this.state.notificationList } pagination search={false}>
                <TableHeaderColumn tdAttr={ { 'data-attr': 'ID' }} dataField='id' isKey hidden={true}>Id</TableHeaderColumn>
                <TableHeaderColumn width="200" tdAttr={ { 'data-attr': 'Provider Name' }} dataField='providerName'>Invited By</TableHeaderColumn>
                <TableHeaderColumn width="200" tdAttr={{ 'data-attr':'Service Name'}} dataField='title' >Service</TableHeaderColumn>
                <TableHeaderColumn width="200" dataFormat={this.IsDate.bind(this)} tdAttr={{ 'data-attr':'Invited Date'}} dataField='InvitedAt' >Invited Data</TableHeaderColumn>
                <TableHeaderColumn width="100" dataFormat={this.IsAccepted.bind(this)} tdAttr={{ 'data-attr':'Status'}} dataField='status'>Status</TableHeaderColumn>
                <TableHeaderColumn width='100' dataFormat={this.IsAction.bind(this)} dataSort={true} searchable={false}>Action</TableHeaderColumn>
              </BootstrapTable><br/>
            </div>
                
            <Modal show={this.state.modal} onHide={e=>this.hideModals({})} enforceFocus={false}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <img src={Notification1} height="50px" alt="" /> &nbsp; Invitation Service
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormLabel>
                    Initiated By : <span className="notificationTitle">{formData.providerName?formData.providerName:'-'}</span>
                  </FormLabel><br/>
                  <FormLabel>
                    Service : <span className="notificationTitle">{formData.title?formData.title:'-'}</span>
                  </FormLabel>
                  <br/>
                  {formData.logo && 
                    <FormLabel>
                      <img src={formData.logo} className="imfsize" alt="" />
                    </FormLabel>
                  }                  
                  <br/>
                  <div className="company-desc">
                    {text1}{text2!==""?<span>
                      <span id={"dots"}>...</span>
                      <span className="text2sec" id={"text"}>{text2}</span>
                      <span className="right-caret" onClick={this.readMoreOption.bind(this,"right")} id={"right"}>See More</span> 
                      <span className="down-caret" onClick={this.readMoreOption.bind(this,"down")} id={"down"}>See less</span>
                    </span>:''}
                  </div><br/><br/>
                  <InputGroup className="mb-3">
                    <textarea type="text" className="form-control he-discription" name="description" rows="4" cols="50" value={formData.message} onChange={e=>{this.handleChange("message",e)}} >
                    </textarea>
                  </InputGroup>
                  {(formData.isAccepted==='' || formData.isAccepted===null || formData.isAccepted==='None') && 
                  <div className="row">
                    <InputGroup className="mb-3">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 p-r0">
                        <Button className="inviteServiceBtn" onClick={e=>{Confirm(this.acceptInvitation,0)}}>Decline</Button>&nbsp;&nbsp;
                        <Button className="inviteServiceBtn" onClick={e=>{Confirm(this.acceptInvitation,1)}}>Accept</Button>
                      </div>
                    </InputGroup>
                   </div>
                  }
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )
    }
}

const mapStateToProps = state => {
  return {
    error: state.serviceReducer.signupError,
    success: state.serviceReducer.success,
    serviceData: state.serviceReducer,
  }
}

const mapDispatchToProps = {
  acceptInvitationAction: acceptInvitationAction,
  InviteNotificationGetAction: InviteNotificationGetAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
